import React from 'react'
// import { OfferDialog } from './offer-dialog'
import { VideoDialog } from './video-dialog'

export const ResourceDialog = ({ resource, open, onClose }) => {
  if (!resource) {
    return null
    // } else if (resource.type === 'offer') {
    //   return <OfferDialog resource={resource} open={open} onClose={onClose} />
  } else {
    return <VideoDialog resource={resource} open={open} onClose={onClose} />
  }
}
