import React, { Fragment, useState } from 'react'
import ReactDOM from 'react-dom';
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { AnchorSubmenu, Anchor, useLocationCheck } from '@components/core/link'
import './nav-group-menu-item.css';

const NavGroup = ({ className, items, linkButton, ...rest }) => {

  const checkLocation = useLocationCheck()

  const dark = className && className.split(/\s+/).includes('nav-group-dark')

  const NavGroupItem = ({ className, name, Icon, href, ...rest }) => {
    const { cref, current } = checkLocation(href)
    return (
      <Anchor
        className={classNames(
          'nav-group-item hover:bg-transparent font-bold text-[15.5px] text-[#404041]',
          {
            '!text-[#86b749] nav-group-item-current': current,
          },
          {
            'nav-group-dark': dark,
          },
          className
        )}
        href={cref}
        tabIndex={current ? '-1' : '0'}
      >
        {Icon ? <Icon className="w-6 h-6 text-[#417aad] hover:text-[#86b749]" /> : name}
      </Anchor>
    )
  }

  const NavGroupMenu = ({ props, name, items, offset, ...rest }) => {
    const [triggerRef, setTriggerRef] = useState(null)
    const [popperRef, setPopperRef] = useState(null)
    const { styles, attributes } = usePopper(triggerRef, popperRef, {
      placement: 'bottom',
    })

    return (
      <Menu as="div" className="relative">
        {({ open }) => {
          return(
          <>
            <Menu.Button
              ref={setTriggerRef}
              onMouseEnter={({target}) => {
                if (target.type!=="button") {
                  return;
                }
                let groupMenuButtons = ReactDOM.findDOMNode(target).parentNode.parentNode.getElementsByClassName('z-10')
                for(let i = 0; i < groupMenuButtons.length; i ++){
                  let button = ReactDOM.findDOMNode(groupMenuButtons[i]).parentNode.getElementsByClassName('nav-group-menu-button')
                  if(button[0] !== target)
                    button[0].click()
                }
                return open ? "" : target.click()
              }}            
              className={classNames(' nav-group-menu-button hover:bg-transparent', className)}
              {...rest}
            >
              <div className="nav-menu-item flex items-center font-bold text-[15.5px] text-[#404041]  hover:text-[#86b749]">
                {name}
              </div>
            </Menu.Button>
            {open && (
              <div
                className={
                  classNames("z-10 !translate-y-[45px] !rounded-none", `${offset}`)
                }
                ref={setPopperRef}
                style={styles.popper}
                {...attributes.popper}
              >
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items>
                    <div className="nav-group-menu-items flex w-max h-max !rounded-none bg-white/[0.9]">
                      {items.map(({ name, href, addSpace, showIndex, blank }) => {
                        const { cref, current } = checkLocation(href)
                        return (
                          <Menu.Item key={name} disabled={current}>
                            {({ active }) => (
                              <>
                                {
                                  showIndex === false
                                  ?
                                  <></>
                                  :
                                  <div className="flex flex-wrap">
                                    <p className="text-[#86b74c] text-[60px] leading-[13px]">
                                      .
                                    </p>
                                  </div>
                                }
                                <div className="flex flex-wrap content-center h-12">
                                  <AnchorSubmenu
                                    href={cref}
                                    addSpace={addSpace}
                                    blank={blank}
                                    className={classNames('nav-group-menu-item text-center text-[13px] text-[#404041]', {
                                      'nav-group-menu-item-active': active,
                                      'nav-group-menu-item-current': current,
                                    })}
                                  >
                                    {name}
                                  </AnchorSubmenu>
                                </div>
                            </>
                            )}
                          </Menu.Item>
                        )
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </div>
            )}
          </>
        )}}
      </Menu>
    )
  }

  return (
    <div className={classNames('nav-group ', className)} {...rest}>
      {items.map(({ name, icon, href, items, offset }) =>
        href ? (
          <NavGroupItem key={name} name={name} Icon={icon} href={href} />
        ) : (
          <NavGroupMenu key={name} name={name} items={items} offset={offset} />
        )
      )}
      {linkButton}
    </div>
  )
}

export { NavGroup }
