import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { NewsCard } from './news-card'

const NewsPanel = ({ className, tag, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allNewsYaml {
          nodes {
            category
            date
            title
            url
            img {
              id
              childImageSharp {
                gatsbyImageData(height: 128, transformOptions: { fit: CONTAIN })
              }
            }
          }
        }
      }
    `
  )

  const items = data.allNewsYaml.nodes

  return (
    <div className={classNames('', className)} {...rest}>
      <div className="flex flex-col items-center w-full gap-y-4">
        {items.map(({ date, category, title, img, url }) => (
          <NewsCard
            key={title}
            date={date}
            category={category}
            title={title}
            url={url}
            img={img}
          />
        ))}
      </div>
    </div>
  )
}

export { NewsPanel }
