import React from 'react'

export const StopIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <path d="M37.1099 4H17.128L3 17.8218V37.3682L17.128 51.19H37.1099L51.2379 37.3682V17.8218L37.1099 4Z" stroke="#1B3D57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M35.6387 7.62976H18.597L6.54785 19.3244V35.8651L18.597 47.5598H35.6387L47.6879 35.8651V19.3244L35.6387 7.62976Z" stroke="#1B3D57" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M15.0207 20.9403H11.6869C11.4891 20.9403 11.2932 20.9793 11.1105 21.055C10.9277 21.1307 10.7617 21.2416 10.6218 21.3815C10.482 21.5213 10.371 21.6874 10.2953 21.8701C10.2196 22.0529 10.1807 22.2487 10.1807 22.4465V25.4836C10.1806 25.6815 10.2195 25.8774 10.2952 26.0602C10.3708 26.243 10.4818 26.4091 10.6217 26.549C10.7615 26.6889 10.9276 26.7999 11.1104 26.8756C11.2931 26.9513 11.489 26.9903 11.6869 26.9903H14.724C14.9218 26.9902 15.1177 27.0292 15.3005 27.1048C15.4833 27.1805 15.6494 27.2914 15.7893 27.4313C15.9293 27.5712 16.0402 27.7372 16.116 27.92C16.1917 28.1028 16.2307 28.2987 16.2307 28.4965V32.7436C16.2307 32.9415 16.1918 33.1374 16.1162 33.3202C16.0405 33.503 15.9295 33.6691 15.7897 33.809C15.6498 33.9489 15.4837 34.0599 15.301 34.1356C15.1182 34.2113 14.9223 34.2503 14.7245 34.2503H10.1807" stroke="#78A65A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M22.2754 34.2503V20.9403" stroke="#78A65A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M18.6533 20.9403H25.9133" stroke="#78A65A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M32.0946 20.9403H30.6194C29.3561 20.9403 28.332 21.9644 28.332 23.2277V31.9629C28.332 33.2262 29.3561 34.2503 30.6194 34.2503H32.0946C33.3579 34.2503 34.382 33.2262 34.382 31.9629V23.2277C34.382 21.9644 33.3579 20.9403 32.0946 20.9403Z" stroke="#78A65A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M38.0117 34.2503V20.9403H42.7912C43.1282 20.9403 43.4513 21.0742 43.6896 21.3124C43.9279 21.5507 44.0617 21.8738 44.0617 22.2108V25.7198C44.0617 26.0568 43.9279 26.3799 43.6896 26.6182C43.4513 26.8565 43.1282 26.9903 42.7912 26.9903H39.2217" stroke="#78A65A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)