import { useStaticQuery, graphql } from 'gatsby'

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            gtm {
              trackingId
              cookieName
            }
            build {
              mode
            }
            site {
              url
            }
          }
        }
      }
    `
  )
  return site.siteMetadata
}

export { useSiteMetadata }
