import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

const ContactLink = ({items}) => {
  return (
    <div className='text-left mb-[19px]'>
      {
        items.map((item) => {
          return (
            <p key={item.name} className="text-center md:text-left">
              <GatsbyLink to={item.href} className="text-white font-bold text-[16px] leading-[29px]">
                {item.name}
              </GatsbyLink>
            </p>
          )
        })
      }
    </div>
  )
}

export {ContactLink}