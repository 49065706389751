import React from 'react'
import { VortexBG, TitleBanner } from '@components/core'

export const SignupBanner = ({ title, subtitle, ...rest }) => (
  <TitleBanner
    bgImage={VortexBG}
    bgClassName="bg-primary-500 bg-opacity-[80%]"
    title={title}
    subtitle={subtitle}
    {...rest}
  />
)
