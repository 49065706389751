import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import homeGraphic from './home-graphic-green.webp'

const heroTitleStyle = 'text-center font-bold font-hero text-[#acafb3] lg:text-[3.7rem] md:text-5xl sm:text-4xl text-4xl lg:leading-[3.6rem] leading-[3rem]';
export const HomeBanner = ({ ...rest }) => (
<div className="bg-[#161f2a] w-full min-h-[327px] md:h-[48vw] md:min-h-[608px] max-h-[608px]">
  <div className="container flex h-full items-center ">
    <div className="w-full md:w-[31%] ml-auto md:ml-[3rem] relative">
      <div className="flex flex-col items-center w-[250px] md:w-auto md:mt-[4rem] md:mb-8 md:float-right m-auto">
        <h1 className="flex flex-col items-center m-auto">
          <span className={classNames("mt-[24px] mb-0 md:m-0", heroTitleStyle)}
            data-sal="slide-right"
            data-sal-duration="2000"
            data-sal-delay="100"
            data-sal-easing="ease"
          >Zero Trust</span>
          <span
            className={classNames("m-0", heroTitleStyle)}
            data-sal="slide-right"
            data-sal-duration="2000"
            data-sal-delay="500"
            data-sal-easing="ease"
          >for Mobile Apps</span>
          {/* <span
            className={classNames("m-0", heroTitleStyle)}
            data-sal="slide-right"
            data-sal-duration="2000"
            data-sal-delay="1000"
            data-sal-easing="ease"
          >Mobile Apps</span> */}
        </h1>
        
        <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-3/4 my-6"/>
        {/* <h2 className="m-0 text-center text-[#86b146] font-hero lg:text-[1.6rem] lg:leading-[28px] text-[1.2rem] mb-[18px]">From the Device<br/>to the Cloud</h2> */}
        <h2 className="m-0 text-center text-[#86b146] font-hero lg:text-[1.6rem] lg:leading-[28px] text-[1.2rem] mb-[18px]">Cross Platform Mobile Attestation for Android, HarmonyOS and iOS</h2>
        <Link
          target='_blank'
          href="https://approov.io/threats/real-time"
          className="text-white bg-[#86b146] py-[10px] px-[24px] my-[20px] font-bold text-[20px] leading-[24px] w-max text-center not-prose  rounded !no-underline"
        >
          View Real-Time Threats
        </Link>
      </div>
    </div>
    <div className="hidden md:block w-[70%] not-prose">
      <img
        src={homeGraphic}
        className="lg:w-[816px] md:mr-[50px] md:m-auto h-auto"
        alt="Mobile App Protection | Approov"
      />
    </div>
  </div>
</div>
)
