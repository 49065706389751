import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import {MDXRenderer} from "../../core/mdx";

const PartnerCard = ({ className, title, body, img, tag, ...rest }) => {
  console.log("tag", tag);
  return (
    <div
      className={classNames(
        "pt-2 flex flex-col gap-0 mt-0",
        { "justify-center": tag === "browser" },
        { "mb-[40px]": tag === "backend" },
        { "!mt-[30px] md:!mt-0": tag === "cloud" },
        className
      )}
      {...rest}
    >
      <div className="min-h-[80px]">
        <div
          className={classNames(
            "flex justify-center md:justify-start pb-2",
            { "h-[106px]": tag === "backend" },
            { "h-[106px]": tag === "cloud" || tag === "browser" }
          )}
        >
          <GatsbyImage
            imgStyle={{ margin: "0 auto 0 0", width: "auto", height: "100%" }}
            image={getImage(img)}
            alt={title}
          />
        </div>
      </div>
        <MDXRenderer className="text-[#717272] text-[18.7px] mt-[9px] leading-[26px] text-center md:text-left not-prose">
          {body}
        </MDXRenderer>
    </div>
  );
};

export { PartnerCard };
