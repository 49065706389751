import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'

const AppCard = ({ className, title, text, cardImage, link, Icon, IconColour, PanelColour, titleClassName, titleStyle, textStyle, ...rest }) => {

  return (
    <div
      className={classNames('flex flex-col gap-0', className)}
      {...rest}
    >
      <Link className='text-center' href={link}>
        {cardImage}
      </Link>
      <h3 className={classNames('m-0 text-center text-[#717272] font-bold text-[1.15rem] mt-[1.0625rem]', titleClassName)} style={{whiteSpace: 'pre-wrap', ...titleStyle}}>
        {title}
      </h3>
      <p className="m-0 mt-2 text-[#717272] text-center text-[1rem] md:text-[17px] leading-[1.5] whitespace-pre-wrap">
        {text}
      </p>

    </div>
  )
}

export { AppCard }
