import React from 'react'
import classNames from 'classnames'
import { DivBG } from '@components/core'

const minBannerHeight = 'min-h-[224px]'

export const Banner = ({ bgImage, bgClassName, children, ...rest }) => (
  <div className="shadow-lg shadow-gray-500">
    <DivBG bgImage={bgImage} bgClassName={bgClassName} {...rest}>
      <div
        className={classNames(
          'flex justify-center items-center',
          minBannerHeight
        )}
      >
        {children}
      </div>
    </DivBG>
  </div>
)
