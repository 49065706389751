import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { LinkBox } from '@components/core'

export const LinkCard = ({
  className,
  resource,
  disabled = false,
  ...rest
}) => {
  const { title, subtitle, img, url } = resource

  return (
    <div className="relative w-64 h-64">
      <LinkBox
        className={classNames(
          'font-medium text-gray-700 bg-white',
          'flex flex-col items-center justify-start w-64 h-64',
          'absolute top-0 left-0',
          className
        )}
        href={url || '#'}
        {...rest}
      >
        <GatsbyImage
          className="w-full h-1/2"
          imgStyle={{ padding: 0, margin: 0 }}
          image={getImage(img)}
          alt=""
        />
        <div className="flex flex-col items-center justify-center w-full px-2 border-t">
          <p className="m-0 mt-4 text-lg leading-tight text-center text-primary-500 flex-grow">
            {title}
          </p>
          <p className="m-0 mt-2 text-sm leading-tight text-center text-gray-700 overflow-hidden overflow-ellipsis" style={{ display: '-webkit-box', WebkitLineClamp: 2,   WebkitBoxOrient: 'vertical'}}>
            {subtitle}
          </p>
        </div>
      </LinkBox>
      {disabled && (
        <div
          className={classNames(
            'w-64 h-64',
            'absolute top-0 left-0',
            'bg-white opacity-50'
          )}
        />
      )}
    </div>
  )
}
