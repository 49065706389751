import React from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { Prose, useWindowSize } from '@components/core'
import { XIcon } from '../../core/icons/x'

const CloseButton = ({ className, onDismiss }) => (
  <button
    type="button"
    className={classNames(
      'absolute flex items-center justify-center w-5 h-5 ml-1 text-black rounded-full top-1 right-1 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-grey-800',
      className
    )}
    onClick={onDismiss}
  >
    <span className="sr-only">Close dialog</span>
    <XIcon className="w-4 h-4" aria-hidden="true" />
  </button>
)

export const VideoDialog = ({ resource, open, onClose }) => {
  const { title, url } = resource

  const { width, height } = useWindowSize()
  const wCN =
    width && height && 9 * width > 18 * height ? 'w-[142vh]' : 'w-[80vw]'

  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        open={open}
        onClose={onClose}
        className="fixed inset-0 z-40 overflow-y-auto"
      >
        <Prose>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-overlay" />

            <div
              className={classNames(
                wCN,
                'relative px-4 pb-4 bg-white border border-gray-200 rounded shadow-xl'
              )}
            >
              <CloseButton className="mt-2 mr-2" onDismiss={onClose} />
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <h2 className="my-2">{title}</h2>
                </div>
                <div className="flex items-center justify-center w-full">
                  <div className="w-full border border-gray-300 aspect-[16/9]">
                    <iframe
                      className="w-full h-full"
                      title="Approov Your APIs"
                      src={url}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Prose>
      </Dialog>
    </Transition>
  )
}
