/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from 'react'
import { Dialog } from '@headlessui/react'
import { Button } from '@components/core'
import { confirmCid } from './offer-api'
import { OfferForm } from './offer-form'

const Spinner = () => (
  <svg
    className="w-8 h-8 mr-3 -ml-1 text-white animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
)

const SHOW_ACCEPT = 'accept'
const SHOW_ACCESSING = 'accessing'
const SHOW_RETRY = 'retry'
const SHOW_CONTENT = 'content'

export const LeadGuard = ({ offer, cid, show, setShow }) => {
  const isOpen = show !== 'content'
  const noClose = () => {}

  const onAccept = () => {
    setShow(SHOW_ACCESSING)
    confirmCid(cid).then(({ isConfirmed }) => {
      console.log(`confirmed: ${isConfirmed}`)
      setShow(isConfirmed ? SHOW_CONTENT : SHOW_RETRY)
    })
  }

  return (
    <Dialog open={isOpen} onClose={noClose} className="relative z-40">
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <div className="flex flex-col items-center max-w-2xl p-8 rounded-xl bg-primary-500">
          {show === SHOW_ACCEPT && (
            <>
              <h3 className="pb-4 m-0 text-center text-white">
                Confirm resource request
              </h3>
              <Button
                className="text-white bg-transparent border-white hover:border-white hover:bg-white hover:text-primary-500"
                onClick={onAccept}
              >
                Confirm
              </Button>
            </>
          )}
          {show === SHOW_ACCESSING && (
            <>
              <h3 className="pb-4 m-0 text-center text-white">
                Accessing requested resource...
              </h3>
              <Spinner />
            </>
          )}
          {show === SHOW_RETRY && (
            <>
              <h3 className="pb-4 m-0 text-center text-white">
                Contact information is required to access this resource:
              </h3>
              <OfferForm offer={offer} />
            </>
          )}
        </div>
      </div>
    </Dialog>
  )
}
