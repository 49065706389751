import React from 'react'

export const NoChangeIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <g clip-path="url(#clip0_284_1820)">
    <path d="M51.9446 0H3.05542C2.24547 0.00131591 1.46908 0.323648 0.896364 0.896364C0.323648 1.46908 0.00131591 2.24547 0 3.05542L0 45.2839C0.00131591 46.0938 0.323648 46.8702 0.896364 47.4429C1.46908 48.0156 2.24547 48.338 3.05542 48.3393H16.6704V53.3404H10.8333C10.7202 53.3342 10.607 53.3512 10.5006 53.3902C10.3943 53.4292 10.297 53.4895 10.2147 53.5674C10.1324 53.6453 10.0669 53.7392 10.0221 53.8432C9.97735 53.9473 9.95425 54.0594 9.95425 54.1727C9.95425 54.286 9.97735 54.3981 10.0221 54.5022C10.0669 54.6062 10.1324 54.7001 10.2147 54.778C10.297 54.8559 10.3943 54.9162 10.5006 54.9552C10.607 54.9942 10.7202 55.0112 10.8333 55.005H44.1742C44.387 54.9933 44.5873 54.9006 44.7339 54.7458C44.8805 54.591 44.9622 54.3859 44.9622 54.1727C44.9622 53.9595 44.8805 53.7544 44.7339 53.5996C44.5873 53.4448 44.387 53.3521 44.1742 53.3404H38.3321V48.3393H51.9446C52.7545 48.338 53.5309 48.0156 54.1036 47.4429C54.6764 46.8702 54.9987 46.0938 55 45.2839V3.05542C54.9987 2.24547 54.6764 1.46908 54.1036 0.896364C53.5309 0.323648 52.7545 0.00131591 51.9446 0ZM1.66704 3.05542C1.66704 2.6872 1.81332 2.33406 2.07369 2.07369C2.33406 1.81332 2.6872 1.66704 3.05542 1.66704H51.9446C52.3128 1.66704 52.6659 1.81332 52.9263 2.07369C53.1867 2.33406 53.333 2.6872 53.333 3.05542V40.0016H1.66704V3.05542ZM36.665 53.3354H18.3325V48.3343H36.665V53.3354ZM53.3354 45.2789C53.3354 45.6471 53.1892 46.0003 52.9288 46.2606C52.6684 46.521 52.3153 46.6673 51.9471 46.6673H3.05542C2.68806 46.6673 2.33567 46.5217 2.07544 46.2624C1.81522 46.0031 1.66836 45.6512 1.66704 45.2839V41.6686H53.333L53.3354 45.2789Z" fill="#1B3D57"/>
    <path d="M27.9999 8.85718C31.1558 8.85718 33.7142 11.4156 33.7142 14.5715L33.7152 18.0161C34.5245 18.0609 35.0722 18.2048 35.6391 18.5079C36.3103 18.8669 36.8473 19.404 37.2063 20.0752C37.5837 20.7808 37.7142 21.4568 37.7142 22.6445V29.3556C37.7142 30.5433 37.5837 31.2193 37.2063 31.9249C36.8473 32.5961 36.3103 33.1332 35.6391 33.4921C34.9334 33.8695 34.2575 34 33.0698 34H22.9301C21.7424 34 21.0664 33.8695 20.3608 33.4921C19.6896 33.1332 19.1525 32.5961 18.7935 31.9249C18.4162 31.2193 18.2856 30.5433 18.2856 29.3556V22.6445C18.2856 21.4568 18.4162 20.7808 18.7935 20.0752C19.1525 19.404 19.6896 18.8669 20.3608 18.5079C20.9278 18.2047 21.4756 18.0609 22.2852 18.016L22.2856 14.5715C22.2856 11.4156 24.844 8.85718 27.9999 8.85718ZM33.0698 19.7143H22.9301C21.9112 19.7143 21.5417 19.8204 21.1692 20.0196C20.7968 20.2188 20.5044 20.5112 20.3052 20.8836C20.106 21.2561 19.9999 21.6256 19.9999 22.6445V29.3556C19.9999 30.3745 20.106 30.744 20.3052 31.1164C20.5044 31.4889 20.7968 31.7813 21.1692 31.9805C21.5417 32.1797 21.9112 32.2858 22.9301 32.2858H33.0698C34.0886 32.2858 34.4581 32.1797 34.8306 31.9805C35.2031 31.7813 35.4954 31.4889 35.6946 31.1164C35.8938 30.744 35.9999 30.3745 35.9999 29.3556V22.6445C35.9999 21.6256 35.8938 21.2561 35.6946 20.8836C35.4954 20.5112 35.2031 20.2188 34.8306 20.0196C34.4581 19.8204 34.0886 19.7143 33.0698 19.7143ZM27.9999 23.1429C28.9467 23.1429 29.7142 23.9104 29.7142 24.8572C29.7142 25.6034 29.2374 26.2383 28.5719 26.4737L28.5714 28.2858C28.5714 28.6014 28.3155 28.8572 27.9999 28.8572C27.6843 28.8572 27.4285 28.6014 27.4285 28.2858L27.4285 26.4739C26.7627 26.2386 26.2856 25.6036 26.2856 24.8572C26.2856 23.9104 27.0532 23.1429 27.9999 23.1429ZM27.9999 10.5715C25.7908 10.5715 23.9999 12.3623 23.9999 14.5715V18H31.9999V14.5715C31.9999 12.3623 30.2091 10.5715 27.9999 10.5715Z" fill="#78A65A"/>
  </g>
  <defs>
    <clipPath id="clip0_284_1820">
      <rect width="55" height="55" fill="white"/>
    </clipPath>
  </defs>
</svg>
)