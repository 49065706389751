import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
}

export const CEOImage = (
  <StaticImage
    src={`${imgDir}/david-lecturing.jpg`}
    alt="David Stewart, CEO"
    {...imgProps}
  />
)
