import { useLocation } from '@reach/router'

const canonicalHref = (href) => {
  let cref = href ? href.trim() : ''
  if (['', '#', '#/'].includes(cref)) {
    return { cref: '#', internal: true }
  } else {
    if (href.indexOf("knowledge/general")===-1&&cref.length > 1 && cref.endsWith('/')) cref = cref.slice(0, -1)
      return { cref, internal: /^\/(?!\/)/.test(cref) }
  }
}

const useLocationCheck = () => {
  const { pathname } = useLocation()

  const checkLocation = (pathname) => (href) => {
    const { cref, internal } = canonicalHref(href)
    const current = cref === pathname || cref === '#'

    return { cref, internal, current }
  }

  return checkLocation(pathname)
}

export { canonicalHref, useLocationCheck }
