import React from "react";
import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import { PartnerCard } from "./partner-card";

const PartnerGrid = ({ className, tag, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { frontmatter: { contentKey: { eq: "partner" } } }, limit: 1000) {
          nodes {
            id
            frontmatter {
              tag
              title
              img {
                id
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 86, placeholder: BLURRED, transformOptions: { fit: CONTAIN })
                }
              }
            }
            body
          }
        }
      }
    `
  );

  const cards = data.allMdx.nodes
    .filter((c) => c.frontmatter.tag === tag)
    .sort((c0, c1) => {
      const s0 = c0.frontmatter.title.toLowerCase();
      var s1 = c1.frontmatter.title.toLowerCase();
      return s0 > s1 ? 1 : s0 < s1 ? -1 : 0;
    });

  return (
    <div className={classNames("w-full", className)} {...rest}>
      <div className="flex flex-wrap justify-center items-stretch gap-y-3 gap-x-[3%] md:gap-x-[5%] mb-[50px]">
        {cards.map((card) => {
          return (
            <PartnerCard
              key={card.frontmatter.title}
              className={classNames(
                { "w-[98%] sm:w-[48%] md:w-[30%] self-stretch": tag !== "cloud" },
                { "w-[98%] md:w-[46%] self-stretch": tag === "cloud" }
              )}
              title={card.frontmatter.title}
              body={card.body}
              img={card.frontmatter.img}
              tag={tag}
            />
          );
        })}
      </div>
    </div>
  );
};

export { PartnerGrid };
