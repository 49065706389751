import React from 'react'
import { SecurityBG, TitleBanner } from '@components/core'

export const ConsultBanner = ({ title, subtitle, ...rest }) => (
  <TitleBanner
    bgImage={SecurityBG}
    bgClassName="bg-primary-700 bg-opacity-80"
    title={title}
    subtitle={subtitle}
    {...rest}
  />
)
