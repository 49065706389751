import React from 'react'
import classNames from 'classnames'
import { featureListData } from './feature-data'

const FeatureList = ({ className, ...rest }) => {
  return (
    <div className={classNames('w-full', className)} {...rest}>
      <div className="flex flex-wrap items-stretch justify-center gap-y-0 gap-x-6 lg:gap-x-8">
        {featureListData.map(({ name, icon: Icon }) => (
          <div key={name} className="w-56">
            <p className="m-0 mt-2 text-sm text-gray-800">
              <Icon className="inline-block w-5 h-5 text-primary-500" /> {name}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export { FeatureList }
