import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Dialog, Disclosure, Transition } from '@headlessui/react'
import { Anchor, Link, useLocationCheck } from '@components/core/link'
import { ChevronDownIcon } from '../icons/chevron-down'
import { XIcon } from '../icons/x'

const NavBurger = ({ logo, items, show, setOpen, ...rest }) => {
  const Logo = logo
  const checkLocation = useLocationCheck()

  const NavBurgerItem = ({ className, name, href, ...rest }) => {
    const { cref, current } = checkLocation(href)
    const tabIndex = current ? '-1' : '0'

    return (
      <Anchor
        className={classNames(
          'nav-burger-item',
          {
            'nav-burger-item-current': current,
          },
          className
        )}
        href={cref}
        tabIndex={tabIndex}
      >
        {name}
      </Anchor>
    )
  }

  const NavBurgerItems = ({ className, name, items, ...rest }) => {
    return (
      <Disclosure as="div" className="space-y-1">
        {({ open }) => (
          <>
            <Disclosure.Button className="nav-burger-disclosure-button">
              <div className="flex items-center">
                {name}
                <ChevronDownIcon className="w-4 h-4" />
              </div>
            </Disclosure.Button>
            <Disclosure.Panel className="space-y-1">
              {items.map(({ name, href }) => (
                <NavBurgerItem
                  key={name}
                  className="ml-4"
                  name={name}
                  href={href}
                />
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    )
  }

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 flex lg:hidden"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 pt-2 -mr-12">
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="w-6 h-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            {Logo && (
              <div className="flex items-center flex-shrink-0 px-4">
                <Link href="/">
                  <Logo className="w-auto !h-auto mr-4" />
                </Link>
              </div>
            )}
            <div className="flex-1 h-0 mt-5 overflow-y-auto">
              <nav className="px-2 space-y-1">
                {items.map(({ name, href, items }) => {
                  if (href) {
                    return <NavBurgerItem key={name} name={name} href={href} />
                  } else {
                    return (
                      <NavBurgerItems key={name} name={name} items={items} />
                    )
                  }
                })}
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div
          className="flex-shrink-0 w-14"
          aria-hidden="true"
          note="dummy element to force sidebar to shrink to fit close icon"
        />
      </Dialog>
    </Transition>
  )
}

export { NavBurger }
