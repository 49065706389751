import React from "react";
import classNames from "classnames";

// Background Images should be configured like this:
//
// <GatsbyImage
//   objectFit="cover"
//   objectPosition="right center"
//   style={{
//     width: '100%',
//     height: '100%',
//   }}
//   image={getImage(page.challengeImage)}
//   alt="Security Challenge"
//   placeholder="blurred"
// />
//
// or
//
// <StaticImage
//   objectFit="cover"
//   objectPosition="center 25%"
//   style={{ height: '100%', width: '100%' }}
//   alt="Dog at Home"
//   src="./dog.jpg"
//   placeholder="blurred"
// />

const DivBG = ({ className, bgImage, bgClassName, children, special, ...rest }) => {
  return (
    <div className={classNames("relative bg-[#020232]", className)} {...rest}>
      <div className={classNames("relative z-[2]", bgClassName)}>
        {children}
      </div>
      <div className="hidden sm:block absolute h-full w-full top-0 left-0 z-[1]">
        {bgImage}
      </div>
    </div>
  );
};

export { DivBG };
