import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { LinkBox } from '@components/core'

const NewsCard = ({ date, category, title, url, img, ...rest }) => {
  return (
    <LinkBox
      className={classNames(
        'w-full',
        'font-medium text-gray-700 border-none hover:text-gray-700',
        'flex items-center justify-center p-4'
      )}
      href={url}
      {...rest}
    >
      <div className="w-1/4 pr-2">
        <GatsbyImage
          imgStyle={{ padding: 0, margin: 0 }}
          image={getImage(img)}
          alt=""
        />
      </div>
      <div className="flex flex-col items-start w-3/4 pl-2">
        <p className="m-0 mb-2 text-xs text-primary-500">{category}</p>
        <p className="m-0 text-xl md:text-2xl">{title}</p>
        <p className="m-0 mt-2 text-sm">{date}</p>
      </div>
    </LinkBox>
  )
}

export { NewsCard }
