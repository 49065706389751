import { DeviceSetupIcon } from '../../core/icons/device-setup'
import { DeviceUpdateIcon } from '../../core/icons/device-update'
import { DnaIcon } from '../../core/icons/dna'
import { EyeSlashIcon } from '../../core/icons/eye-slash'
import { HelpIcon } from '../../core/icons/help'
import { LinkIcon } from '../../core/icons/link'
import { ListIcon } from '../../core/icons/list'
import { PacmanIcon } from '../../core/icons/pacman'
import { PoisonIcon } from '../../core/icons/poison'
import { WarningIcon } from '../../core/icons/warning'
import { ErrorIcon } from '../../core/icons/error'
import { ShieldCheckIcon } from '../../core/icons/shield-check'
import { StethoscopeIcon } from '../../core/icons/stethoscope'
import { DeviceCheckIcon } from '../../core/icons/device-check'
import { TerminalIcon } from '../../core/icons/terminal'
import { IdentificationIcon } from '../../core/icons/identification'
import { TokenIcon } from '../../core/icons/token'

const featureListData = [
  { icon: DnaIcon, name: 'Positive app attestation' },
  { icon: PacmanIcon, name: 'Man-in-the-middle rejection' },
  { icon: LinkIcon, name: 'User authorization binding' },
  { icon: EyeSlashIcon, name: 'Dynamic certificate pinning' },
  { icon: DeviceSetupIcon, name: 'Easy app SDK integration' },
  { icon: TokenIcon, name: 'JWT backend token verification' },
  { icon: ListIcon, name: 'Selective security policies' },
  { icon: ShieldCheckIcon, name: 'DeviceCheck integration' },
  { icon: ShieldCheckIcon, name: 'SafetyNet integration' },
  { icon: DeviceCheckIcon, name: 'Emulator detection' },
  { icon: ErrorIcon, name: 'Debug detection' },
  { icon: WarningIcon, name: 'Root detection' },
  { icon: WarningIcon, name: 'Jailbreak detection' },
  { icon: PoisonIcon, name: 'Magisk detection' },
  { icon: PoisonIcon, name: 'Frida detection' },
  { icon: PoisonIcon, name: 'Xposed detection' },
  { icon: PoisonIcon, name: 'Cydia detection' },
  { icon: IdentificationIcon, name: 'Clone app detection' },
  { icon: PoisonIcon, name: 'App automation detection' },
  { icon: PoisonIcon, name: 'Memory dump detection' },
  { icon: StethoscopeIcon, name: 'Real-time metrics' },
  { icon: DeviceUpdateIcon, name: 'Over-the-air security updates' },
  { icon: TerminalIcon, name: 'DevOps tooling' },
  { icon: HelpIcon, name: '24/7/365 support' },
]

export { featureListData }
