import React from 'react'
import classNames from 'classnames'

const Section = ({ as, className, children, ...rest }) => {
  const cn = classNames('section', className)
  const As = as || 'section'

  return (
    <As className={cn} {...rest}>
      {children}
    </As>
  )
}

export { Section }
