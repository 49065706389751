import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgHeight = 16 * 4
const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
  layout: 'fixed',
  height: imgHeight,
  objectFit: 'contain',
}

export const AccountTakeoverImage = (
  <StaticImage
    src={`${imgDir}/Account-Takeover-Icon.png`}
    alt=""
    {...imgProps}
  />
)

export const Locker = (
  <StaticImage
    src={`${imgDir}/Locker.png`}
    alt="Cyber security abstract concept; 3D padlock icon on digital background"
    class="hidden md:block ml-[3px] w-full h-[500px]"
    objectFit="cover"
  />
)

export const ApiSecurityBreachImage = (
  <StaticImage
    src={`${imgDir}/API-Security-Breach-Icon.png`}
    alt=""
    {...imgProps}
  />
)

export const AppImpersonationImage = (
  <StaticImage
    src={`${imgDir}/App-Impersonation-Icon.png`}
    alt=""
    {...imgProps}
  />
)

export const CreditFraudImage = (
  <StaticImage src={`${imgDir}/Credit-Fraud.png`} alt="" {...imgProps} />
)

export const DenialOfServiceImage = (
  <StaticImage
    src={`${imgDir}/Denial-of-Service-Icon.png`}
    alt=""
    {...imgProps}
  />
)

export const FakeAccountCreationImage = (
  <StaticImage
    src={`${imgDir}/Fake-Account-Creation.png`}
    alt=""
    {...imgProps}
  />
)

export const ManInTheMiddleImage = (
  <StaticImage
    src={`${imgDir}/Man-in-the-Middle-Icon.png`}
    alt=""
    {...imgProps}
  />
)

export const ScrapingImage = (
  <StaticImage src={`${imgDir}/Scraping-Icon.png`} alt="" {...imgProps} />
)
