import React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import { graphql, useStaticQuery } from "gatsby";
import { BlogCard } from "./blog-card";
import  "./slider.css";

export const BlogGrid = ({
  className,
  tag,
  onSelect,
  disabled = false,
  ...rest
}) => {
  const {
    allBlogYaml: { nodes: blogs },
  } = useStaticQuery(
    graphql`
      query {
        allBlogYaml {
          nodes {
            path
            title
            published
            img
            tags
            description
          }
        }
      }
    `
  );

  const filteredBlogs = blogs.filter((blog) => blog.tags?.includes(tag));

  const settings = {
    className: "center",
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: filteredBlogs.length > 3 ? 3 : filteredBlogs.length - 1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  if (filteredBlogs.length < 4) {
    return (
      <div className={classNames("w-full", className)} {...rest}>
        <div className="flex flex-wrap items-stretch justify-center gap-2 lg:gap-[3rem]">
          {filteredBlogs.map((blog) => (
            <BlogCard
              key={blog.title}
              blog={blog}
              onSelect={onSelect}
              disabled={disabled}
              isSlider={false}
            />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className={classNames("w-[95%] m-auto", className)} {...rest}>
        <Slider {...settings}>
          {filteredBlogs.map((blog) => (
            <BlogCard
              key={blog.title}
              blog={blog}
              onSelect={onSelect}
              disabled={disabled}
              isSlider={true}
            />
          ))}
        </Slider>
      </div>
    );
  }
};
