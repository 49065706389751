import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'
import { QuickstartCard } from './quickstart-card'
import { QuickstartMenuCard } from './quickstart-menu-card'

const QuickstartGrid = ({ className, tag, displayMode, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { frontmatter: { contentKey: { eq: "quickstart" } } }
          limit: 1000
        ) {
          nodes {
            id
            slug
            frontmatter {
              name
              tag
              alt
              variants {
                name
                href
              }
              img {
                id
                childImageSharp {
                  gatsbyImageData(
                    layout: FIXED
                    height: 48
                    placeholder: BLURRED
                    transformOptions: { fit: CONTAIN }
                  )
                }
              }
            }
          }
        }
      }
    `
  )

  const cards = data.allMdx.nodes
    .filter((c) => c.frontmatter.tag === tag)
    .sort((c0, c1) => {
      const s0 = c0.frontmatter.name.toLowerCase()
      var s1 = c1.frontmatter.name.toLowerCase()
      return s0 > s1 ? 1 : s0 < s1 ? -1 : 0
    })

  return (
    <div className={classNames('w-full', className)} {...rest}>
      <div className="flex flex-wrap items-stretch justify-center gap-2 lg:gap-4">
        {cards.map(({ frontmatter: { name, img, variants, alt } }) => {
          if (variants.length === 1) {
            return (
              <QuickstartCard
                key={name}
                name={name}
                img={img}
                alt={alt}
                href={variants[0].href}
                displayMode={displayMode}
              />
            )
          } else {
            return (
              <QuickstartMenuCard
                key={name}
                name={name}
                img={img}
                alt={alt}
                items={variants}
                displayMode={displayMode}
              />
            )
          }
        })}
      </div>
    </div>
  )
}

export { QuickstartGrid }
