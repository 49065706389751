import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import {
  Alert,
  Button,
  Link,
  /* LinkButton, */
  Tooltip,
} from '@components/core'
import { InfoIcon } from '../../core/icons/info'

export const refs = {
  tryApproov: '/signup',
  dynamicPinning: 'https://blog.approov.io/approov-dynamic-pinning',
  dynamicPinningView:
    'https://blog.approov.io/approov-dynamic-pinning-an-independent-view',
  pinningBypass:
    'https://blog.approov.io/how-to-protect-against-certificate-pinning-bypassing',
  reverseProxy:
    'https://blog.approov.io/using-a-reverse-proxy-to-protect-third-party-apis',
  androidCertPinning:
    'https://developer.android.com/training/articles/security-config#CertificatePinning',
  androidNetworkSecurity:
    'https://developer.android.com/training/articles/security-config#CertificatePinning',
  approovDynamicPinning: 'https://blog.approov.io/approov-dynamic-pinning',
  androidExample:
    'https://github.com/approov/example-android-static-certificate-pinning',
  iOSExample:
    'https://github.com/approov/example-ios-static-certificate-pinning',
  iOSIdentityPinning: 'https://developer.apple.com/news/?id=g9ejcf8y',
  iOSNSAppTransportSecurity:
    'https://developer.apple.com/documentation/bundleresources/information_property_list/nsapptransportsecurity',
}

export const ConfigDisclaimer = ({ className }) => {
  return (
    <Alert
      className={classNames('p-2', className)}
      status="info"
      title="DISCLAIMER"
    >
      " Use this tool at your own risk. It's your responsibility to be aware of
      all the implications of using static certificate piinning in your mobile
      app and to ensure that the given configurations are correct and work as
      expected.
    </Alert>
  )
}

export const PinningAdvisory = ({ className }) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center p-4 bg-blue-200',
        className
      )}
    >
      <div className="text-lg">
        <InfoIcon className="inline-block w-10 h-10 mr-2 text-blue-500" />
        Static Certificate Pinning Alert
      </div>
      <p className="text-sm">
        Using this pinning configuration tool generates a fixed static set of
        pins that are included in your app. Static pinning always carries some
        risk that your app will stop working if you are not able to present any
        of the specified pins from your backend servers. For further details on
        this and on the flexibility and added peace of mind that using Approov
        brings you, please read{' '}
        <Link href={refs.dynamicPinning}>Approov Dynamic Pinning</Link>.
      </p>
      {/* <div className="mt-2">
        <LinkButton className="button-primary" href={refs.tryApproov}>
          Give Approov a Try
        </LinkButton>
      </div> */}
    </div>
  )
}

export const DomainInfo = () => {
  return (
    <Tooltip
      className="inline-block"
      trigger="click"
      tip={
        <div className="p-2 text-gray-700 bg-gray-100 w-96">
          <h3 className="m-0 mb-4">API Domain to Pin</h3>
          <p className="text-sm">
            Enter one domain per row that your mobile app uses to call an API
            that you control and want to pin.
          </p>
          <p className="text-sm">
            You may optionally add <code>:port</code> if the live API is not on
            the standard port 443.
          </p>
          <p className="text-sm">
            The pin will be generated from the domain certificate public key.
          </p>
          <p className="text-sm">
            We don't recommend that you pin third party APIs as the pins might
            change unexpectedly. If you really need to then you could use a
            reverse proxy as discussed{' '}
            <Link href={refs.reverseProxy}>here</Link>.
          </p>
        </div>
      }
    >
      <InfoIcon className="w-4 h-4 text-white" />
    </Tooltip>
  )
}

export const PinSubdomainsInfo = () => {
  return (
    <Tooltip
      className="inline-block"
      trigger="click"
      tip={
        <div className="p-2 text-gray-700 bg-gray-100 w-96">
          <h3 className="m-0 mb-4">
            Include All Subdomains in the Pinning Configuration
          </h3>
          <p className="text-sm">
            Selecting this option will pin all subdomains of your domain.
          </p>
          <p className="text-sm">
            For example, if the domain to pin is example.com then it will also
            pin api.example.com, auth.example.com, etc.
          </p>
          <p className="text-sm">
            So, when this option is selected it will act as the wildcard
            *.example.com.
          </p>
        </div>
      }
    >
      <InfoIcon className="w-4 h-4 text-white" />
    </Tooltip>
  )
}

export const PinLeafCertificateInfo = () => {
  return (
    <Tooltip
      className="inline-block"
      trigger="click"
      tip={
        <div className="p-2 text-gray-700 bg-gray-100 w-96">
          <h3 className="m-0 mb-4">Pin the Leaf Certificate</h3>
          <p className="text-sm">
            Selecting this option will pin against the public key of the leaf
            certificate live on the domain.
          </p>
          <p className="text-sm">
            The leaf certificate is the one presented by the API server itself.
            It is the pin that most definitively identifies the server but is
            likely to be the most subject to change.
          </p>
          <p className="text-sm">
            The live domain will be probed from remote servers so must be
            accessible on the Internet, not only on the local network.
          </p>
        </div>
      }
    >
      <InfoIcon className="w-4 h-4 text-white" />
    </Tooltip>
  )
}

export const PinRootCertificateInfo = () => {
  return (
    <Tooltip
      className="inline-block"
      trigger="click"
      tip={
        <div className="p-2 text-gray-700 bg-gray-100 w-96">
          <h3 className="m-0 mb-4">Pin the Root Certificate</h3>
          <p className="text-sm">
            Selecting this option will pin against the public key of the root
            certificate live on the domain.
          </p>
          <p className="text-sm">
            The root certificate is the top level Certificate Authority (CA) in
            the trust chain. A pin to this ensures that the server certificate
            must be signed in a chain leading to this particular root authority.
          </p>
          <p className="text-sm">
            The live domain will be probed from remote servers so must be
            accessible on the Internet, not only on the local network.
          </p>
        </div>
      }
    >
      <InfoIcon className="w-4 h-4 text-white" />
    </Tooltip>
  )
}

export const CertificateFilesInfo = () => {
  return (
    <Tooltip
      className="inline-block"
      trigger="click"
      tip={
        <div className="p-2 text-gray-700 bg-gray-100 w-96">
          <h3 className="m-0 mb-4">Upload Certificate File(s)</h3>
          <p className="text-sm">
            The certificate file(s) to upload must have the public key for the
            domain. PEM, CRT, CER and binary encoded DER files are supported.
            The certificate needs to be valid for the domain and not already
            expired. This means it must be a leaf certificate.
          </p>
          <p className="text-sm">
            This may be used to specify one or more backup pins that are not
            currently live. It's strongly recommended to always provide at least
            one backup pin.
          </p>
          <p className="text-sm">
            On form submission, duplicate certificate files will be ignored.
          </p>
        </div>
      }
    >
      <InfoIcon className="w-4 h-4 text-white" />
    </Tooltip>
  )
}

export const ResetDialog = ({ control, onCancel, onReset }) => {
  return (
    <Transition appear show={control.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={control.setClosed}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border border-gray-300 shadow-xl rounded-2xl">
              <Dialog.Title className="text-lg font-bold">
                Static Pinning Reset
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-700">
                  Do you want to reset this form?
                </p>
                <p className="text-sm text-gray-700">
                  All fields will be reset to their default values.
                </p>
              </div>

              <div className="flex justify-end w-full gap-3 mt-4">
                <Button
                  className="button-secondary"
                  onClick={() => {
                    onCancel && onCancel()
                    control.setClosed()
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="button-failure"
                  onClick={() => {
                    onReset && onReset()
                    control.setClosed()
                  }}
                >
                  Reset
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export const ErrorDialog = ({ control, onCancel }) => {
  return (
    <Transition appear show={control.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={control.setClosed}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white border border-gray-300 shadow-xl rounded-2xl">
              <Dialog.Title className="text-lg font-bold">
                Static Pinning Error
              </Dialog.Title>
              <div className="mt-2">
                <p className="mb-2 text-sm text-gray-700">
                  There was an invalid response from the static pinning tool.
                </p>
                <p className="text-sm text-gray-700">
                  Please retry your request, or if this error persists, please{' '}
                  <Link href="https://approov.io/info/contact">contact us</Link> for assistance.
                </p>
              </div>

              <div className="flex justify-end w-full gap-3 mt-4">
                <Button
                  className="button-secondary"
                  onClick={() => {
                    onCancel && onCancel()
                    control.setClosed()
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
