import React from "react";
import { Link as GatsbyLink } from "gatsby";

const SubLink = ({ linkSet }) => {
  return (
    <div className="text-center md:text-left mb-[19px] w-full md:w-1/3">
      {linkSet.href ? (
        <GatsbyLink to={linkSet.href}>
          <p className="text-white font-bold text-[16px] leading-[29px]">
            {linkSet.name}
          </p>
        </GatsbyLink>
      ) : (
        <p className="text-white font-bold text-[16px] leading-[29px]">
          {linkSet.name}
        </p>
      )}
      {linkSet.items.map((item) => {
        return (
          <div key={item.name} className="mt-[10px]">
            {item.href.includes("http") ? (
              <a href={item.href}>
                <p className="text-white leading-[20px]">{item.name}</p>
              </a>
            ) : (
              <GatsbyLink to={item.href}>
                <p className="text-white leading-[20px]">{item.name}</p>
              </GatsbyLink>
            )}
          </div>
        );
      })}
    </div>
  );
};

export { SubLink };
