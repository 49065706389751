import axios from 'axios'

export const checkEmail = async (email, context = '') => {
  const qualifyEmailUrl = 'https://admin-liffey-ew1.approovr.com/verify-email/'

  try {
    const url = qualifyEmailUrl
    const body = {
      properties: { email: { value: email } },
      context: context,
    }

    const { data } = await axios.post(url, body)
    if (/*data.isValid &&*/ data.meetsCriteria) {
      return {
        isQualified: true,
        isFree: false,
        isError: false,
        rejectionReason: 'none',
        status: `email is qualified`,
      }
    } else {
      return {
        data,
        isQualified: false,
        isNotBiz:
          data.rejectionReason === 'free email disallowed' ||
          data.rejectionReason === 'disposable email disallowed',
        isError: false,
        rejectionReason: data.rejectionReason,
        status: `email is unqualified`,
      }
    }
  } catch (e) {
    return {
      data: {},
      isQualified: false,
      isFree: false,
      isError: true,
      rejectionReason: 'unexpected error',
      status: 'unexpected error qualifying email at admin service',
    }
  }
}

export const checkFreeEmail = async (email, context = '') => {
  const qualifyEmailUrl = 'https://admin-liffey-ew1.approovr.com/verify-email/'

  try {
    const url = qualifyEmailUrl
    const body = {
      properties: { email: { value: email } },
      context: context,
      allowFree: true,
    }

    const { data } = await axios.post(url, body)
    if (/*data.isValid &&*/ data.meetsCriteria) {
      return {
        isQualified: true,
        isFree: false,
        isError: false,
        rejectionReason: 'none',
        status: `email is qualified`,
      }
    } else {
      return {
        data,
        isQualified: false,
        isNotBiz:
          data.rejectionReason === 'free email disallowed' ||
          data.rejectionReason === 'disposable email disallowed',
        isError: false,
        rejectionReason: data.rejectionReason,
        status: `email is unqualified`,
      }
    }
  } catch (e) {
    return {
      data: {},
      isQualified: false,
      isFree: false,
      isError: true,
      rejectionReason: 'unexpected error',
      status: 'unexpected error qualifying email at admin service',
    }
  }
}

const portalId = '2449407'
const submitFormUrl =
  'https://api.hsforms.com/submissions/v3/integration/submit/'
const confirmFormUrl =
  'https://admin-liffey-ew1.approovr.com/hubspot-form-email-confirm/'

export const submitForm = async ({
  formId,
  fields,
  context,
  confirm = null,
}) => {
  try {
    const url = confirm
      ? `${confirmFormUrl}${formId}/`
      : `${submitFormUrl}${portalId}/${formId}/`
    console.log(`posting: url: ${url}`)

    const confirms = confirm
      ? {
          confirmationURL: confirm.url,
          confirmationText: confirm.body,
          altConfirmationText:
            'If you are unable to click on the link above to confirm your request, please navigate your web browser to LINK-HERE',
          confirmationSubject: confirm.subject,
          clientPayload: confirm.payload || undefined,
        }
      : {}

    const body = {
      submittedAt: Date.now(),
      fields: fields,
      context: context,
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'By submitting this form, you consent to allow CriticalBlue to store and process the personal information submitted above to fufill your request.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 5135615,
              leadBasis: 'LEAD',
              text: 'CriticalBlue (developer of Approov) will use the personal information you provide to send you the content requested and information about our services. You may unsubscribe from these communications at any time by clicking the link at the bottom of our emails. For information on our privacy practices and commitment to protecting your privacy, check out our Privacy Policy',
            },
          ],
        },
      },
      ...confirms,
    }
    console.log(`posting: ${JSON.stringify(body, null, 2)}`)

    const { data: res } = await axios({ method: 'post', url: url, data: body })
    console.log(`form submission result: ${JSON.stringify(res)}`)
    const isAccepted = !(res.errors && res.errors.length > 0)

    return {
      isAccepted: isAccepted,
      isError: !isAccepted,
      status: `submission was ${isAccepted ? 'accepted' : 'not accepted'}`,
    }
  } catch (e) {
    console.log(`form submission error: ${e.name}: ${e.message}`)
    return {
      isAccepted: false,
      isError: true,
      status: 'unexpected error submitting form',
    }
  }
}

export const confirmCid = async (cid) => {
  const url = `https://admin-liffey-ew1.approovr.com/hubspot-form-email-confirmed/${cid}`

  try {
    const { data, status } = await axios.get(url)
    console.log('response is: ', JSON.stringify({ status, data }, null, 2))
    return {
      isConfirmed: true,
      data: data,
    }
  } catch (err) {
    return {
      isConfirmed: false,
      data: {},
    }
  }
}
