import React from 'react'
import useDownloader from 'react-use-downloader'
import { Button } from './button'

export const DownloadButton = ({ downloadUrl, fileName, ...rest }) => {
  const { download, isInProgress } = useDownloader()

  const onDownload = () => download(downloadUrl, fileName)

  return <Button onClick={onDownload} loading={isInProgress} {...rest} />
}
