import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';

import { NewsletterSubscriptionForm } from './newsletter-subscription_form';

const url_list = [
    {
        title: 'RASP Overview',
        url: '/mobile-app-security/rasp/'
    },
    {
        title: 'Mobile APP Attestation',
        url: '/mobile-app-security/rasp/app-attestation/'
    },
    {
        title: 'Runtime Secrets Protection',
        url: '/mobile-app-security/rasp/runtime-secrets/'
    },
    {
        title: 'Real Time Threat Intelligence',
        url: '/mobile-app-security/rasp/realtime-threat-intelligence/'
    },
    {
        title: 'API Security',
        url: '/mobile-app-security/rasp/api-security/'
    },
    {
        title: 'Dynamic Certificate Pinning',
        url: '/mobile-app-security/rasp/dynamic-cert-pinning/'
    },
    {
        title: 'App Shielding',
        url: '/mobile-app-security/rasp/app-shielding/'
    }
]

export const RightSidebar = ({ location }) => {
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        setUrls(url_list.filter( item => location !== item.url));
    }, [location])

    return (
        <div className="w-full lg:w-[280px] flex-shrink-0">
            <div className="flex flex-col gap-[40px]">
            <div className="flex flex-col gap-[40px] rounded-[10px] border-[#E3E3E3] border-[1px] px-[24px] py-[36px]">
                <div className="flex flex-col gap-[8px]">
                    <div className="text-[17px] text-[#1B3D57] font-bold leading-[28px]">
                        Want to see the power of Approov in action? 
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/info/schedule-a-demo" className="text-[16px] text-[#262727] leading-[20px]">
                        Request a demo now!
                        </Link>
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/resource/quickstarts" className="text-[16px] text-[#262727] leading-[20px]">
                        Check out our Quickstart guides.
                        </Link>
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/signup/" className="text-[16px] text-[#262727] leading-[20px]">
                        Start a free trial today!
                        </Link>
                    </div>
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="text-[17px] text-[#1B3D57] font-bold leading-[28px]">
                        Other Features of Approov End-to-End Mobile App Security
                    </div>
                    {
                        urls.map( (item, idx) => (
                            <div className="flex items-start gap-[9px]" key={idx} >
                                <div className="h-[20px] flex items-center">
                                <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                                    <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                                </svg>
                                </div>
                                <Link href={item.url} className="text-[16px] text-[#262727] leading-[20px]">
                                {item.title}
                                </Link>
                            </div>
                        ))
                    }
                </div>

                <div className="flex flex-col gap-[8px]">
                    <div className="text-[17px] text-[#1B3D57] font-bold leading-[28px]">
                        Want to learn more about Approov?
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/blog/" className="text-[16px] text-[#262727] leading-[20px]">
                        Blogs
                        </Link>
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/resource/" className="text-[16px] text-[#262727] leading-[20px]">
                        Resources
                        </Link>
                    </div>
                    <div className="flex items-start gap-[9px]">
                        <div className="h-[20px] flex items-center">
                        <svg className="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" width="5" height="5" viewBox="0 0 5 5" fill="none">
                            <circle cx="2.5" cy="2.5" r="2.5" fill="#78A65A"/>
                        </svg>
                        </div>
                        <Link href="https://approov.io/knowledge" className="text-[16px] text-[#262727] leading-[20px]">
                        Knowledge Base
                        </Link>
                    </div>
                </div>
            </div>
            <NewsletterSubscriptionForm />
            </div>
        </div>
    );
}