import React from 'react'
import { Banner } from './banner'
import { BasicBG } from '../backgrounds'

export const BasicBanner = ({ title, subtitle, ...rest }) => {
  const bgClassName = 'bg-black bg-opacity-25'

  return (
    <Banner bgImage={BasicBG} bgClassName={bgClassName} {...rest}>
      <div className="flex flex-col justify-center w-full px-2 py-4 md:px-4">
        <h1 className="pb-4 my-0 text-center text-white">{title}</h1>
        {subtitle && (
          <h3 className="py-0 my-0 text-center text-white">{subtitle}</h3>
        )}
      </div>
    </Banner>
  )
}
