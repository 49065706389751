import axios from 'axios'

const configUrl = `https://admin-liffey-ew1.approovr.io/pinning-config`

export const configRequest = async (body) => {
  try {
    const url = `${configUrl}/`
    const { data } = await axios.post(url, body)

    return {
      isOK: true,
      status: `configuration results`,
      results: {
        errors: data.errors,
        logging: data.logging.join('\r\n'),
        androidConfig: atob(data.configAndroidBase64),
        iosConfig: atob(data.configIOSBase64),
      },
    }
  } catch (e) {
    return {
      isOK: false,
      status: 'Unexpected error requesting pinning configuration',
    }
  }
}
