import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import {AppCard} from "../../core/app-card";

const IntegrityPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames("w-full", className)} {...rest}>
      <h2
        className="leading-none mt-0 mb-[2rem] text-center text-[#1b3d57] font-bold  text-[2.2rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem]"
        // data-sal="slide-up"
        // data-sal-duration="1000"
        // data-sal-delay="400"
        // data-sal-easing="ease"
      >
        Defend Your Five Mobile Attack Surfaces
      </h2>
      <p className="text-center leading-[1.75rem] lg:text-1xl text-[1rem] mb-0">
        Approov provides the only comprehensive run-time security solution for
        mobile apps and their APIs, unified across Android, iOS, and HarmonyOS.{" "}
      </p>
      <div className="flex flex-wrap justify-center items-stretch gap-y-3 gap-x-[2%] md:gap-x-[2.33%] lg:gap-x-[2%] my-[16px]">
        <AppCard
          cardImage={
            <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/app-attestation/"><StaticImage
              objectFit="contain"
              className="h-32"
              src="./app-integrity1.png"
              alt=""
            /></Link>
          }
          titleClassName="leading-[1.2rem]"
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5"
          title={`App Integrity`}
          text={`Attest your apps to ensure only truly authentic apps are authorized to make API calls.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/"><StaticImage
              objectFit="contain"
              className="h-32"
              src="./device-integrity.png"
              alt=""
            /></Link>
          }
          titleClassName="leading-[1.2rem]"
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5"
          title={`Device Integrity`}
          text={`Adopt comprehensive fine-grained device integrity checks enforced outside of the untrusted device environment.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/dynamic-cert-pinning/"><StaticImage
              objectFit="contain"
              className="h-32"
              src="./channel-integrity.png"
              alt=""
            /></Link>
          }
          link='https://approov.io/mobile-app-security/rasp/dynamic-cert-pinning/'
          titleClassName="leading-[1.2rem]"
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5"
          title={`Channel Integrity`}
          text={`Employ securely certificate-pinned TLS with dynamic pin update.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <div className='flex justify-center'>
              <StaticImage
                objectFit="contain"
                className="h-32"
                src="./credentials-integrity.png"
                alt=""
              />
            </div>
          }
          titleClassName="leading-[1.2rem]"
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5"
          title={`Credential Integrity`}
          text={`Require frequent app authentication for strong trusted user and API authorization.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/api-security/"><StaticImage
              objectFit="contain"
              className="h-32"
              src="./service-integrity.png"
              alt=""
            /></Link>
          }
          titleClassName="leading-[1.2rem]"
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5"
          title={`Service Integrity`}
          text={`Shield your APIs from exploitation of vulnerabilities by using over-the-air updates for instant reaction to new threat intelligence.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
      </div>
      <div className="flex flex-col items-center justify-center">
        <Link
          href="https://info.approov.io/secret-report"
          className="text-white bg-[#86b146] py-[10px] px-[48px] mt-[52px] font-bold text-[18px] leading-[24px] w-max text-center not-prose  rounded !no-underline shadow-md shadow-[#e2e8f0]"
        >
          Download our Threat Labs
          <br />
          Mobile App Security Report
        </Link>
      </div>
    </div>
  );
};

export { IntegrityPanel };
