import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { canonicalHref } from './href-utils'

// an unstyled, location-unnaware anchor component

const Anchor = ({ className, href, blank, children, ...rest }) => {
  const { cref, internal } = canonicalHref(href)
  blank =
    blank !== undefined
      ? blank
      : !(internal || href.startsWith('https://blog.approov.io'))
  const target = blank ? { target: '_blank', rel: 'noopener norefferer' } : {}

  if (internal && !blank) {
    return (
      <GatsbyLink to={cref} className={className} {...rest}>
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a href={cref} className={className} {...target} {...rest}>
        {children}
      </a>
    )
  }
}

export { Anchor }
