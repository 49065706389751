import React from "react";
import { Link as GatsbyLink } from "gatsby";

const BottomFooter = ({ items }) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="text-white p-[20px] pb-0 pt-[14px] mt-[13px] flex flex-col md:flex-row">
      <p>
        Approov | CriticalBlue Limited © {currentYear} •{" "}
        <GatsbyLink to={"/privacy"} className="text-white font-bold">
          Privacy Policy
        </GatsbyLink>{" "}
        •{" "}
        <GatsbyLink
          to={"https://approov.io/info/terms"}
          className="text-white font-bold"
        >
          Terms
        </GatsbyLink>{" "}
        •{" "}
        <GatsbyLink to={"/cookies"} className="text-white font-bold">
          Cookie Policy
        </GatsbyLink>
      </p>
    </div>
  );
};

export { BottomFooter };
