import React, { Fragment } from 'react'
import classNames from 'classnames'
import { Tab } from '@headlessui/react'

export const PanelTab = ({ name, start, end }) => {
  return (
    <Tab as={Fragment}>
      {({ selected }) => (
        <button
          className={classNames(
            selected ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
            start ? 'rounded-tl-lg' : null,
            end ? 'rounded-tr-lg' : null,
            'group relative min-w-0 flex-1 overflow-hidden bg-white py-2 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
          )}
        >
          <span>{name}</span>
          <span
            className={classNames(
              selected ? 'bg-primary-500' : 'bg-transparent',
              'absolute inset-x-0 bottom-0 h-0.5'
            )}
          />
        </button>
      )}
    </Tab>
  )
}
