import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import classNames from 'classnames'
import { useResource } from './resource-context'

export const VideoCard = ({ className, resource, ...rest }) => {
  const { select } = useResource()

  const { title, subtitle, img } = resource

  return (
    <button
      className={classNames(
        'link-box',
        'font-medium text-gray-700 bg-white',
        'flex flex-col items-center justify-start w-64 h-64',
        className
      )}
      onClick={() => select(resource)}
      {...rest}
    >
      <GatsbyImage
        className="w-full h-1/2"
        imgStyle={{ padding: 0, margin: 0 }}
        image={getImage(img)}
        alt=""
      />
      <div className="flex flex-col items-center justify-center w-full px-2 border-t">
        <p className="m-0 mt-4 text-lg leading-tight text-center text-primary-500">
          {title}
        </p>
        <p className="m-0 mt-2 text-sm leading-tight text-center text-gray-700 overflow-hidden overflow-ellipsis" style={{ display: '-webkit-box', WebkitLineClamp: 2,   WebkitBoxOrient: 'vertical'}}>
          {subtitle}
        </p>
      </div>
    </button>
  )
}
