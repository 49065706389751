import React from 'react'

const PlanBar = ({ contents,  className, ...rest }) => {
  console.log("planbar contents", contents)

  return (
    <>
    {
      contents.map((item, index) => 
        <td className="tracking-[-0.025em] p-2 pt-[0px]" key={"planbar" + index}>
          <p className="text-center text-[#86b146] font-bold text-[12px] md:text-[18px]">{item}</p>
        </td>
      )
    }
    </>
  )
}

export { PlanBar }