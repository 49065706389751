import React from 'react'
import classNames from 'classnames'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { ThreatCard } from './threat-card'
import { threatData } from './threat-data'
import './tab-styles.css'

const ThreatPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames('hidden sm:block w-full pl-[1.4rem] pr-[1.4rem] mt-[24px] threat-panel', className)} {...rest}>
      <Tabs>
        <TabList>
          <Tab>
            <p>Account Takeover</p>
          </Tab>
          <Tab>
            <p>Fake Account Creation</p>
          </Tab>
          <Tab>
            <p>Denial of Service</p>
          </Tab>
          <Tab>
            <p>Credit Fraud</p>
          </Tab>
          <Tab>
            <p>App Impersonation</p>
          </Tab>
          <Tab>
            <p>Man in the Middle</p>
          </Tab>
          <Tab>
            <p>API Security Breach</p>
          </Tab>
          <Tab>
            <p>Scraping</p>
          </Tab>
        </TabList>

        {threatData.map(({ title, description, href, Image }) => (
          <TabPanel>
            <div className="panel-content">
              <ThreatCard
                key={title}
                className="w-[98%] md:w-full self-stretch flex"
                title={title}
                description={description}
                href={href}
                Image={Image}
              />
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  )
}

export { ThreatPanel }
