import React, { useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import isEmail from 'validator/es/lib/isEmail'
import { useCookies } from 'react-cookie'
import { Button, Link } from '@components/core'
import { useLead } from './use-lead'
import { usePage } from './use-page'
import { useOffer } from './use-offer'
import { checkFreeEmail, submitForm } from './offer-api'

export const PinningForm = ({
  offer,
  className,
  dark,
  toPayload,
  onDismiss,
  isDismissed,
}) => {
  // offer info
  const { offerId, formId, offerName, resourceHref, gaConversionName } =
    useOffer(offer)
  const submitId = `submit-${offerId}`
  const thanks = 'Thanks for your interest in Approov!'
  const subject = `${offerName} Request`
  const body = `Thank you for requesting the ${offerName}.\n\nClick LINK-HERE to confirm your request.`

  // page info

  const { siteUrl, pageUrl, pageUri, pageName, queryParams } = usePage()

  const extraFields = {
    utm_campaign: queryParams.utm_campaign || '',
    utm_source: queryParams.utm_source || '',
    utm_medium: queryParams.utm_medium || '',
    utm_term: queryParams.utm_term || '',
    utm_content: queryParams.utm_content || '',
    google_ads_conversion_name:
      (queryParams.gclid && gaConversionName) || undefined,
    google_ads_click_id: queryParams.gclid || undefined,
  }

  // cookie storage

  const [cookies] = useCookies(['hubspotutk'])

  const domain_values = cookies['domain-values']
  console.log("domain_values", domain_values)

  // lead storage/state

  const [lead, setLead] = useLead()

  // display state

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isQualified, setIsQualified] = useState(false)
  const [isAccepted, setIsAccepted] = useState(isDismissed())

  // form prep

  const defaultFieldValues = {
    firstName: lead?.firstName || '',
    lastName: lead?.lastName || '',
    email: lead?.email || '',
    domains: domain_values || '',
  }

  const {
    register,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onBlur', defaultValues: defaultFieldValues })

  // submit handler

  const onSubmit = async (data) => {
    debugger
    setIsSubmitted(false)

    let result

    // qualify email

    try {
      result = await checkFreeEmail(data.email, pageUrl)

      if (!result.isQualified) {
        setError('email', {
          type: 'validate',
          rejectionReason: result.rejectionReason,
        })
        setIsQualified(false)
        setIsSubmitted(true)
        return
      }
    } catch (e) {
      setError('email', {
        type: 'validate',
        rejectionReason: 'unexpected error',
      })
      setIsQualified(false)
      setIsSubmitted(true)
      return
    }

    clearErrors('email')
    setIsQualified(true)

    // update lead

    setLead({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      domains: data.domains,
    })

    // add route info

    if (typeof window !== `undefined`) {
      extraFields.approov_referrer_url = window?.approov?.referrerUrl || 'none'
      extraFields.approov_previous_path =
        window?.approov?.previousPath || 'none'
    }

    // use the 'tab' query param as this form's referrer id
    extraFields.approov_current_refid = queryParams.tab || 'none'

    // submit form

    try {
      const fields = [
        {
          name: 'firstname',
          value: data.firstName,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'domains',
          value: data.domains,
        }
      ]
      for (const field in extraFields) {
        if (extraFields[field] !== undefined) {
          fields.push({ name: field, value: extraFields[field] })
        }
      }

      console.log(
        `fields submitted to ${formId}: ${JSON.stringify(fields, null, 2)}`
      )

      const context = {
        hutk: cookies.hubspotutk,
        pageUri: pageUri,
        pageName: pageName,
      }

      const confirm = {
        url: `${siteUrl}${resourceHref}`,
        body: body,
        subject: subject,
        payload: toPayload(),
      }

      result = await submitForm({
        formId,
        fields,
        context,
        confirm: confirm,
      })

      if (!result.isAccepted) {
        setIsAccepted(false)
        setIsSubmitted(true)
        return
      }
    } catch (e) {
      setIsAccepted(true)
      setIsSubmitted(true)
      return
    }

    // clear state
    setIsSubmitted(false)
    setIsAccepted(true)
  }

  // confirm thanks

  const onConfirm = () => {
    setIsAccepted(true)
    onDismiss()
  }

  if (!isAccepted) {
    return (
      <form
        className={classNames('p-4 bg-gray-100 rounded', className)}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" {...register('domains')} defaultValue={domain_values} />
        <div className="grid grid-cols-6 gap-y-4 gap-x-4">
          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="firstName"
              className={classNames(
                'block text-sm font-medium',
                dark ? 'text-white' : 'text-gray-700'
              )}
            >
              First name
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.firstName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.firstName,
                  }
                )}
                placeholder="first"
                {...register('firstName', { required: true, minLength: 1 })}
              />
              {errors.firstName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  First name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="last"
              className={classNames(
                'block text-sm font-medium',
                dark ? 'text-white' : 'text-gray-700'
              )}
            >
              Last name
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.lastName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.lastName,
                  }
                )}
                placeholder="last"
                {...register('lastName', { required: true, minLength: 1 })}
              />
              {errors.lastName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Last name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="email"
              className={classNames(
                'block text-sm font-medium',
                dark ? 'text-white' : 'text-gray-700'
              )}
            >
              Business email
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.email,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.email,
                  }
                )}
                placeholder="email"
                {...register('email', {
                  required: true,
                  validate: {
                    format: (v) => isEmail(v),
                  },
                })}
              />
              {errors.email && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  A valid email address is required to deliver your request
                  (rejection reason: {errors.email.rejectionReason}). If unable
                  to provide another email, or you think this email is valid,
                  let us know at the <Link href="https://approov.io/info/contact">contact us</Link>{' '}
                  page.
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6">
            <p
              className={classNames(
                'mt-0 text-sm leading-tight',
                dark ? 'text-white' : 'text-gray-500'
              )}
            >
              CriticalBlue (developer of Approov) will use the personal
              information you provide to send you the content requested and
              information about our services. You may unsubscribe from these
              communications at any time. For information on how to unsubscribe,
              as well as our privacy practices and commitment to protecting your
              privacy, check out our <Link href="/privacy">Privacy Policy</Link>
              .
            </p>
            {isSubmitted && isQualified && !isAccepted && (
              <p className="mt-0 text-sm leading-tight text-failure-500">
                An unexpected error occurred while submitting this form. Please
                try your submission again, and if you continue to have trouble,
                please let us know at the{' '}
                <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do our
                best to resolve the issue.
              </p>
            )}
          </div>
        </div>

        <div className="pt-4">
          <div className="flex justify-end">
            <Button
              id={submitId}
              type="submit"
              className="ml-3 button-secondary bg-[#86b146]"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    )
  } else {
    // advise on how to confirm
    return (
      <div className={classNames('p-4 bg-gray-100 rounded', className)}>
        <p className={dark ? 'text-white' : ''}>{thanks}</p>
        <p className={dark ? 'text-white' : ''}>
          Check your email for a link to confirm your request. If you do not
          promptly receive a confirming email, please check your spam or junk
          folder for emails from approov.io. If you still have not received the
          confirming email, please let us know at the{' '}
          <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do our best
          to resolve the issue.
        </p>

        <div className="pt-4">
          <div className="flex justify-end">
            <Button className="ml-3 button-secondary" onClick={onConfirm}>
              Check Email
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
