import React from "react";
import { Headquarter, SocialIcons, ContactLink, SubLink } from "@components/core";

const FooterContent = ({ items }) => {
  return (
    <div className="text-white p-[20px] mt-[13px] flex flex-col">
      <div className="text-white p-[20px] mt-[13px] flex flex-col md:flex-row">
        {/* Left side start */}
        <div className="w-full md:w-[57%] flex flex-col md:flex-row pl-[6px] md:pl-[0px]">
          {/* Headquarter start */}
          <div className="w-full md:w-1/2 md:block">
            {items.left.headquarters.map((headquarter) => {
              return <Headquarter key={headquarter.name} headquarter={headquarter} />;
            })}
            {/* <ContactTab contactInfo={items.left.contactInfo} /> */}
          </div>
          {/* Headquarter end */}

          {/* Contact Links start */}
          <div className="w-full md:w-1/2 md:block flex flex-col content-center flex-wrap">
            <ContactLink items={items.left.links} />
            <SocialIcons className="bg-[#004360] hidden sm:flex" items={items.social} />
          </div>
          {/* Contact Links end */}
        </div>
        {/* Left side end */}

        {/* Right side start */}
        <div className="w-full md:w-[43%] flex flex-row flex-wrap justify-between md:content-left pl-[6px] md:pr-[0px]">
          {items.right.links.map((item) => {
            return <SubLink key={item.name} linkSet={item} />;
          })}
        </div>
        {/* Right side end */}
      </div>
      <SocialIcons className="bg-[#004360] flex sm:hidden" items={items.social} />
    </div>
  );
};

export { FooterContent };
