import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { LinkButton } from '@components/core'
import { StaticImage } from 'gatsby-plugin-image'

const ProtectsPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames('w-full', className)} {...rest}>
      <h2 className="leading-none lg:mb-[1.7rem] md:mb-[1.7rem] sm:mb-[1.2rem] mb-[1.2rem] mt-0  text-center text-white font-bold  text-[2.2rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem]"
          >
            Who Protects with Approov?
      </h2>
      <div className='grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 pt-2 items-stretch gap-y-3 gap-x-[2%] md:gap-x-[2.33%] lg:gap-x-[2%]'>
        <div className='col-span-1 row-span-1 col-start-1 row-start-2 md:row-start-2 lg:row-start-1 flex flex-col items-center justify-center'>
          <h2 className='m-0 text-white text-center lg:text-[1.6rem] md:text-[1.2rem] text-[1.2rem] lg:leading-[2rem] md:leading-[2rem] leading-[1.5rem]'>
            Balancing Top-Class Security With Top-Class Customer Experience In Car Sharing
          </h2>
          <LinkButton
            className={classNames('button-secondary button-xs w-fit bg-[#86b146] font-bold px-6 mt-[2rem] md:mt-[2.5rem] text-[1rem]')}
            href="/customer/bmw"
          >
            LEARN HOW
          </LinkButton>
        </div>
        <div className='col-span-1 row-span-1 col-start-1 lg:col-start-2 row-start-1 flex justify-center items-center lg:m-0 my-8'>
          <Link href='https://approov.io/customer/bmw/'>
            <StaticImage objectFit='contain' src="./bmw.png" className='lg:w-[11rem] md:w-[11rem] w-[8rem]' alt=""/>
          </Link>
        </div>
        <div className='col-span-1 row-span-1 col-start-1 lg:col-start-3 md:col-start-2 row-start-3 sm:row-start-3 md:row-start-1  flex justify-center items-center lg:-0 mb-8'>
          <Link href='https://approov.io/customer/sixt/'>
            <StaticImage objectFit='contain' src="./sixt.png" className='lg:w-[11rem] md:w-[11rem] w-[8rem] mt-8 md:mt-0' alt=""/>
          </Link>
        </div>
        <div className='col-span-1 row-span-1 col-start-1 lg:col-start-4 md:col-start-2 row-start-4 sm:row-start-4 md:row-start-2 lg:row-start-1 flex flex-col items-center justify-center'>
          <h2 className='m-0 text-white text-center lg:text-[1.6rem] md:text-[1.2rem] text-[1.2rem] lg:leading-[2rem] md:leading-[2rem] leading-[1.5rem]'>
              We looked for a solution to authenticate API requests were coming from our mobile apps, and that’s when we came across Approov.
          </h2>
          <LinkButton
            className={classNames('button-secondary button-xs w-fit bg-[#86b146] font-bold px-6 mt-[2rem] md:mt-[2.5rem] text-[1rem]')}
            href="/customer/sixt"
          >
            LEARN HOW
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export { ProtectsPanel }