import { ChartIcon } from '../../core/icons/chart'
import { DeviceCheckIcon } from '../../core/icons/device-check'
import { DnaIcon } from '../../core/icons/dna'
import { EyeSlashIcon } from '../../core/icons/eye-slash'
import { UserCheckIcon } from '../../core/icons/user-check'

const pillarData = [
  {
    title: 'App Integrity',
    dont: 'Allow your API keys to be used by bots, scripts and fake apps to access your backend data and services',
    doit: 'Attest your apps to ensure only truly authentic apps are authorized to make API calls',
    Icon: DnaIcon,
  },
  {
    title: 'Device Integrity',
    dont: 'Allow your app to run in compromised environments which can expose vulnerabilities and exfiltrate sensitive data',
    doit: 'Adopt comprehensive fine-grained device integrity checks enforced outside of the untrusted device environment',
    Icon: DeviceCheckIcon,
  },
  {
    title: 'Channel Integrity',
    dont: 'Allow man-in-the-middle attacks by assuming that plain TLS is good enough',
    doit: 'Employ securely certificate-pinned TLS with dynamic pin update',
    Icon: EyeSlashIcon,
  },
  {
    title: 'Credential Integrity',
    dont: 'Allow scripts to access your APIs and conduct credential stuffing attacks on your login endpoints',
    doit: 'Require frequent app authentication for strong trusted user and API authorization',
    Icon: UserCheckIcon,
  },
  {
    title: 'Service Integrity',
    dont: 'Allow zero-day threats to compromise your defenses and rely on new app releases to update protections',
    doit: 'Shield your APIs from exploitation of vulnerabilities with over-the-air updates for instant reaction to threat intelligence',
    Icon: ChartIcon,
  },
]

export { pillarData }
