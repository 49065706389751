import { Locker } from "./images";

export const threatData = [
  {
    title: "Account Takeover",
    Image: Locker,
    description: 
      "Form of identity theft using techniques like session hijacking, password cracking, or credential stuffing using mass log in attempts to verify the validity of stolen username/password pairs."
  },
  {
    title: "Fake Account Creation",
    Image: Locker,
    description:
      "Generation of fake accounts as a prelude to spamming, denial of service attacks, or credential stuffing attacks with stolen account info.",
  },
  {
    title: "Denial of Service",
    Image: Locker,
    description:
      "Targets resources of the application and database servers, or individual user accounts, to achieve denial of service (DoS), effectively preventing real customers from reaching the service or ruining the customer experience.",
  },
  {
    title: "Credit Fraud",
    Image: Locker,
    description:
      "Multiple payment authorization attempts with small payments used to verify the validity of bulk stolen payment card data and identify missing start/expiry dates and security codes for stolen payment card data by trying different values.",
  },
  {
    title: "App Impersonation",
    Image: Locker,
    description:
      "Tampered or impostor app enabling attacker to exploit and misdirect user actions and gather (Personally Identifiable Information) PII for future attacks or customer manipulation.",
  },
  {
    title: "Man in the Middle",
    Image: Locker,
    description:
      "Intercepting and/or manipulating API traffic via a Man-in-the-Middle attack enables an attacker to gather Personally Identifiable Information (PII) or perform malicious actions through the API.",
  },
  {
    title: "API Security Breach",
    Image: Locker,
    description:
      "Exploitation of any API security flaws to exfiltrate confidential enterprise or customer data or damage business operations.",
  },
  {
    title: "Scraping",
    Image: Locker,
    description:
      "Collection of large amounts of data via APIs in order to reuse or resell that data, often by aggregating data from multiple sources to provide an unofficial alternate marketplace.",
  },
  // {
  //   title: 'Scalping And Sniping',
  //   img: <StaticImage src={`${imgDir}/Scraping-Icon.png`} alt="" {...imgProps} />,
  //   description: 'Obtain limited-availability and/or preferred goods/services by unfair automation methods or injection of last minute bid or offer for goods or services.',
  // },
  // {
  //   title: 'Spamming',
  //   img: <StaticImage src={`${imgDir}/Generic-Breach-Icon.png`} alt="" {...imgProps} />,
  //   description: 'Malicious or questionable information or fake activity that abuses the service or skews site activity, e.g.travel reviews or social influencing.',
  // },
  // {
  //   title: 'Coupon Fraud',
  //   img: <StaticImage src={`${imgDir}/Generic-Breach-Icon.png`} alt="" {...imgProps} />,
  //   description: 'Mass enumeration of gift card and coupon numbers, voucher codes, discount tokens which can be resold.',
  // },
  // {
  //   title: 'Denial of Inventory',
  //   img: <StaticImage src={`${imgDir}/Denial-of-Service-Icon.png`} alt="" {...imgProps} />,
  //   description: 'Deplete goods or services stock without ever completing the purchase or committing to the transaction.',
  // },
  // {
  //   title: 'Ad Fraud',
  //   img: <StaticImage src={`${imgDir}/Generic-Breach-Icon.png`} alt="" {...imgProps} />,
  //   description: 'False clicks and fraudulent display of web-placed advertisements.',
  // },
  // {
  //   title: 'Security Breach',
  //   img: <StaticImage src={`${imgDir}/Generic-Breach-Icon.png`} alt="" {...imgProps} />,
  //   description: 'Breach of the security perimeter.',
  // },
];
