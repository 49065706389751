import React from 'react'
import classNames from 'classnames'
import { Link } from './link'

const LinkBox = ({ className, href, children, ...rest }) => {
  return (
    <Link className={classNames('link-box', className)} href={href} {...rest}>
      {children}
    </Link>
  )
}

export { LinkBox }
