import React from 'react'
import { StaticImage } from "gatsby-plugin-image";

const tableContext = [
  {
    new: false,
    name: "Monthly Active Users (MAUs) Included",
    small: "10,000",
    medium: "100,000",
    large: "1,000,000",
    jumbo: ">1,000,000",
  },
  {
    new: false,
    name: "Terms & Conditions",
    small: '<a href="/terms">Standard Terms</a>',
    medium: '<a href="/terms">Standard Terms</a>',
    large: '<a href="/terms">Standard Terms</a>',
    jumbo: "Custom",
  },
  {
      new: false,
      name: "24/7 Priority Support",
      small: "",
      medium: "•",
      large: "•",
      jumbo: "•",
  },
  {
      new: false,
      name: "Onboarding Consultations",
      small: "",
      medium: "•",
      large: "•",
      jumbo: "•",
  },
  {
      new: false,
      name: "Offline Measurement Capability",
      small: "",
      medium: "",
      large: "•",
      jumbo: "•",
  },
  {
      new: false,
      name: "Web Protection Integration",
      small: "",
      medium: "",
      large: "•",
      jumbo: "•"
  },
  {
    new: false,
    name: "Pricing for Additional MAUs ",
    small: 0.03,
    medium: 0.02,
    large: 0.01,
    jumbo: "Custom",
  },
  {
    new: false,
    name: "Billing Options",
    small: "Credit Card",
    medium: "Credit Card or Invoiced",
    large: "Credit Card or Invoiced",
    jumbo: "Credit Card or Invoiced",
    nospliter: true

  },
  {
    spliter: true,
    title: "Features Common to All Plans"
  },
  {
    new: false,
    name: "Installed but Unused Apps",
    small: "Always Free",
    medium: "Always Free",
    large: "Always Free",
    jumbo: "Always Free",
  },
    {
    new: false,
    name: "Unauthorized Traffic, Attacks & Failed  Requests",
    small: "Always Free",
    medium: "Always Free",
    large: "Always Free",
    jumbo: "Always Free",
  },
  {
    new: false,
    name: "Runtime Application Self-Protection (RASP)",
    small: "•",
    medium: "•",
    large: "•",
    jumbo: "•",
  },

  {
    new: false,
    common: true,
    name: "Cross Platform Mobile OS Support",
    contents: [
      "Android (5+)",
      "iOS (10+)",
      "Huawei HarmonyOS",
    ]
  },
  {
    new: false,
    common: true,
    name: "Approov App Attestation",
    contents: [
      "App Attestation on every API call",
      "Device Attestation on every API call",
      "Channel Protection on every API call",
      "Positive Security Model for API security",
      "Optional User Authorization Token Binding",
      "Optional API message signing support"
    ]
  },
  {
    new: false,
    common: true,
    name: "Dynamic Certificate Pinning",
    contents: [
      "Block Man-in-the-Middle (MitM) attacks",
      "Cloud pin updates on demand",
      "CLI tools for certificate management",
      "Managed trust roots support",
      "Continuous API pin monitoring",
    ]
  },
  {
    new: false,
    common: true,
    name: "App Environment Detections",
    contents: [
      "Debug Detection",
      "Emulator Detection",
      "Cloner App Detection",
      "Memory dump detection",
      "App automation detection",
    ]
  },
  {
    new: false,
    common: true,
    name: "App Tamper",
    contents: [
      "App Signature Verification",
    ]
  },
  {
    new: false,
    common: true,
    name: "Detections",
    contents: [
      "Frida Detection",
      "Cydia Detection",
      "TrollStore Detection",
      "Xposed Detection",
      "Riru Detection",
      "Memory Signature Analysis",
      "Advanced Hooking Detection",
    ]
  },
  {
    new: false,
    common: true,
    name: "Device Tamper Detections",
    contents: [
      "Jailbreak Detection",
      "Root Detection",
      "Android Magisk Detection",
    ]
  },
  {
    new: false,
    common: true,
    name: "Live Threat Monitoring Dashboards",
    contents: [
      "Real-Time Dashboard Support",
      "Full Visibility into Live Threats"
    ]
  },
  {
    new: false,
    common: true,
    name: "Approov Attestation Tokens",
    contents: [
      "Industry standard JWTs",
      "Short expiry lifetime",
      "HS256, HS384, HS512, PS256, PS384, PS512, ES256, ES384 and ES512 signing algorithm support",
      "A256GCMKW encryption JWE support",
      "JWT claim configuration",
      "JWKS key import/export",
      "JWKS URI endpoint support",
    ]
  },
  {
    new: false,
    common: true,
    name: "Runtime Secrets Protection",
    contents: [
      "Just-in-time delivery to attested apps",
      "Third-party API Key Protection",
      "Key rotation on demand",
      "Removal of hardcoded secrets",
      "MitM secret extraction protection",
      "On-the-fly secrets substitution",
      "Optional app instance encrypted strings",
    ]
  },
  {
    new: false,
    common: true,
    name: "Over-the-Air Updates",
    contents: [
      "Certificate Pins",
      "Security Policies",
      "Protection Updates",
      "Runtime Secrets",
    ]
  },
  {
    new: false,
    common: true,
    name: "Platform Attestation Integration",
    contents: [
      "Android Play Integrity",
      "Android SafetyNet",
      "iOS AppAttest",
      "iOS DeviceCheck",
    ]
  },
  {
    new: false,
    common: true,
    name: "Administration",
    contents: [
      "Role-based CLI tool (Linux, MacOS, Windows)",
      "Team member Role Management",
      "CI/CD Automation Token Integration",
    ],
    nospliter: true
  },
  {
    spliter: true,
    title: "Supported Development Environments"
  },
];

const environments = [
  {
    bigTitle: "Mobile App Integration Quickstarts",
    smallTitle: "",
    tag: "mobile",
    logos: [
      {
        label: "Android",
        file: <StaticImage alt="" src="./images/Android.png"/>
      },
      {
        label: "Cordova",
        file: <StaticImage alt="" src="./images/Cordova.png"/>
      },
      {
        label: "Flutter",
        file: <StaticImage alt="" src="./images/Flutter.png"/>
      },
      {
        label: "Ionic",
        file: <StaticImage alt="" src="./images/Ionic.png"/>
      },
      {
        label: "iOS",
        file: <StaticImage alt="" src="./images/IOS.png"/>
      },
      {
        label: "NativeScript",
        file: <StaticImage alt="" src="./images/NativeScript.png"/>
      },
      {
        label: "React Native",
        file: <StaticImage alt="" src="./images/React_Native.png"/>
      },
      {
        label: "Xamarin",
        file: <StaticImage alt="" src="./images/Xamarin.png"/>
      },
      {
        label: "KMM",
        file: <StaticImage alt="" src="./images/Kotlin-logo.png"/>
      },
    ]
  },
  {
    bigTitle: "Backend API Integration Quickstarts",
    smallTitle: "",
    tag: "backend",
    logos: [
      {
        label: "Asp.net",
        file: <StaticImage alt="" src="./images/Asp.net.png"/>
      },
      {
        label: "AWS API Gateway",
        file: <StaticImage alt="" src="./images/Aws-api-gateway.png"/>
      },
      {
        label: "Azure",
        file: <StaticImage alt="" src="./images/Azure.png"/>
      },
      {
        label: "Cloudflare Worker",
        file: <StaticImage alt="" src="./images/Cloudflare-worker.png"/>
      },
      {
        label: "Elixir",
        file: <StaticImage alt="" src="./images/Elixir.png"/>
      },
      {
        label: "Golang",
        file: <StaticImage alt="" src="./images/Golang.png"/>
      },
      {
        label: "React Native",
        file: <StaticImage alt="" src="./images/React_Native.png"/>
      },
      {
        label: "Google API GW for Cloud Run",
        file: <StaticImage alt="" src="./images/Google-api-gw-for-cloud-run.png"/>
      },
      {
        label: "Java Spring",
        file: <StaticImage alt="" src="./images/Java-spring.png"/>
      },
      {
        label: "Kong",
        file: <StaticImage alt="" src="./images/Kong.png"/>
      },
      {
        label: "Mulesoft API Gateway",
        file: <StaticImage alt="" src="./images/Mulesoft-api-gateway.png"/>
      },
      {
        label: "Nestjs",
        file: <StaticImage alt="" src="./images/Nestjs.png"/>
      },
      {
        label: "NGINX",
        file: <StaticImage alt="" src="./images/Nginx.png"/>
      },
      {
        label: "Node",
        file: <StaticImage alt="" src="./images/Node.png"/>
      },
      {
        label: "PHP",
        file: <StaticImage alt="" src="./images/Php.png"/>
      },
      {
        label: "Python",
        file: <StaticImage alt="" src="./images/Python.png"/>
      },
      {
        label: "Swift Vapor",
        file: <StaticImage alt="" src="./images/Swift-vapor.png"/>
      },
      {
        label: "Tyk",
        file: <StaticImage alt="" src="./images/Tyk.png"/>
      }
    ],
    nospliter: true
  }
]

export { tableContext, environments }