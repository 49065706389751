import React from 'react'
import classNames from 'classnames'

const Prose = ({ className, children, ...rest }) => {
  return (
    <div
      className={classNames(
        'prose prose-base prose-primary max-w-none',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

const NotProse = ({ className, children, ...rest }) => {
  return (
    <div className={classNames('not-prose', className)} {...rest}>
      {children}
    </div>
  )
}

export { Prose, NotProse }
