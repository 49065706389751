import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
}

export const MhealthEKGAppImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mhealth-ekg-app.jpg`}
    alt="mHealth EKG App"
    {...imgProps}
  />
)

export const FireBurstImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/fire-burst.jpg`}
    alt="fire burst"
    {...imgProps}
  />
)
