import * as React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import './tooltip-styles.css'

const Tooltip = ({ tip, trigger, children, ...rest }) => {
  const triggerOpt = trigger || 'hover'
  const interactiveOpt = triggerOpt === 'click'
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    placement: 'top',
    trigger: triggerOpt,
    interactive: interactiveOpt,
  })

  return (
    <div {...rest}>
      <button type="button" tabIndex="-1" ref={setTriggerRef}>
        {children}
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
          {tip}
        </div>
      )}
    </div>
  )
}

export { Tooltip }
