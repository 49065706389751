import React from 'react'
import { ContactForm } from './contact-form'
import { LeadForm } from './lead-form'
import { PinningForm } from './pinning-form'
import { QuestionForm } from './question-form'
import { TrialForm } from './trial-form'
import { useOffer } from './use-offer'

const ComponentMap = {
  contact: ContactForm,
  lead: LeadForm,
  pinning: PinningForm,
  question: QuestionForm,
  trial: TrialForm,
  default: LeadForm,
}

export const OfferForm = ({ offer, ...rest }) => {
  // offer info

  const { offerType } = useOffer(offer)

  const Form = ComponentMap[offerType] || ComponentMap.default

  return <Form offer={offer} {...rest} />
}
