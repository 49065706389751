import React from "react";

import { PreFooterBG, DivBG, Link } from "@components/core";
export const SemiFooter = () => (
  <div className="bg-[#191917] flex justify-center mt-0">
    <DivBG className="w-full pl-auto pr-auto h-auto md:h-[400px] max-w-[1208px] " special bgImage={PreFooterBG}>
      <div className="container flex justify-center items-center h-[auto] sm:h-[370px]   sm:bg-transparent">
        <div className="w-full flex justify-center flex-wrap">
          <div className="w-full sm:w-1/2 md:w-1/3 text-center flex-col justify-center items-center p-1 my-5 ">
            <p className="font-[400] text-[1.75em] sm:font-[900] sm:text-[2.75em] leading-[1em] text-white">
              Request a Demo
            </p>
            <p className="text-[1em] leading-[1.35em] text-white px-[1rem] mt-5 mb-2">
              Give us 30 minutes and our security experts will show you how to protect your revenue and business
              data by deploying Approov to secure your mobile apps and your APIs
            </p>
            <div className="flex justify-center">
              <Link
                href="https://approov.io/info/schedule-a-demo"
                className="mt-[1.6rem] hover:!no-underline"
              >
                <span className="text-white bg-[#86b146] mt-[20px] px-[16px] py-[8px] font-bold text-[20px] w-max text-center md:text-left not-prose rounded">
                  REQUEST A DEMO
                </span>
              </Link>
            </div>
          </div>
          <div className="w-0 md:w-1/3"></div>
          <div className="w-full sm:w-1/2 md:w-1/3 text-center flex flex-wrap justify-center items-center p-1 my-5">
            <p className="font-[400] text-[1.75em]  sm:font-[900] sm:text-[2.75em] leading-[1em] text-white">
              Get a Trial
            </p>
            <p className="text-[1em] leading-[1.35em] text-white px-[1rem] mt-4 mb-2">
              Approov offers a complimentary 30 day trial (no credit card necessary) to give you immediate and valuable insight into the security risks of your mobile apps and the devices they run on.
            </p>
            <div className="flex justify-center">
                <Link
                    href="/signup"
                    className="mt-[1.6rem] hover:!no-underline"
                >
                  <span className="text-white bg-[#86b146] mt-[20px] px-[16px] py-[8px] font-bold text-[20px] w-max text-center md:text-left not-prose rounded">
                    GET A TRIAL
                  </span>
                </Link>
            </div>
          </div>
        </div>
      </div>
    </DivBG>
  </div>
);
