import React from 'react'

export const ThreatAttackIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <path d="M11.8906 8.59375C12.3652 8.59375 12.75 8.20899 12.75 7.73438C12.75 7.25976 12.3652 6.875 11.8906 6.875C11.416 6.875 11.0312 7.25976 11.0312 7.73438C11.0312 8.20899 11.416 8.59375 11.8906 8.59375Z" fill="#1B3D57"/>
  <path d="M8.45312 8.59375C8.92774 8.59375 9.3125 8.20899 9.3125 7.73438C9.3125 7.25976 8.92774 6.875 8.45312 6.875C7.97851 6.875 7.59375 7.25976 7.59375 7.73438C7.59375 8.20899 7.97851 8.59375 8.45312 8.59375Z" fill="#1B3D57"/>
  <path d="M5.01562 8.59375C5.49024 8.59375 5.875 8.20899 5.875 7.73438C5.875 7.25976 5.49024 6.875 5.01562 6.875C4.54101 6.875 4.15625 7.25976 4.15625 7.73438C4.15625 8.20899 4.54101 8.59375 5.01562 8.59375Z" fill="#1B3D57"/>
  <path d="M51.4219 12.0312H1.57812C1.10375 12.0312 0.71875 11.6463 0.71875 11.1719C0.71875 10.6975 1.10375 10.3125 1.57812 10.3125H51.4219C51.8963 10.3125 52.2812 10.6975 52.2812 11.1719C52.2812 11.6463 51.8963 12.0312 51.4219 12.0312Z" fill="#1B3D57"/>
  <path d="M1.57812 37.8125C1.10375 37.8125 0.71875 37.4275 0.71875 36.9531V6.01562C0.71875 4.59422 1.87547 3.4375 3.29688 3.4375H49.7031C51.1245 3.4375 52.2812 4.59422 52.2812 6.01562V21.4844C52.2812 21.9587 51.8963 22.3438 51.4219 22.3438C50.9475 22.3438 50.5625 21.9587 50.5625 21.4844V6.01562C50.5625 5.54297 50.1775 5.15625 49.7031 5.15625H3.29688C2.8225 5.15625 2.4375 5.54297 2.4375 6.01562V36.9531C2.4375 37.4275 2.0525 37.8125 1.57812 37.8125Z" fill="#1B3D57"/>
  <path d="M36.8125 53.2812H17.0469C16.5725 53.2812 16.1875 52.8962 16.1875 52.4219C16.1875 51.9475 16.5725 51.5625 17.0469 51.5625H18.7656C20.187 51.5625 21.3438 50.4058 21.3438 48.9844V47.2656C21.3438 46.7912 21.7288 46.4062 22.2031 46.4062C22.6775 46.4062 23.0625 46.7912 23.0625 47.2656V48.9844C23.0625 49.952 22.7411 50.8441 22.2014 51.5625H30.7986C30.2589 50.8441 29.9375 49.952 29.9375 48.9844V43.8281C29.9375 43.3538 30.3225 42.9688 30.7969 42.9688C31.2712 42.9688 31.6562 43.3538 31.6562 43.8281V48.9844C31.6562 50.4058 32.813 51.5625 34.2344 51.5625H36.8125C37.2869 51.5625 37.6719 51.9475 37.6719 52.4219C37.6719 52.8962 37.2869 53.2812 36.8125 53.2812Z" fill="#1B3D57"/>
  <path d="M30.7969 44.6875H3.29688C1.87547 44.6875 0.71875 43.5308 0.71875 42.1094V36.9531C0.71875 36.4788 1.10375 36.0938 1.57812 36.0938H25.6406C26.115 36.0938 26.5 36.4788 26.5 36.9531C26.5 37.4275 26.115 37.8125 25.6406 37.8125H2.4375V42.1094C2.4375 42.582 2.8225 42.9688 3.29688 42.9688H30.7969C31.2712 42.9688 31.6562 43.3538 31.6562 43.8281C31.6562 44.3025 31.2712 44.6875 30.7969 44.6875Z" fill="#1B3D57"/>
  <path d="M31.7969 24.0625H30.0781C29.6038 24.0625 29.2188 23.6775 29.2188 23.2031C29.2188 22.7288 29.6038 22.3438 30.0781 22.3438H31.7969C32.2713 22.3438 32.6562 22.7288 32.6562 23.2031C32.6562 23.6775 32.2713 24.0625 31.7969 24.0625Z" fill="#1B3D57"/>
  <path d="M47.2656 17.1875H30.0781C29.6038 17.1875 29.2188 16.8025 29.2188 16.3281C29.2188 15.8538 29.6038 15.4688 30.0781 15.4688H47.2656C47.74 15.4688 48.125 15.8538 48.125 16.3281C48.125 16.8025 47.74 17.1875 47.2656 17.1875Z" fill="#1B3D57"/>
  <path d="M47.2656 20.625H30.0781C29.6038 20.625 29.2188 20.24 29.2188 19.7656C29.2188 19.2913 29.6038 18.9062 30.0781 18.9062H47.2656C47.74 18.9062 48.125 19.2913 48.125 19.7656C48.125 20.24 47.74 20.625 47.2656 20.625Z" fill="#1B3D57"/>
  <path d="M12.8906 24.0625H7.73438C7.26 24.0625 6.875 23.6775 6.875 23.2031C6.875 22.7288 7.26 22.3438 7.73438 22.3438H12.8906C13.365 22.3438 13.75 22.7288 13.75 23.2031C13.75 23.6775 13.365 24.0625 12.8906 24.0625Z" fill="#1B3D57"/>
  <path d="M24.9219 17.1875H7.73438C7.26 17.1875 6.875 16.8025 6.875 16.3281C6.875 15.8538 7.26 15.4688 7.73438 15.4688H24.9219C25.3962 15.4688 25.7812 15.8538 25.7812 16.3281C25.7812 16.8025 25.3962 17.1875 24.9219 17.1875Z" fill="#1B3D57"/>
  <path d="M24.9219 20.625H7.73438C7.26 20.625 6.875 20.24 6.875 19.7656C6.875 19.2913 7.26 18.9062 7.73438 18.9062H24.9219C25.3962 18.9062 25.7812 19.2913 25.7812 19.7656C25.7812 20.24 25.3962 20.625 24.9219 20.625Z" fill="#1B3D57"/>
  <path d="M45.016 28.0716C45.1993 27.8887 45.496 27.8887 45.6793 28.0716C45.8621 28.2548 45.8621 28.5516 45.6793 28.7348C45.496 28.9177 45.1993 28.9177 45.016 28.7348C44.8332 28.5516 44.8332 28.2548 45.016 28.0716Z" fill="#78A65A"/>
  <path d="M40.3757 24C45.5451 24 49.7507 28.2056 49.7507 33.375C49.7507 35.2448 49.2055 37.0351 48.1705 38.5786L54.4446 44.792C55.1796 45.5196 55.1859 46.7091 54.4587 47.4438C54.1037 47.8025 53.6303 48 53.1257 48C52.6287 48 52.1603 47.8076 51.8063 47.4579L45.5066 41.2183C43.9794 42.2215 42.214 42.75 40.3757 42.75C35.2064 42.75 31.0007 38.5444 31.0007 33.375C31.0007 28.2056 35.2064 24 40.3757 24ZM52.4656 46.7914C52.6426 46.9662 52.877 47.0625 53.1257 47.0625C53.3781 47.0625 53.6149 46.9637 53.7924 46.7843C54.156 46.417 54.1527 45.8221 53.785 45.4582L49.6079 41.3214C49.1991 41.7957 48.7545 42.2383 48.2788 42.6444L52.4656 46.7914ZM47.6105 41.9826C48.089 41.5797 48.5342 41.1371 48.9404 40.6605L47.6049 39.3379C47.2057 39.8215 46.7602 40.2633 46.2732 40.6582L47.6105 41.9826ZM40.3757 41.8125C42.1496 41.8125 43.8481 41.2659 45.2875 40.2318C46.0147 39.7094 46.652 39.0783 47.1818 38.3562L47.1819 38.3561C48.2491 36.9021 48.8132 35.1796 48.8132 33.375C48.8132 28.7226 45.0282 24.9375 40.3757 24.9375C35.7233 24.9375 31.9382 28.7226 31.9382 33.375C31.9382 38.0275 35.7233 41.8125 40.3757 41.8125Z" fill="#78A65A"/>
  <path d="M40.375 25.875C41.6785 25.875 42.9629 26.2148 44.0892 26.8577C44.314 26.9861 44.3923 27.2724 44.264 27.4972C44.1357 27.7221 43.8494 27.8003 43.6245 27.672C42.6394 27.1097 41.5157 26.8125 40.375 26.8125C36.7564 26.8125 33.8125 29.7564 33.8125 33.375C33.8125 36.9936 36.7564 39.9375 40.375 39.9375C43.9936 39.9375 46.9375 36.9936 46.9375 33.375C46.9375 32.2341 46.6403 31.1106 46.0781 30.126C45.9498 29.9012 46.0279 29.6149 46.2527 29.4865C46.4776 29.3582 46.7638 29.4364 46.8922 29.6611C47.5352 30.7871 47.875 32.0713 47.875 33.375C47.875 37.5105 44.5105 40.875 40.375 40.875C36.2395 40.875 32.875 37.5105 32.875 33.375C32.875 29.2395 36.2395 25.875 40.375 25.875Z" fill="#78A65A"/>
</svg>
)