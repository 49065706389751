import React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

export const ResourceBanner = ({ title, subtitle, ...rest }) => (
  <>
    <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] max-h-[474px] flex item-center md:block">
      <div className="container flex">
        <div className="w-full md:w-1/2 flex justify-center  relative mt-[-20px] md:mt-[-124px]">
          <p className="text-[35px] md:text-[58px] leading-[58px] font-black self-center text-center tracking-tight md:mt-[262px] md:ml-[-41px]">
            <h1 className="text-[35px] md:text-[58px] font-black leading-[58px] self-center text-center tracking-tight mb-[0px] text-[#acafb3]">{title}</h1>
            <hr className="border-[3.1px] bg-[#b91684] border-[#b91684] w-[79%] m-auto ml-[23px] md:ml-[34px] mt-[21px]"/>
          </p>
        </div>
        <div className="hidden md:block w-full md:w-1/2 md:ml-[-22px] md:mt-[21px] not-prose">
          <StaticImage
            src={`./images/resource.png`}
            className="md:w-[372px] h-auto"
            alt="Mobile App Security Resources | Approov"
          />
        </div>
      </div>
    </div>
  </>
)
