import React from 'react'
import Helmet from 'react-helmet'
import { useSiteMetadata } from '../../site/metadata'
// component to display tailwind-debug-screens plugin

const DebugProvider = ({ children }) => {
  const { build } = useSiteMetadata()

  return (
    <>
      {build.mode === 'development' && (
        <Helmet>
          <body className="debug-screens" />
        </Helmet>
      )}
      {children}
    </>
  )
}

export { DebugProvider }
