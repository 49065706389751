import { useState } from 'react'

export const useOpenClose = () => {
  const [isOpen, setIsOpen] = useState(false)

  const setOpen = () => setIsOpen(true)
  const setClosed = () => setIsOpen(false)
  const toggle = () => setIsOpen(!isOpen)

  return { isOpen, setIsOpen, setOpen, setClosed, toggle }
}
