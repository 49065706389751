import { useLocalStorage } from '../../core/local-storage'

export const useLead = () => {
  // internal state

  const [lead, setLead] = useLocalStorage('lead', {
    firstName: '',
    lastName: '',
    email: '',
  })

  return [lead, setLead]
}
