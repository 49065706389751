import React from 'react'
import classNames from 'classnames'

// const getChunk = (contents, limit) => {
//   let chunkSize = limit;
//   if(contents.length / 2 > chunkSize)
//     chunkSize = Math.ceil(contents.length / 2)
//   let newContents = new Array();
//   for (let i = 0; i < contents.length; i += chunkSize) {
//       let chunk = contents.slice(i, i + chunkSize);
//       newContents.push(chunk);
//   }
//   return newContents
// }

const CommonColumn = ({ start, contents, limit, className, ...rest }) => {
  let contentsCount = contents.length > 3

  return (
    <td colSpan={6}>
      <div 
        className={classNames("flex", className)}
        { ...rest }  
      >
          <div 
            className={classNames("w-full", {"columns-2" : contentsCount})}
          >
            {contents.map((str) => 
            <div className="flex" key={str}>
              <span className="text-[#c2d8a2] font-bold mr-[5px]">+</span>
              <p>
                {str}
              </p>
            </div>
            )}
        </div>
      </div>
    </td>
  )
}

export { CommonColumn }