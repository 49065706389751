import { useStaticQuery, graphql } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'

export const usePage = () => {
  const query = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            site {
              url
            }
          }
        }
      }
    `
  )
  const siteUrl = query.site.siteMetadata.site.url
  const siteUri = siteUrl.slice(siteUrl.indexOf(':') + 1)

  const location = useLocation()
  const pageUrl = `${siteUrl}${location.pathname}${location.search || ''}${
    location.hash || ''
  }`
  const pageUri = `${siteUri}${location.pathname}${location.search || ''}${
    location.hash || ''
  }`
  const pageName = location.pathname

  let queryParams = {}
  if (location.search) {
    queryParams = queryString.parse(location.search) || {}

    // convert any array values to strings
    Object.entries(queryParams).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        queryParams[key] = value.toString()
      }
    })
  }

  let fragment = ''
  if (location.hash && location.hash.length > 1) {
    fragment = location.hash.slice(1)
  }

  return {
    siteUrl,
    location,
    pageUrl,
    pageUri,
    pageName,
    queryParams,
    fragment,
  }
}
