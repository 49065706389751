import React from 'react'
import classNames from 'classnames'

export const ThreatCard = ({
  className,
  title,
  description,
  href,
  Image,
  ...rest
}) => {
  return (
    <div
      className={classNames('relative', className)}
      {...rest}
    >
      <div className="bg-[#b91684] absolute w-[14rem] h-[500px]">
        <p className="px-2 py-2 m-0 leading-[1.63rem] text-[1.2rem] text-left mt-[10px] w-full break-words">
          {description}{' '}<br/>
        </p>
      </div>
      <div className="absolute left-[14rem]">{Image}</div>
    </div>
  )
}
