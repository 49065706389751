import React, { useState } from 'react'
//import Headroom from 'react-headroom'
import { Container, Link, Prose, Section, Seo } from '@components/core'
import { ApproovLogo } from '../approov'
import { CookiesConsent } from '../cookies'

const year = new Date().getFullYear()

// to use a headroom header, replace the sticky div with headroom

const Header = () => {
  return (
    <Section as="header" className="bg-white shadow-sm shadow-gray-500">
      <Container className="container-lg">
        <div className="flex items-center justify-start">
          <Link href="/">
            <ApproovLogo 
            // className="w-auto h-16 py-2" 
            className="!h-auto w-auto w-[5.5rem] md:ml-[8px] mt-[16px] mr-4 lg:w-[11rem]"
            />
          </Link>
        </div>
      </Container>
    </Section>
  )
}

const Footer = () => {
  return (
    <footer className="flex items-center justify-center w-full py-1 text-white bg-gray-900">
      {`© ${year} CriticalBlue, Ltd.`}
    </footer>
  )
}



const LandingPageLayout = ({ page, children, pathname }) => {
  const [tag, setTag] = useState();
  const changeTag = (tag) => {
    setTag(tag);
    console.log("tag==============================>",tag);
  }
  return (
    <>
      <Seo name={pathname} onChangeTag={changeTag}/>
      <div className="flex flex-col w-full min-h-screen">
        <div className="sticky top-0 z-40">
          <Header />
        </div>

        <Prose className="flex grow">
          <main className="w-screen">{children}</main>
        </Prose>

        <div>
          <Footer />
        </div>
      </div>

      <CookiesConsent />
    </>
  )
}

export { LandingPageLayout }
