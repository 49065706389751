import React from 'react'
import classNames from 'classnames'
import { Anchor } from './anchor'

const LinkButton = ({ className, href, children, ...rest }) => {
  return (
    <Anchor
      className={classNames('button no-underline', className)}
      href={href}
      {...rest}
    >
      {children}
    </Anchor>
  )
}

export { LinkButton }
