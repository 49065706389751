import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgHeight = 34
const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
  layout: 'fixed',
  height: imgHeight,
  objectFit: 'contain',
}

export * from './security'
export * from './api'
export * from './thumbup'
export * from './bank'
export * from './chart'
export * from './check'
export * from './chevron-down'
export * from './chevron-right'
export * from './device-check'
export * from './device-setup'
export * from './device-update'
export * from './dna'
export * from './document-search'
export * from './document-text'
export * from './error'
export * from './eye-slash'
export * from './facebook'
export * from './globe'
export * from './help'
export * from './identification'
export * from './info'
export * from './instagram'
export * from './integration'
export * from './lightning-bolt'
export * from './link'
export * from './list'
export * from './menu'
export * from './pacman'
export * from './poison'
export * from './prisoner'
export * from './search-off'
export * from './search'
export * from './shield-check'
export * from './stethoscope'
export * from './success'
export * from './support'
export * from './terminal'
export * from './token'
export * from './user-check'
export * from './warning'
export * from './x'
export * from './cloud'
export * from './crypto_security'
export * from './platform_attestation'
export * from './hardcoded_secret'
export * from './flexibility'
export * from './no_change'
export * from './refresh_update'
export * from './operational_info'
export * from './threat_attacks'
export * from './token_security'
export * from './api_abuse'
export * from './backend_integration'
export * from './protect_data'
export * from './mitm'
export * from './rocket'
export * from './hacking_protection'
export * from './ban_emulator'
export * from './block_app'
export * from './stop'

export const GitHubIcon = () => (
  <StaticImage
    src={`${imgDir}/Github.png`}
    alt=""
    {...imgProps}
  />
)

export const LinkedInIcon = () => (
  <StaticImage
    src={`${imgDir}/LI.png`}
    alt=""
    {...imgProps}
  />
)

export const TwitterIcon = () => (
  <StaticImage
    src={`${imgDir}/Twitter.png`}
    alt=""
    {...imgProps}
  />
)

export const YoutubeIcon = () => (
  <StaticImage
    src={`${imgDir}/Youtube.webp`}
    alt=""
    {...imgProps}
  />
)
export const FreeTrialIcon = () => (
  <StaticImage
    src={`${imgDir}/FreeTrial.webp`}
    alt=""
    {...imgProps}
  />
)
export const Pinning = () => (
  <StaticImage
    src={`${imgDir}/Pinning.webp`}
    alt=""
    {...imgProps}
  />
)
