import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { Menu, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { Link } from './link'

const LinkMenuBox = ({ className, items, children, ...rest }) => {
  const [triggerRef, setTriggerRef] = useState(null)
  const [popperRef, setPopperRef] = useState(null)
  const { styles, attributes } = usePopper(triggerRef, popperRef, {
    placement: 'right',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -20],
        },
      },
    ],
  })

  return (
    <Menu as="div" className="relative">
      {({ open }) => (
        <>
          <Menu.Button
            ref={setTriggerRef}
            className={classNames('link-box', className)}
            {...rest}
          >
            {children}
          </Menu.Button>
          {open && (
            <div
              className="z-10"
              ref={setPopperRef}
              style={styles.popper}
              {...attributes.popper}
            >
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items>
                  <div className="link-menu-items">
                    {items.map(({ name, href }) => (
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            href={href}
                            className={
                              active
                                ? 'link-menu-item-active'
                                : 'link-menu-item'
                            }
                          >
                            {name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </div>
          )}
        </>
      )}
    </Menu>
  )
}

export { LinkMenuBox }
