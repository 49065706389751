import React from 'react'
import classNames from 'classnames'
import { ErrorIcon } from '../icons/error'
import { InfoIcon } from '../icons/info'
import { SuccessIcon } from '../icons/success'
import { WarningIcon } from '../icons/warning'

const statusInfo = {
  info: {
    icon: InfoIcon,
    boxCN: 'rounded-md bg-blue-200 p-4',
    iconCN: 'w-5 h-5 text-blue-700',
    titleCN: 'm-0 mt-0 text-sm font-bold text-blue-700',
    bodyCN: 'm-0 mt-2 text-sm text-blue-700',
    bodyOnlyCN: 'm-0 mt-0 text-sm text-blue-700',
  },
  success: {
    icon: SuccessIcon,
    boxCN: 'rounded-md bg-green-200 p-4',
    iconCN: 'w-5 h-5 text-green-700',
    titleCN: 'm-0 mt-0 text-sm font-bold text-green-700',
    bodyCN: 'm-0 mt-2 text-sm text-green-700',
    bodyOnlyCN: 'm-0 mt-0 text-sm text-green-700',
  },
  warning: {
    icon: WarningIcon,
    boxCN: 'rounded-md bg-yellow-100 p-4',
    iconCN: 'w-5 h-5 text-yellow-500',
    titleCN: 'm-0 mt-0 text-sm font-bold text-yellow-500',
    bodyCN: 'm-0 mt-2 text-sm text-yellow-500',
    bodyOnlyCN: 'm-0 mt-0 text-sm text-yellow-500',
  },
  error: {
    icon: ErrorIcon,
    boxCN: 'rounded-md bg-red-200 p-4',
    iconCN: 'w-5 h-5 text-red-700',
    titleCN: 'm-0 mt-0 text-sm font-bold text-red-700',
    bodyCN: 'm-0 mt-2 text-sm text-red-700',
    bodyOnlyCN: 'm-0 mt-0 text-sm text-red-700',
  },
}

const Alert = ({ className, status, title, children, ...rest }) => {
  const {
    icon: Icon,
    boxCN,
    iconCN,
    titleCN,
    bodyCN,
    bodyOnlyCN,
  } = statusInfo[status]

  if (title) {
    return (
      <div className={classNames(boxCN, className)} {...rest}>
        <div className="flex">
          <div className="flex-shrink-0">
            <Icon className={iconCN} aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className={titleCN}>{title}</div>
            <div className={bodyCN}>{children}</div>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className={classNames(boxCN, className)} {...rest}>
        <div className="flex">
          <div className="flex-shrink-0">
            <Icon className={iconCN} aria-hidden="true" />
          </div>
          <div className="ml-3">
            <div className={bodyOnlyCN}>{children}</div>
          </div>
        </div>
      </div>
    )
  }
}

export { Alert }
