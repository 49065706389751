import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

// wraps react-query client provider with sensible defaults

const queryClient = new QueryClient()

export const QueryProvider = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
)
