import React from 'react'
import classNames from 'classnames'
import { Tab } from '@headlessui/react'
import { useFieldArray, useWatch } from 'react-hook-form'
import isValidDomain from 'is-valid-domain'
import { useMutation } from 'react-query'
import { useCookies } from 'react-cookie'
import { Alert, Button, Link, useOpenClose } from '@components/core'
import { configRequest } from './config-request'
import { XIcon } from '../../core/icons/x'
import {
  refs,
  CertificateFilesInfo,
  DomainInfo,
  ErrorDialog,
  PinLeafCertificateInfo,
  PinRootCertificateInfo,
  PinSubdomainsInfo,
  ResetDialog,
} from './user-info'

export const ConfigPanel = ({ controls }) => {
  // explode controls
  const [cookies, setCookie]  = useCookies(['domain-values']);
  const {
    blankDomain,
    form: {
      control,
      formState: { errors, isSubmitting },
      getValues,
      handleSubmit,
      register,
      reset,
      setError,
      setValue,
      trigger,
      watch,
    },
    setResults,
    setResultsTab,
  } = controls

  // define domains array and ops

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'domains',
  })

  // create reset and error dialog controls

  const resetPanel = () => {
    reset()
    setResults({})
  }

  const resetDialog = useOpenClose()

  const onReset = () => {
    resetPanel()
    resetDialog.setClosed()
  }

  const errorDialog = useOpenClose()

  // define domain operations

  const findDomain = (name) => {
    const domains = getValues(`domains`)
    return domains.findIndex((domain) => domain.name === name)
  }

  const appendDomain = () => {
    // console.log(`appending domain, now ${fields.length} domains`)
    append(blankDomain)
  }

  const removeDomain = (index) => () => remove(index)

  const addDomainCerts = (index) => (files) => {
    const certs = getValues(`domains[${index}].certs`)

    for (const file of files) {
      certs.push(file)
      setValue(`domains[${index}].certs`, certs)
      trigger(`domains[${index}].name`)
    }
  }

  const removeDomainCert = (index, pos) => () => {
    const certs = getValues(`domains[${index}].certs`)

    certs.splice(pos, 1)
    setValue(`domains[${index}].certs`, certs)
  }

  const clearAllDomainAlerts = () => {
    const domains = getValues(`domains`)
    domains.forEach((domain, index) => setValue(`domains[${index}].alerts`, []))
  }

  const addDomainAlert = (index, alert) => {
    const alerts = getValues(`domains[${index}].alerts`)
    alerts.push(alert)
    setValue(`domains[${index}].alerts`, alerts)
  }

  const checkDomains = () => {
    const domains = getValues(`domains`)
    domains.forEach((domain, index) => {
      if (domains[index].certs.length === 0) {
        addDomainAlert(index, {
          status: 'warning',
          children: `The domain ${domain.name} doesn't have a backup pin. We recommend you upload a certificate file to include one`,
        })
      }
    })
  }

  // handle form submission

  const { mutateAsync: postConfigRequest } = useMutation(configRequest)

  const onSubmit = async (data) => {
    if (data.domains.length === 1 && data.domains[0].name.length === 0) {
      setError('domains[0].name', {
        type: 'submit',
        message: 'Domain name required',
      })
      return
    }

    let foundBlanks = false
    const domains = data.domains.filter((domain) => {
      const isBlank = domain.name.length === 0 && domain.certs.length === 0
      if (isBlank) foundBlanks = true
      return !isBlank
    })

    if (domains.length === 0) {
      setValue('domains', [blankDomain])
      setError('domains[0].name', {
        type: 'submit',
        message: 'Domain name required',
      })
      return
    }

    if (foundBlanks) {
      setValue('domains', domains)
    }

    // @todo if any duplicate domains, mark errors & return

    const readFile = async (file) => {
      let result = await new Promise((resolve) => {
        let fileReader = new FileReader()
        fileReader.onload = (e) => resolve(fileReader.result)
        fileReader.onerror = (error) => console.error(error)
        fileReader.readAsBinaryString(file)
      })

      return result
    }

    // @todo improve backup file checks for proper formats and limit such as
    // DOMAINS_LIMIT, CERTIFICATE_FILE_LIMIT, and maybe a max filesize limit?

    const domain_values = data.domains.map(({name})=>name).join()
    setCookie("domain-values", domain_values)

    const mapDomains = async (domains) => {
      const configBody = []

      let id = 0
      for (const domain of domains) {
        const b64Certs = {}
        for (const cert of domain.certs) {
          const content = await readFile(cert)
          b64Certs[cert.name] = btoa(content)
        }

        configBody.push({
          id: id++,
          domain: domain.name,
          includeSubDomains: domain.subs,
          addLeafPin: domain.leaf,
          addRootPin: domain.root,
          certFilesB64: b64Certs,
        })
      }

      return configBody
    }

    const configBody = await mapDomains(domains)

    console.log(`request: ${JSON.stringify(configBody, null, 2)}`)

    clearAllDomainAlerts()

    const response = await postConfigRequest(configBody)

    if (!response.isOK) {
      errorDialog.setOpen()
      return
    }

    checkDomains()

    const results = response.results
    const hasErrors = Object.keys(results.errors).length > 0
    if (hasErrors) {
      Object.keys(results.errors).forEach((name) => {
        const index = findDomain(name)
        results.errors[name].forEach((msg) => {
          addDomainAlert(index, {
            status: 'error',
            children: `${msg.charAt(0).toUpperCase()}${msg.slice(1)}`,
          })
        })
      })
    }

    setResults(response.results)

    if (!hasErrors) setResultsTab()

    console.log(`response: ${JSON.stringify(response, null, 2)}`)
  }

  // define ui helper components

  const CertChip = ({ className, onDismiss, children }) => {
    return (
      <div
        className={classNames(
          'inline-flex items-center px-1 py-0 border border-primary-500 rounded-md text-white bg-primary-500 text-sm',
          className
        )}
      >
        {children}
        {onDismiss && (
          <button
            type="button"
            className={classNames(
              'inline-flex items-center justify-center ml-1 w-4 h-4 text-white rounded-full hover:bg-primary-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-300',
              className
            )}
            onClick={onDismiss}
          >
            <span className="sr-only">Remove cert file</span>
            <XIcon className="w-3 h-3" aria-hidden="true" />
          </button>
        )}
      </div>
    )
  }

  const CertsInput = ({ control, index }) => {
    watch({
      name: `domains[${index}].certs`,
      defaultValue: [],
    })

    const certs = getValues(`domains[${index}].certs`)

    return (
      <>
        <input type="hidden" {...register(`domains[${index}].certs`)} />
        <div className="flex flex-wrap flex-none gap-x-2 gap-y-1">
          {certs.map((file, pos) => (
            <CertChip key={pos} onDismiss={removeDomainCert(index, pos)}>
              {file.name}
            </CertChip>
          ))}
          <div>
            <label
              className="px-1 py-0 m-0 text-sm leading-0 font-body button button-secondary button-outline"
              htmlFor={`add-certs-${index}`}
            >
              Add Files...
            </label>
            <input
              id={`add-certs-${index}`}
              className="absolute opacity-0 w-[1px] h-[1px] -z-1"
              type="file"
              name="noName"
              accept=".crt,.cer,.pem,.der"
              multiple
              onChange={(e) => {
                const files = e.target.files
                addDomainCerts(index)(files)
              }}
            />
          </div>
        </div>
      </>
    )
  }

  const AlertsDisplay = ({ control, index }) => {
    useWatch({
      control: control,
      name: `domains[${index}].alerts`,
      defaultValue: [],
    })

    const alerts = getValues(`domains[${index}].alerts`)

    console.log(`alerts layout: ${JSON.stringify(alerts, null, 2)}`)

    return (
      <>
        <input type="hidden" {...register(`domains[${index}].alerts`)} />
        <div className="">
          {alerts.map((alert, i) => (
            <Alert key={i} className="" {...alert} />
          ))}
        </div>
      </>
    )
  }

  const DomainRemoveButton = ({ className, onDismiss }) => (
    <button
      type="button"
      className={classNames(
        'inline-flex items-center justify-center ml-1 text-failure-500 hover:text-white rounded-full hover:bg-failure-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-failure-300',
        className
      )}
      onClick={onDismiss}
    >
      <span className="sr-only">Remove domain</span>
      <XIcon className="w-6 h-6" aria-hidden="true" />
    </button>
  )

  // finally, define the panel contents

  return (
    <Tab.Panel>
      <div className="w-full mb-4 bg-white">
        <div className="">
          <p className="text-xs">
            Use this tab to add all of the domains you wish to certificate pin
            in your mobile app. You can decide what pins are added, obtaining
            pins from live endpoints or from certificate files. The pinning
            itself will be performed by the platform, see{' '}
            <Link href={refs.androidNetworkSecurity}>Android</Link> or{' '}
            <Link href={refs.iosNSAppTransportSecurity}>iOS</Link> official
            documentation.
          </p>
          <Alert className="p-2 my-2" status="success">
            Please visit the Android and iOS tabs for the certificate pinning
            configuration to use in your mobile app.
          </Alert>
        </div>

        <div className="grid items-center grid-cols-8 gap-1 py-2 mt-4 text-sm text-white bg-gray-800 justify-items-center">
          <div className="col-span-2 leading-tight text-center break-words">
            Domain
            <DomainInfo />
          </div>
          <div className="col-span-1 leading-tight text-center break-words">
            Pin Subdomains
            <PinSubdomainsInfo />
          </div>
          <div className="col-span-1 leading-tight text-center break-words">
            Pin Leaf Certificate
            <PinLeafCertificateInfo />
          </div>
          <div className="col-span-1 leading-tight text-center break-words">
            Pin Root Certificate
            <PinRootCertificateInfo />
          </div>
          <div className="col-span-2 leading-tight text-center break-words">
            Certificate File(s) (recommended)
            <CertificateFilesInfo />
          </div>
          <div className="col-span-1 leading-tight text-center">Actions</div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            {fields.map((field, index) => (
              <div key={field.id}>
                <div className="grid items-center grid-cols-8 gap-2 pt-2 mt-4 text-sm text-gray-700 bg-white justify-items-center">
                  <div className="w-full col-span-2">
                    <input
                      type="text"
                      className={classNames(
                        'block w-full',
                        {
                          'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500':
                            !errors?.domains?.[index]?.name,
                        },
                        {
                          'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md':
                            !!errors?.domains?.[index]?.name,
                        }
                      )}
                      placeholder="api.domain.com"
                      {...register(`domains[${index}].name`, {
                        validate: (name) =>
                          name.length > 0
                            ? isValidDomain(name) || 'Invalid domain name'
                            : getValues(`domains[${index}].certs`).length ===
                                0 || 'Domain is required',
                      })}
                    />
                  </div>
                  <div className="col-span-1">
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-gray-500 rounded text-primary-500 focus:ring-primary-500 focus:border-primary-500"
                      {...register(`domains[${index}].subs`)}
                    />
                  </div>
                  <div className="col-span-1">
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-gray-500 rounded text-primary-500 focus:ring-primary-500 focus:border-primary-500"
                      {...register(`domains[${index}].leaf`, {
                        validate: (v) =>
                          v ||
                          getValues(`domains[${index}].root`) ||
                          getValues(`domains[${index}].certs`)?.length > 0 ||
                          'Requires pinning leaf or root certs, or adding certificate files',
                      })}
                    />
                  </div>
                  <div className="col-span-1">
                    <input
                      type="checkbox"
                      className="w-4 h-4 border-gray-500 rounded text-primary-500 focus:ring-primary-500 focus:border-primary-500"
                      {...register(`domains[${index}].root`, {
                        validate: (v) =>
                          v ||
                          getValues(`domains[${index}].leaf`) ||
                          getValues(`domains[${index}].certs`)?.length > 0 ||
                          'Requires pinning leaf or root certs, or adding certificate files',
                      })}
                    />
                  </div>
                  <div className="col-span-2">
                    <CertsInput control={control} field={field} index={index} />
                  </div>
                  <div className="col-span-1">
                    {(index > 0 || fields.length > 1) && (
                      <DomainRemoveButton onDismiss={removeDomain(index)} />
                    )}
                  </div>
                </div>
                <div className="grid items-center grid-cols-8 gap-2 pb-2 text-sm text-gray-700 bg-white justify-items-center">
                  <div className="w-full col-span-2">
                    {errors?.domains?.[index]?.name && (
                      <p className="mt-2 mb-0 text-sm text-red-600">
                        {errors?.domains?.[index]?.name?.message}
                      </p>
                    )}
                  </div>
                  <div className="col-span-1"></div>
                  <div className="w-full col-span-4 text-center">
                    {errors?.domains?.[index]?.leaf ? (
                      <p className="mt-2 mb-0 text-sm text-red-600">
                        {errors?.domains?.[index]?.leaf?.message}
                      </p>
                    ) : errors?.domains?.[index]?.root ? (
                      <p className="mt-2 mb-0 text-sm text-red-600">
                        {errors?.domains?.[index]?.root?.message}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-span-1"></div>
                </div>
                <AlertsDisplay control={control} field={field} index={index} />
              </div>
            ))}
          </div>
          <div className="flex gap-2 pt-4 pb-2">
            <Button
              type="button"
              className="button-secondary bg-[#86b146]"
              onClick={appendDomain}
            >
              Add Domain
            </Button>
            <Button
              type="submit"
              className="button-secondary bg-[#86b146]"
              loading={isSubmitting}
            >
              Submit
            </Button>
            <Button
              type="button"
              className="button-failure"
              onClick={resetDialog.setOpen}
            >
              Reset
            </Button>
          </div>
        </form>
        <ResetDialog control={resetDialog} onReset={onReset} />
        <ErrorDialog control={errorDialog} />
      </div>
    </Tab.Panel>
  )
}
