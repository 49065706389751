import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { canonicalHref } from './href-utils'

// an unstyled, location-unnaware anchor component

const AnchorSubmenu = ({ className, href, blank, children, addSpace, ...rest }) => {
  const { cref, internal } = canonicalHref(href)
  blank =
    blank !== undefined
      ? blank
      : !(internal || href.startsWith('https://blog.approov.io'))
  const target = blank ? { target: '_blank', rel: 'noopener norefferer' } : {}
  const childrenWords = children.split('\n')

  if (internal && !blank) {
    return (
      <GatsbyLink to={cref} className={className} {...rest}>
        {
          <>
            {
              addSpace
              ?
              <p className="text-white">_</p>
              :
              <></>
            }
            {
              childrenWords.map((word) => {
                return (
                  <p key={word}>{word}</p>
                )
              })
            }
            {
              addSpace
              ?
              <p className="text-white">_</p>
              :
              <></>
            }          
          </>
        }
      </GatsbyLink>
    )
  } else {
    return (
      <a href={cref} className={className} {...target} {...rest}>
        {
          <>
            {
              addSpace
              ?
              <p className="text-white">_</p>
              :
              <></>
            }
            {
              childrenWords.map((word) => {
                return (
                  <p key={word}>{word}</p>
                )
              })
            }
            {
              addSpace
              ?
              <p className="text-white">_</p>
              :
              <></>
            }          
          </>
        }
      </a>
    )
  }
}

export { AnchorSubmenu }
