import {
  ChartIcon,
  DeviceCheckIcon,
  DnaIcon,
  EyeSlashIcon,
  UserCheckIcon,
} from '@components/core'

const surfacesData = [
  {
    title: 'App Integrity',
    text: 'Attest your apps to ensure only truly authentic apps are authorized to make API calls',
    Icon: DnaIcon,
    IconColour: 'text-[#8cc63f] border-[#8cc63f]',
    PanelColour: 'bg-[#8cc63f] border-[#8cc63f]',
  },
  {
    title: 'Device Integrity',
    text: 'Adopt comprehensive fine-grained device integrity checks enforced outside of the untrusted device environment',
    Icon: DeviceCheckIcon,
    IconColour: 'text-[#47841d] border-[#47841d]',
    PanelColour: 'bg-[#47841d] border-[#47841d]',
  },
  {
    title: 'Channel Integrity',
    text: 'Employ securely certificate-pinned TLS with dynamic pin update',
    Icon: EyeSlashIcon,
    IconColour: 'text-[#1c7380] border-[#1c7380]',
    PanelColour: 'bg-[#1c7380] border-[#1c7380]',
  },
  {
    title: 'Credential Integrity',
    text: 'Require frequent app authentication for strong trusted user and API authorization',
    Icon: UserCheckIcon,
    IconColour: 'text-[#004e87] border-[#004e87]',
    PanelColour: 'bg-[#004e87] border-[#004e87]',
  },
  {
    title: 'Service Integrity',
    text: 'Shield your APIs from exploitation of vulnerabilities with over-the-air updates for instant reaction to threat intelligence',
    Icon: ChartIcon,
    IconColour: 'text-[#0a366a] border-[#0a366a]',
    PanelColour: 'bg-[#0a366a] border-[#0a366a]',
  },
]

export { surfacesData }
