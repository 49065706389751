import React from 'react'
import classNames from 'classnames'

const Button = ({ className, loading, disabled, children, ...rest }) => {
  const cn = classNames(
    'button',
    className,
    { 'button-loading': loading },
    { 'button-disabled': disabled }
  )
  const tabindex = loading || disabled ? '-1' : '0'

  return (
    <button className={cn} tabIndex={tabindex} {...rest}>
      {children}
    </button>
  )
}

export { Button }
