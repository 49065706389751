import React, { useEffect, useRef, useState } from 'react'
import { Tab } from '@headlessui/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Alert, Button, Link } from '@components/core'
import { refs, ConfigDisclaimer } from './user-info'
import { OfferContent } from '../offer/offer-content'
import {Highlighter} from "../../core/highlighter";

const noConfig =
  'Submit the configuration without any errors to see the Android pinning configuration...'

export const AndroidPanel = ({ controls, shared }) => {
  const { results, setResultsTab, toPayload, fromPayload } = controls

  // set config if no errors
  const config =
    results?.errors &&
    !Object.keys(results.errors).length &&
    results.androidConfig

  // clear copied state after 5 seconds
  const [copied, setCopied] = useState(false)
  const timerRef = useRef(null)
  const onCopy = () => {
    setCopied(true)
    timerRef.current = setTimeout(() => setCopied(false), 5000)
  }
  useEffect(() => {
    if (!config) setCopied(false)
    return () => clearTimeout(timerRef.current)
  }, [config])

  // setup dismissal functions
  const onDismiss = () => {
    shared.setShow('retried')
    setResultsTab()
  }
  const isDismissed = () => shared.show === 'retried'

  return (
    <Tab.Panel>
      <div className="w-full mb-4 bg-gray-200">
        <div className="flex flex-col p-4 pb-2">
          <h3 className="mt-0 text-xl font-bold text-gray-700">
            Certificate Pinning on Android
          </h3>

          <OfferContent
            offer="static_pinning"
            shared={shared}
            onDismiss={onDismiss}
            isDismissed={isDismissed}
            toPayload={toPayload}
            fromPayload={fromPayload}
          >
            <Alert className="p-2 my-2" status="success">
              Please visit the <span className="font-bold">FAQ</span> tab to
              learn more about certificate pinning and its trade-offs and
              pitfalls.
            </Alert>

            <div className="mt-2">
              <h4 className="mt-0 -mb-2 text-xl text-gray-700 text-bold">
                Your Network Security Config File
              </h4>

              <div className="relative bg-white">
                <Highlighter
                  code={config ? config : noConfig}
                  language={config ? 'markup' : 'none'}
                />
                {config && (
                  <CopyToClipboard onCopy={onCopy} text={config}>
                    <Button className="absolute top-0 right-0 mt-1 mr-1 button-secondary button-xs">
                      {copied ? 'copied' : 'copy'}
                    </Button>
                  </CopyToClipboard>
                )}
              </div>

              {config && (
                <>
                  <Alert
                    className="p-2 mt-4"
                    status="error"
                    title="Certificate Expiration Date"
                  >
                    If the certificate renewal always retains the same public
                    key then you can safely remove the <code>expiration</code>{' '}
                    attribute from the <code>pin-set</code>.
                  </Alert>

                  {/* <DisplayAllDomainsWarnings tab="android" /> */}

                  <ConfigDisclaimer className="mt-2" />
                </>
              )}

              <p className="mb-0">
                You can learn more about the{' '}
                <code>network_security_config.xml</code> in the Android official{' '}
                <Link href={refs.androidCertPinning}>documentation</Link>.
              </p>
            </div>

            <div>
              <h4 className="my-4 text-xl text-gray-700 text-bold">
                How to Use
              </h4>
              <div className="px-4 bg-white">
                <p className="py-2">
                  Create the file{' '}
                  <code>./main/res/xml/network_security_config.xml</code> in
                  your project.
                </p>
                <p className="pb-2">
                  Copy the above certificate pinning xml configuration and paste
                  it into the file.
                </p>
                <p className="pb-2">
                  Open your <code>AndroidManifest.xml</code> file and add the
                  following code snippet{' '}
                  <code>
                    android:networkSecurityConfig="@xml/network_security_config"
                  </code>{' '}
                  inside the <code>application</code> tag.
                </p>
                <p className="pb-2">
                  Rebuild your mobile app and double check you can still make
                  requests to the APIs.
                </p>
              </div>
            </div>
            <div className="mt-4">
              <h4 className="mt-0 mb-4 text-xl text-gray-700 text-bold">
                Hands on Example
              </h4>
              <div className="px-4 bg-white">
                <p className="py-2">
                  Visit the Github repo for the{' '}
                  <Link href={refs.androidExample}>Pin Test App</Link> to see an
                  example of how to use the certificate pinning configuration.
                </p>
                <p className="pb-2">
                  Follow the step by step instructions in the repo to learn how
                  certificate pinning is implemented in a mobile app.
                </p>
              </div>
            </div>
          </OfferContent>
        </div>
      </div>
    </Tab.Panel>
  )
}
