import React from 'react'
import classNames from 'classnames'
import { graphql, useStaticQuery } from 'gatsby'
import { FaqSection } from './faq-section'

const FaqPanel = ({ className, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { frontmatter: { contentKey: { eq: "faq" } } }) {
          nodes {
            frontmatter {
              faq {
                items {
                  answerMdx
                  questionMdx
                }
                section
              }
            }
          }
        }
      }
    `
  )

  const faq = data.allMdx.nodes[0].frontmatter.faq

  return (
    <div className={classNames('', className)} {...rest}>
      <div className="flex flex-col items-center w-full gap-y-4">
        {faq.map(({ section, items }) => (
          <FaqSection key={section} section={section} items={items} />
        ))}
      </div>
    </div>
  )
}

export { FaqPanel }
