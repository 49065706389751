import React from 'react'
import classNames from 'classnames'

const PillarCard = ({ className, title, doit, dont, Icon, ...rest }) => {
  return (
    <div
      className={classNames('flex flex-col gap-0 mt-0', className)}
      {...rest}
    >
      <div className="flex items-center justify-center w-full h-8 gap-2 border-2 bg-primary-500 border-primary-500 rounded-t-xl">
        <Icon className="inline-block w-auto h-6 pr-2 text-white md:hidden" />
        <h3 className="m-0 text-lg text-center text-white sm:text-lg md:text-lg lg:text-lg xl:text-lg">
          {title}
        </h3>
      </div>

      <div className="flex flex-col w-full px-2 pt-2 pb-4 border-2 border-primary-500 rounded-b-xl grow">
        <div className="items-center justify-center hidden pb-4 md:flex">
          <Icon className="w-12 h-12 text-primary-500" />
        </div>
        <div>
          <p className="m-0 mt-2 text-sm leading-tight">
            <span className="font-bold text-failure-500">Don't:</span> {dont}
          </p>
          <p className="m-0 mt-3 text-sm leading-tight">
            <span className="font-bold text-success-500">Do:</span> {doit}
          </p>
        </div>
      </div>
    </div>
  )
}

export { PillarCard }
