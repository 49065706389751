import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {AppCard} from "../../core/app-card";


const GoldStandardPanel = ({ className, ...rest }) => {
  return (
    <>
      <div className={classNames('hidden lg:grid w-full grid-cols-[min(350px)_minmax(160px,_1fr)_minmax(160px,_1fr)_minmax(160px,_1fr)] grid-rows-[1fr] gap-x-[2rem] gap-y-[0.6rem]', className)} {...rest}>
        <h2 className="mt-0 lg:text-left text-center text-[#1b3d57] font-bold text-[1.6rem] md:text-[1.6rem] sm:text-[1.6rem] lg:text-[2.2rem] leading-[1.1]" style={{lineHeight: 1.1}}>
          Regain Control of Your Mobile App Security
        </h2>
        <Link className='flex justify-center' href="https://approov.grafana.net/public-dashboards/d2febf9a256e4f49b656b47ffe9ef588?orgId=1&refresh=5m">
          <StaticImage
            objectFit='contain'
            className="h-32"
            src="./app-attestation.webp"
            alt=""
          />
        </Link>
        <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/runtime-secrets/">
          <StaticImage
            objectFit='contain'
            className="h-32"
            src="./keys-in-cloud.png"
            alt=""
          />
        </Link>
        <Link className='flex justify-center' href='https://approov.io/mobile-app-security/rasp/'>
          <StaticImage
            objectFit='contain'
            className="h-32"
            src="./self-protection.png"
            alt=""
          />
        </Link>
        <div className='h-auto'></div>
        <div className='flex flex-col justify-center items-center'>
          <h3 className='m-0 text-center text-[#1b3d57] font-bold text-[1.15rem] leading-[1.2rem] whitespace-pre-wrap h-auto'>
            Know your app is safe, always
          </h3>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <h3 className='m-0 text-center text-[#1b3d57] font-bold text-[1.15rem] leading-[1.2rem] whitespace-pre-wrap h-auto'>
            Keys in-the-Cloud
          </h3>
        </div>
        <div className='flex flex-col justify-center items-center'>
          <h3 className='m-0 text-center text-[#1b3d57] font-bold text-[1.15rem] leading-[1.2rem] whitespace-pre-wrap h-auto'>
            The Easy Way to Secure Your Mobile Apps
          </h3>
        </div>
        <p className="lg:text-left text-center m-0 text-[#717272] lg:text-[1.3rem] text-[1rem] leading-[26px]">
          Only Approov provides <strong className='text-[#555555]'>dynamic</strong> <Link href='https://approov.io/mobile-app-security/rasp'>Runtime Application Self Protection (RASP)</Link> that verifies trust and mitigates evolving threats. Go beyond traditional passive static approaches. Approov continuously secures the device, the app, the APIs and the channel it uses all the way to the Cloud!
        </p>
        <p className="m-0 mt-2 md:mt-0 text-[#717272] text-center text-[1rem] md:text-[17px] whitespace-pre-wrap leading-[1.5]">
          Approov provides <Link href='https://approov.grafana.net/public-dashboards/d2febf9a256e4f49b656b47ffe9ef588?orgId=1&refresh=5m'>real-time threat analytics</Link> that give you <strong className='text-[#555555]'>visibility</strong> into the security of your mobile apps. This means that you can see how your apps are being attacked, and you can take action to protect against future attacks by understanding the threat environment.
        </p>
        <p className="m-0 mt-2 md:mt-0 text-[#717272] text-center text-[1rem] md:text-[17px] whitespace-pre-wrap leading-[1.5]">
          Approov <strong className='text-[#555555]'>secures</strong> your app's secrets and API <Link href='https://approov.io/mobile-app-security/rasp/runtime-secrets/'>keys in-the-cloud</Link>, where they can be easily managed and rotated as required. Secrets are only delivered just-in-time to your fully attested apps at runtime.
        </p>
        <p className="m-0 mt-2 md:mt-0 text-[#717272] text-center text-[1rem] md:text-[17px] whitespace-pre-wrap leading-[1.5]">
          Approov is <Link href='https://approov.io/mobile-app-security/rasp/#rsap_mobile_app_integration'>easy-to-use</Link> and can be integrated with CI/CD pipelines. Approov provides the only comprehensive attestation solution for mobile apps and their APIs—unified across iOS, Android and HarmonyOS.
        </p>
      </div>

      <div className={classNames('flex lg:hidden w-full flex-wrap justify-center items-stretch gap-y-3 gap-x-[2%] md:gap-x-[2.33%] lg:gap-x-[2%]', className)} {...rest}>
        <div className='w-[98%] sm:w-[98%] md:w-[98%] lg:w-[38%] flex flex-col justify-center'>
          <h2 className="mt-0 lg:mt-[49px] mb-[17px] lg:text-left text-center text-[#1b3d57] font-bold text-[2.2rem] md:text-[2.2rem] sm:text-[2.2rem]" style={{lineHeight: 1.1}}
          >
            Regain Control of Your Mobile App Security
          </h2>
          <p className="lg:text-left text-center m-0 text-[#717272] lg:text-[1.3rem] text-[1rem] leading-[26px]"
            // data-sal="slide-up"
            // data-sal-duration="1000"
            // data-sal-delay="400"
            // data-sal-easing="ease"
          >
            Only Approov provides dynamic <Link to="https://approov.io/mobile-app-security/rasp/">runtime mobile protection (RASP)</Link> that verifies trust and mitigates evolving threats. Go beyond traditional passive static approaches. Approov continuously secures the device, the app, the APIs and the channel it uses all the way to the Cloud!
          </p>
        </div>
        <AppCard
          cardImage = {
            <Link className='flex justify-center' href="https://approov.grafana.net/public-dashboards/d2febf9a256e4f49b656b47ffe9ef588?orgId=1&refresh=5m"><StaticImage
              objectFit='contain'
              className="h-32"
              src="./app-attestation.webp"
              alt=""
            /></Link>
          }
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5 lg:mt-0"
          title={`Know your app is safe, always`}
          titleClassName="leading-[1.2rem]"
          text={`Approov provides real-time threat analytics that give you visibility into the security of your mobile apps. This means that you can see how your apps are being attacked, and you can take action to protect against future attacks by understanding the threat environment.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage = {
            <div className='flex justify-center'>
              <StaticImage
                objectFit='contain'
                className="h-32"
                src="./keys-in-cloud.png"
                alt=""
              />
            </div>
          }
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5 lg:mt-0"
          title={`Keys in-the-Cloud`}
          titleClassName="leading-[1.2rem]"
          text={`Approov secures your app's secrets and API keys in-the-cloud, where they can be easily managed and rotated as required. Secrets are only delivered just-in-time to your fully attested apps at runtime.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage = {
            <Link className='flex justify-center' href="https://approov.io/mobile-app-security/rasp/runtime-secrets/"><StaticImage
              objectFit='contain'
              className="h-32"
              src="./self-protection.png"
              alt=""
            /></Link>
          }
          className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch mt-5 lg:mt-0"
          title={`The Easy Way to Secure Your Mobile Apps`}
          titleClassName="leading-[1.2rem]"
          text={`Approov is easy-to-use and can be integrated with CI/CD pipelines. Approov provides the only comprehensive attestation solution for mobile apps and their APIs—unified across iOS, Android and HarmonyOS.`}
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
      </div>
    </>
  )
}

export { GoldStandardPanel }