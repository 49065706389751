import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
}

// Fintech

export const FintechCardImage = (
  <StaticImage
    objectFit="cover"
    className="h-full"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/fintech-market.jpg`}
    alt=""
  />
)

export const FintechBG = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/fintech-banner.jpg`}
    alt={"Mobile App API Security for Fintech | Approov"}
  />
)

export const FintechRansomImage = (
  <StaticImage
    src={`${imgDir}/fintech-ransom.png`}
    alt="Fintech Ransom"
    {...imgProps}
  />
)

export const FintechTrackerImage = (
  <StaticImage
    src={`${imgDir}/fintech-tracker.jpg`}
    alt="Fintech Tracker"
    {...imgProps}
  />
)

// mHealth

export const MhealthCardImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mhealth-market.jpg`}
    alt=""
  />
)

export const MhealthBG = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mhealth-banner.jpg`}
    alt=""
  />
)

export const MhealthInfographicImage = (
  <StaticImage
    src={`${imgDir}/mhealth-infographic.png`}
    alt="Mhealth Infographic"
    {...imgProps}
  />
)

export const MhealthPhysicianImage = (
  <StaticImage
    src={`${imgDir}/mhealth-physician.png`}
    alt="Mhealth Physician"
    {...imgProps}
  />
)

// Mobility

export const MobilityCardImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mobility-market.jpg`}
    alt=""
  />
)

export const MobilityBG = (
  <StaticImage
    objectFit="cover"
    objectPosition="center center"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mobility-banner.jpg`}
    alt=""
  />
)

export const SixtShareImage = (
  <StaticImage
    src={`${imgDir}/sixt-share.jpg`}
    alt="Sixt Care Share"
    {...imgProps}
  />
)

// Retail

export const RetailCardImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/retail-market.jpg`}
    alt=""
  />
)

export const RetailBG = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/retail-banner.jpg`}
    alt=""
  />
)

export const ScoffableDeliveryImage = (
  <StaticImage
    src={`${imgDir}/scoffable-delivery.jpg`}
    alt="Scoffable Delivery"
    {...imgProps}
  />
)

export const AbandonedCartsImage = (
  <StaticImage
    src={`${imgDir}/abandoned-carts.jpg`}
    alt="Abandoned Carts"
    {...imgProps}
  />
)

export const AutomotiveCardImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/auto-market.jpg`}
    alt=""
  />
)

// Other

export const OtherCardImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/other-markets.png`}
    alt=""
  />
)

export const OtherMarketsBG = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/other-banner.png`}
    alt=""
  />
)

export const IdyllicWinnerImage = (
  <StaticImage
    src={`${imgDir}/idyllic-winner.png`}
    alt="Idyllic Winner"
    {...imgProps}
  />
)

export const NimsesNetworkImage = (
  <StaticImage
    src={`${imgDir}/nimses-network.jpg`}
    alt="Idyllic Winner"
    {...imgProps}
  />
)
