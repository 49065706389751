import React from "react";
import classNames from "classnames";

const minBannerHeight = "min-h-[327px]";
const maxBannerHeight = "max-h-[474px]";
const bgColor = "bg-[#122331]";
const textColor = "text-[#acafb3]";

export const CommonBanner = ({ title, subtitle, ...rest }) => (
  <div
    className={classNames(
      "flex flex-wrap justify-center content-center w-full  h-[48vw] md:min-h-[474px] p-[20px]",
      bgColor,
      minBannerHeight,
      maxBannerHeight
    )}
  >
    <div className="h-max w-max">
      <h1
        className={classNames(
          "text-[35px] md:text-[58px] font-black leading-[58px] self-center text-center tracking-tight mb-[0px]",
          textColor
        )}
      >
        {title}
      </h1>
      {subtitle ? (
        <h3
          className={classNames(
            "text-[22px] md:text-[35px] font-black leading-[35px] self-center text-center tracking-tight mt-[10px]",
            textColor
          )}
        >
          {subtitle}
        </h3>
      ) : (
        <></>
      )}
      <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-[50%] md:w-[75%] m-auto mt-[28px]" />
    </div>
  </div>
);
