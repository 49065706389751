import React from 'react'
import classNames from 'classnames'
import { Link } from '@components/core'

export const MarketCard = ({
  className,
  title,
  description,
  href,
  Image,
  ...rest
}) => {
  return (
    <div
      className={classNames('relative', className)}
      {...rest}
    >
      <div className="w-full h-full">{Image}</div>
      <p className="px-2 py-2 m-0 leading-[1.45rem] absolute top-[0.5rem] left-[1.0625rem] w-[17rem] text-[1.05rem] text-left">
        {description}{' '}<br/>
        <Link className="font-bold text-[#b91684] hover:text-[#20629f]" href={href}>
          Learn More
        </Link>
      </p>
    </div>
  )
}
