import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

const ApproovLogo2 = (props) => (
  <StaticImage
    objectFit="cover"
    src="./approov-logo2.png"
    alt=""
    placeholder="blurred"
    className={props.className}
    style={{height: 120, width: 200, ...props.style}}
  />
)

export { ApproovLogo2 }
