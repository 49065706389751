import React from 'react'
import { LinkCard } from './link-card'
// import { OfferCard } from './offer-card'
import { VideoCard } from './video-card'

const Cards = {
  link: LinkCard,
  // offer: OfferCard,
  video: VideoCard,
}

export const ResourceCard = ({
  className,
  resource,
  disabled = false,
  ...rest
}) => {
  const Card = Cards[resource.type]

  return (
    <Card
      className={className}
      resource={resource}
      disabled={disabled}
      {...rest}
    />
  )
}
