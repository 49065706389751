import {
  FintechCardImage,
  MhealthCardImage,
  MobilityCardImage,
  RetailCardImage,
  AutomotiveCardImage
} from './images'

export const marketCardData = [
  {
    title: 'Fintech',
    description:
      'Mobile has transformed how consumers do banking and make financial transactions. Explosive growth requires fresh, ongoing and easily deployed security.',
    href: '/market/fintech',
    Image: FintechCardImage,
  },
  {
    title: 'Healthcare',
    description:
      'Mobile healthcare app use has grown dramatically during the pandemic, and protecting personal healthcare data is now more important than ever.',
    href: '/market/mhealth',
    Image: MhealthCardImage,
  },
  {
    title: 'Mobility',
    description:
      'The way we get around is changing fast. As we use mobile apps more and more to engage with transportation services, protecting these interactions are essential.',
    href: '/market/mobility',
    Image: MobilityCardImage,
  },
  {
    title: 'Retail',
    description:
      'Customers expect fast and safe shopping experiences. Grow and protect revenue with frictionless mobile and API security.',
    href: '/market/retail',
    Image: RetailCardImage,
  },
  {
    title: 'Automotive',
    description:
      'Apps are a primary interaction point for many businesses today. Protect all data and revenue passing between your mobile users and backend services.',
    href: '/market/other',
    Image: AutomotiveCardImage,
  },
]
