import React from 'react'

export const OperationalInfoIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
        <path d="M12.0312 39.5312V52.4219" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M2.83984 52.4152H51.8242" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.0312 11.1719C12.9429 11.1719 13.8173 11.534 14.4619 12.1787C15.1066 12.8234 15.4688 13.6977 15.4688 14.6094V20.625H12.4768C11.4469 20.625 10.4593 20.2159 9.73105 19.4877C9.00285 18.7595 8.59375 17.7718 8.59375 16.742V14.6094C8.59375 13.6977 8.95591 12.8234 9.60057 12.1787C10.2452 11.534 11.1196 11.1719 12.0312 11.1719Z" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.73438 25.7812V36.0938" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.4922 28.3594H18.9062V23.4896C18.9062 22.7298 18.6044 22.0012 18.0672 21.464C17.53 20.9268 16.8014 20.625 16.0417 20.625H8.02089C7.26116 20.625 6.53254 20.9268 5.99531 21.464C5.45809 22.0012 5.15627 22.7298 5.15625 23.4896V38.2422C5.15625 38.5841 5.29206 38.9119 5.53381 39.1537C5.77555 39.3954 6.10343 39.5312 6.44531 39.5312C6.78719 39.5312 7.11507 39.3954 7.35682 39.1537C7.59856 38.9119 7.73437 38.5841 7.73438 38.2422V36.0938L8.59375 52.4219H15.4688L16.3281 36.0938V30.9375H24.4922C24.8341 30.9375 25.1619 30.8017 25.4037 30.5599C25.6454 30.3182 25.7812 29.9903 25.7812 29.6484C25.7812 29.3066 25.6454 28.9787 25.4037 28.7369C25.1619 28.4952 24.8341 28.3594 24.4922 28.3594Z" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.0625 31.4648V37.6723C24.0625 38.0293 24.1328 38.3828 24.2694 38.7126C24.406 39.0424 24.6062 39.342 24.8586 39.5945C25.111 39.8469 25.4107 40.0471 25.7405 40.1837C26.0703 40.3203 26.4237 40.3906 26.7807 40.3906H41.1099C41.4669 40.3906 41.8204 40.3203 42.1502 40.1837C42.4799 40.0471 42.7796 39.8469 43.032 39.5945C43.2844 39.342 43.4846 39.0424 43.6212 38.7126C43.7578 38.3828 43.8281 38.0293 43.8281 37.6723V8.73383C43.8281 8.01292 43.5417 7.32153 43.032 6.81177C42.5222 6.30201 41.8308 6.01562 41.1099 6.01562H26.7807C26.0598 6.01563 25.3684 6.30201 24.8586 6.81177C24.3489 7.32153 24.0625 8.01292 24.0625 8.73383V28.0751" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.6562 37.8125H36.0938" stroke="#1B3D57" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.7206 10.3125H29.295C28.7783 10.3125 28.3594 10.7314 28.3594 11.2481V13.6738C28.3594 14.1905 28.7783 14.6094 29.295 14.6094H31.7206C32.2374 14.6094 32.6562 14.1905 32.6562 13.6738V11.2481C32.6562 10.7314 32.2374 10.3125 31.7206 10.3125Z" stroke="#78A65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M38.5956 10.3125H36.17C35.6533 10.3125 35.2344 10.7314 35.2344 11.2481V13.6738C35.2344 14.1905 35.6533 14.6094 36.17 14.6094H38.5956C39.1124 14.6094 39.5312 14.1905 39.5312 13.6738V11.2481C39.5312 10.7314 39.1124 10.3125 38.5956 10.3125Z" stroke="#78A65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M31.7206 18.0469H29.295C28.7783 18.0469 28.3594 18.4658 28.3594 18.9825V21.4081C28.3594 21.9249 28.7783 22.3438 29.295 22.3438H31.7206C32.2374 22.3438 32.6562 21.9249 32.6562 21.4081V18.9825C32.6562 18.4658 32.2374 18.0469 31.7206 18.0469Z" stroke="#78A65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M38.5956 18.0469H36.17C35.6533 18.0469 35.2344 18.4658 35.2344 18.9825V21.4081C35.2344 21.9249 35.6533 22.3438 36.17 22.3438H38.5956C39.1124 22.3438 39.5312 21.9249 39.5312 21.4081V18.9825C39.5312 18.4658 39.1124 18.0469 38.5956 18.0469Z" stroke="#78A65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M24.0625 35.2344H43.8281" stroke="#1B3D57" stroke-width="2" stroke-linejoin="round"/>
        <path d="M22.3438 30.9375V28.3594" stroke="#1B3D57" stroke-width="2" stroke-linejoin="round"/>
        <path d="M16.3281 36.0938H5.15625" stroke="#1B3D57" stroke-width="2" stroke-linejoin="round"/>
    </svg>
)