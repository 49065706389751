import React from 'react'

const Headquarter = ({headquarter}) => {
  return (
    <div className='text-center md:text-left mb-[19px]'>
      <h6 className="text-white font-bold text-[16px] leading-[29px]">
        {headquarter.name}
      </h6>
      {
        headquarter.items.map((item) => 
            item.name?<p key={item} className=" leading-[23px]"><span className='font-bold'>{item['name']}:&nbsp;</span>{item['item']}</p>:
                      <p key={item} className=" leading-[23px]">{item}</p>
        )
      }
    </div>
  )
}

export {Headquarter}