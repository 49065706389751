import React from 'react'
import {useEffect} from "react";
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const Seo = ({ name, onChangeTag }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            site {
              url
            }
          }
        }
        allSeoYaml {
          nodes {
            name
            title
            description
            keywords
            robots
            image
            tag
            schema {
              at_context
              at_type
              name
              alternateName
              url
              logo
              sameAs
            }
          }
        }
      }
    `
  )

  const pageName = name ? (name.endsWith('/') ? name : name + '/') : ''

  const site = data.allSeoYaml.nodes.find((p) => p.name === 'site')
  site.url = data.site.siteMetadata.site.url

  const page = data.allSeoYaml.nodes.find((p) => p.name === pageName)

  const title = page?.title || site.title
  // const titleTemplate = page?.title ? `%s | ${site.title}` : `%s`
  const description = page?.description || site.description
  const keywords = (page?.keywords || site.keywords).join(',')
  const tag = page?.tag
  const robots = page?.robots
  const image = `${site.url}${page?.image || site.image}`
  const url = `${site.url}${name}`
  const schema = page?.schema
  if (schema) {
    schema['@context'] = schema.at_context || undefined
    schema.at_context = undefined
    schema['@type'] = schema.at_type || undefined
    schema.at_type = undefined
  }

  useEffect(() => {

    const gmt_script = document.createElement('script');
    gmt_script.type = 'text/javascript';
    gmt_script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KKDP9CZ');`;
    gmt_script.async = true;
    document.head.appendChild(gmt_script);

    const gmt_noscript = document.createElement('noscript');

    gmt_noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKDP9CZ"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    gmt_noscript.async = true;
    document.body.appendChild(gmt_noscript);

    const script = document.createElement('script');
    script.src = 'https://secure.leadforensics.com/js/270328.js';
    script.async = true;
    document.body.appendChild(script);

    const hubspot_script = document.createElement('script');
    hubspot_script.type = 'text/javascript';
    hubspot_script.src = '//js.hs-scripts.com/2449407.js';
    hubspot_script.id = 'hs-script-loader';
    hubspot_script.async = true;
    document.body.appendChild(hubspot_script);

    return () => {
        document.head.removeChild(gmt_script);
        document.body.removeChild(gmt_noscript);
        document.body.removeChild(hubspot_script);
        document.body.removeChild(script);
    };
  }, [name]);

  useEffect(() => {
    if (tag) {
      onChangeTag(tag)
    }
  }, [tag])

  // console.log(`***************** pageName: ${pageName}`)

  // if (page) {
  //   console.log(`***************** page: ${JSON.stringify(page, null, 2)}`)
  // }
  // const meta = { title, description, keywords, robots, image, url, schema }
  // console.log(`***************** meta: ${JSON.stringify(meta, null, 2)}`)

  return (
    <Helmet htmlAttributes={{ lang: `en` }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      {robots && <meta name="robots" content={robots} />}
      <link rel="canonical" href={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@approov_io" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="title" property="og:title" content={title} />
      <meta
        name="description"
        property="og:description"
        content={description}
      />
      <meta name="image" property="og:image" content={image} />
      <meta name="type" property="og:type" content="website" />
      <meta name="url" property="og:url" content={url} />
      <meta name="site_name" property="og:site_name" content={site.title} />
      {/* <script type="text/javascript" src={withPrefix("https://secure.leadforensics.com/js/270328.js")}></script> */}
      {schema && (
        <script type="application/ld-json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  )
}

export { Seo }
