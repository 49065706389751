import React from 'react'
import classNames from 'classnames'

const Container = ({ className, children, ...rest }) => {
  return (
    <div className={classNames('container', className)} {...rest}>
      {children}
    </div>
  )
}

export { Container }
