import React from 'react'
import classNames from 'classnames'
import Highlight, { defaultProps } from 'prism-react-renderer'
import vsLight from 'prism-react-renderer/themes/vsLight'

export const Highlighter = ({ code, language = 'none' }) => {
  const props = { ...defaultProps, theme: vsLight }

  return (
    <Highlight {...props} language={language} code={code}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={classNames(className, 'whitespace-pre-wrap break-words')}
          style={style}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}
