import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Tab } from '@headlessui/react'
import { PanelTab } from './panel-tab'
import { ConfigPanel } from './config-panel'
import { ResultsPanel } from './results-panel'
import { AndroidPanel } from './android-panel'
import { IosPanel } from './ios-panel'
import { FaqPanel } from './faq-panel'
import { PinningAdvisory } from './user-info'
import { usePage } from '../offer/use-page'

export const StaticPinningTool = ({ className, initialTab }) => {
  // define amd initialize results

  const [results, setResults] = useState({})

  // define and initialize form

  const blankDomain = {
    name: '',
    subs: true,
    leaf: true,
    root: false,
    certs: [],
    alerts: [],
  }

  const form = useForm({
    mode: 'onSubmit',
    defaultValues: {
      domains: [blankDomain],
      referrerId: initialTab || '',
    },
  })

  // read and write from payload

  const toPayload = () => {
    return {
      domains: form.getValues('domains'),
      results: results,
    }
  }

  const fromPayload = (payload) => {
    form.setValue('domains', payload.domains)
    setResults(payload.results)
  }

  // define tab controls

  let iTab = 0
  if (initialTab) {
    const tabNames = ['config', 'results', 'android', 'ios', 'faq']
    iTab = tabNames.findIndex((name) => name === initialTab)
    if (iTab < 0) iTab = 0
  }

  const [activeTab, selectTab] = useState(iTab)

  // switch to results tab
  const setResultsTab = () => selectTab(1)

  // track tab change in case it is changed programmatically
  const handleTabChange = (index) => {
    selectTab(index)
  }

  // wrap up controls for the config view

  const controls = {
    blankDomain,
    form,
    results,
    setResults,
    setResultsTab,
    toPayload,
    fromPayload,
  }

  // protect android and ios panels

  const {
    queryParams: { cid },
  } = usePage()

  // display state

  const [show, setShow] = useState(() => (cid ? 'accept' : 'retry'))

  return (
    <div className={className}>
      <Tab.Group
        key={activeTab} // hack to allow programmatic tab selection
        defaultIndex={activeTab}
        onChange={handleTabChange}
      >
        <Tab.List
          className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow-lg sm:w-3/4 md:w-1/2"
          aria-label="Tabs"
        >
          <PanelTab name="Config" start />
          <PanelTab name="Results" />
          <PanelTab name="Android" />
          <PanelTab name="iOS" />
          <PanelTab name="FAQ" end />
        </Tab.List>

        <Tab.Panels>
          <ConfigPanel controls={controls} />
          <ResultsPanel logging={results?.logging} />
          <AndroidPanel controls={controls} shared={{ show, setShow }} />
          <IosPanel controls={controls} shared={{ show, setShow }} />
          <FaqPanel />
        </Tab.Panels>
      </Tab.Group>

      <PinningAdvisory className="mt-2" />
    </div>
  )
}
