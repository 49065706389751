import React from 'react'

export const BackendIntegrationIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
        <g clip-path="url(#clip0_280_1188)">
            <path d="M53.5615 50.3462V8.0385C53.5615 7.70003 53.3076 7.36157 52.8846 7.36157H0.423017C0.0845553 7.36157 -0.253906 7.61542 -0.253906 8.0385V35.1154C-0.253906 35.4539 -6.00815e-05 35.7923 0.423017 35.7923C0.846094 35.7923 1.09994 35.5385 1.09994 35.1154V15.4846H52.2922V49.7539H1.09994V40.1923C1.09994 39.8539 0.846094 39.5154 0.423017 39.5154C-6.00815e-05 39.5154 -0.253906 39.7693 -0.253906 40.1923V50.3462C-0.253906 50.6847 -6.00815e-05 51.0231 0.423017 51.0231H52.8846C53.223 51.0231 53.5615 50.6847 53.5615 50.3462ZM1.09994 14.2154V8.71542H18.4461V14.2154H1.09994ZM19.7153 14.2154V8.71542H52.2922V14.2154H19.7153Z" fill="#1B3D57"/>
            <path d="M49.4991 47.6385C49.8376 47.6385 50.176 47.3846 50.176 46.9615V18.1923C50.176 17.8538 49.9222 17.5154 49.4991 17.5154H3.80681C3.46834 17.5154 3.12988 17.7692 3.12988 18.1923V46.9615C3.12988 47.3 3.38373 47.6385 3.80681 47.6385H49.4991ZM4.48373 18.8692H48.9068V46.3692H4.48373V18.8692Z" fill="#1B3D57"/>
            <path d="M5.49947 12.2692C5.96679 12.2692 6.34563 11.8904 6.34563 11.4231C6.34563 10.9557 5.96679 10.5769 5.49947 10.5769C5.03216 10.5769 4.65332 10.9557 4.65332 11.4231C4.65332 11.8904 5.03216 12.2692 5.49947 12.2692Z" fill="#1B3D57"/>
            <path d="M9.72994 12.2692C10.1973 12.2692 10.5761 11.8904 10.5761 11.4231C10.5761 10.9557 10.1973 10.5769 9.72994 10.5769C9.26263 10.5769 8.88379 10.9557 8.88379 11.4231C8.88379 11.8904 9.26263 12.2692 9.72994 12.2692Z" fill="#1B3D57"/>
            <path d="M13.9614 12.2692C14.4287 12.2692 14.8075 11.8904 14.8075 11.4231C14.8075 10.9557 14.4287 10.5769 13.9614 10.5769C13.4941 10.5769 13.1152 10.9557 13.1152 11.4231C13.1152 11.8904 13.4941 12.2692 13.9614 12.2692Z" fill="#1B3D57"/>
            <path d="M48.6533 10.8308H23.2687C22.9303 10.8308 22.5918 11.0847 22.5918 11.5077C22.5918 11.9308 22.8456 12.1847 23.2687 12.1847H48.6533C48.9918 12.1847 49.3303 11.9308 49.3303 11.5077C49.3303 11.0847 48.9918 10.8308 48.6533 10.8308Z" fill="#1B3D57"/>
            <path d="M45.2692 24.3693H35.1154C34.7769 24.3693 34.4385 24.6231 34.4385 25.0462C34.4385 25.4693 34.6923 25.7231 35.1154 25.7231H45.2692C45.6077 25.7231 45.9462 25.4693 45.9462 25.0462C45.9462 24.6231 45.6077 24.3693 45.2692 24.3693Z" fill="#1B3D57"/>
            <path d="M45.2692 29.4462H35.1154C34.7769 29.4462 34.4385 29.7 34.4385 30.1231C34.4385 30.5462 34.6923 30.8 35.1154 30.8H45.2692C45.6077 30.8 45.9462 30.5462 45.9462 30.1231C45.9462 29.7 45.6077 29.4462 45.2692 29.4462Z" fill="#1B3D57"/>
            <path d="M45.2689 34.5231H26.6535C26.315 34.5231 25.9766 34.7769 25.9766 35.2C25.9766 35.6231 26.2304 35.8769 26.6535 35.8769H45.2689C45.6073 35.8769 45.9458 35.6231 45.9458 35.2C45.9458 34.7769 45.6073 34.5231 45.2689 34.5231Z" fill="#1B3D57"/>
            <path d="M45.2689 39.6H26.6535C26.315 39.6 25.9766 39.8538 25.9766 40.2769C25.9766 40.7 26.2304 40.9538 26.6535 40.9538H45.2689C45.6073 40.9538 45.9458 40.7 45.9458 40.2769C45.9458 39.8538 45.6073 39.6 45.2689 39.6Z" fill="#1B3D57"/>
            <path d="M23.0146 23.1001V23.0154C23.0146 23.0154 23.0147 22.9308 22.93 22.9308L22.8454 22.8462H22.7608L22.6762 22.7616C22.6762 22.7616 22.5916 22.7616 22.507 22.7616H22.4223H7.19157C6.85311 22.7616 6.51465 23.0154 6.51465 23.4385V42.0539C6.51465 42.1385 6.51465 42.1385 6.51465 42.2231V42.3078C6.51465 42.3078 6.51465 42.3924 6.59926 42.3924C6.68388 42.3078 6.76849 42.3078 6.76849 42.3924C6.76849 42.3924 6.85311 42.3924 6.85311 42.477C6.85311 42.477 6.93773 42.477 6.93773 42.5616C7.02234 42.5616 7.10696 42.6462 7.19157 42.6462H22.4223C22.7608 42.6462 23.0993 42.3924 23.0993 41.9693V23.3539C23.0146 23.1847 23.0146 23.1847 23.0146 23.1001ZM21.0685 23.9462L7.86849 40.1078V23.9462H21.0685ZM8.54542 41.2924L21.83 25.1308V41.2924H8.54542Z" fill="#78A65A"/>
            <path d="M0.422671 38.2462C0.507287 38.2462 0.591902 38.2462 0.676517 38.1616C0.761133 38.1616 0.845748 38.1616 0.845748 38.0769C0.930364 37.9077 1.01498 37.8231 1.01498 37.6539C1.01498 37.5693 1.01498 37.4846 0.930364 37.4C0.845748 37.3154 0.930364 37.2308 0.845748 37.2308C0.591902 36.9769 0.168825 36.9769 -0.085021 37.2308C-0.169636 37.3154 -0.169636 37.4 -0.254252 37.4C-0.254252 37.4846 -0.338867 37.5693 -0.338867 37.6539C-0.338867 37.8231 -0.254252 37.9923 -0.169636 38.0769C0.0842097 38.2462 0.25344 38.2462 0.422671 38.2462Z" fill="#1B3D57"/>
        </g>
        <defs>
            <clipPath id="clip0_280_1188">
            <rect width="55" height="55" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)