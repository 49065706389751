import React from 'react'
import classNames from 'classnames'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@components/core'
import {MDXRenderer} from "../../core/mdx";

const FaqSection = ({ className, section, items, ...rest }) => {
  return (
    <div className={classNames('w-full', className)} {...rest}>
      <h2 className="text-[24px] md:text-[35px] font-black not-prose text-center md:text-left leading-[40px] text-[#727272]">{section}</h2>

      {items.map(({ questionMdx, answerMdx }) => (
        <div key={questionMdx} className="px-4">
          <div className="mx-auto bg-white rounded-lg">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                    <div className="ml-2">
                      <MDXRenderer>{questionMdx}</MDXRenderer>
                    </div>
                    <ChevronDownIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-gray-700`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 text-gray-700 text-md">
                    <MDXRenderer>{answerMdx}</MDXRenderer>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      ))}
    </div>
  )
}

export { FaqSection }
