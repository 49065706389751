import React from 'react'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import { useLocation } from '@reach/router'

const Link = ({
  className,
  activeClassName,
  href,
  blank,
  children,
  hubspot,
  ...rest
}) => {
  href = href
    ? href.endsWith('/') && href.length > 1
      ? href.slice(0, -1)
      : href
    : '#'
  const internal = href === '#' || /^\/(?!\/)/.test(href)
  const { pathname } = useLocation()
  const current = href === '#' || href === pathname

  const cn = classNames('link', className)
  const acn = classNames('link link-current', activeClassName)
  const tabindex = current ? '-1' : '0'

  blank =
    blank !== undefined
      ? blank
      : !(internal || href.startsWith('https://blog.approov.io'))
  const target = blank ? { target: '_blank', rel: 'noopener norefferer' } : {}

  // console.log(
  //   `Link: { href:${href}, cref:${pathname} internal:${internal}, current:${current}, cn: ${cn}, acn: ${acn}, blank:${blank}, target:${target}}`
  // )

  // return internal or external link

  if (internal && !blank) {
    if(href === "/") {
      href = '';
    }   
    else if (hubspot || href.indexOf(".pdf") > 0 || href.indexOf("/for/") !== -1) {
      href= `https://approov.io${href}`
    }
    else if (!hubspot) {
      href = `${href}/`;
    }
    return (
      <GatsbyLink
        to={href}
        className={cn}
        activeClassName={acn}
        tabIndex={tabindex}
        {...rest}
      >
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a href={href} className={cn} {...target} {...rest}>
        {children}
      </a>
    )
  }
}

export { Link }
