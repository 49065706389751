import React from 'react'
import { MDXRenderer as GatsbyMDXRenderer } from 'gatsby-plugin-mdx'

const MDXRenderer = ({ className, children, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <GatsbyMDXRenderer>{children}</GatsbyMDXRenderer>
    </div>
  )
}

export { MDXRenderer }
