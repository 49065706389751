import React from 'react'
import classNames from 'classnames'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const CustomerPanel = ({ className, ...rest }) => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: { frontmatter: { contentKey: { eq: "customer" } } }
          sort: { order: ASC, fields: frontmatter___board___order }
          limit: 1000
        ) {
          nodes {
            frontmatter {
              name
              logo {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              board {
                order
              }
            }
          }
        }
      }
    `
  )

  return (
    <div className={classNames('w-full', className)} {...rest}>
      <div className="flex flex-wrap items-stretch justify-center gap-y-6 gap-x-12 lg:gap-x-16">
        {data.allMdx.nodes.map(({ frontmatter }) => {
          const name = frontmatter.name
          const logo = getImage(frontmatter.logo)
          const order = frontmatter.board.order
          return (
            order >= 0 && (
              <Link href={`/customer/${name.toLowerCase().replaceAll(" ", '')}`}>
                <div key={name} className="flex flex-col items-center w-32">
                  <GatsbyImage image={logo} alt={name} />
                </div>
              </Link>
            )
          )
        })}
      </div>
    </div>
  )
}

export { CustomerPanel }
