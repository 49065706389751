import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
  quality: 50,
}

export const ArchitectureImage = (
  <StaticImage
    src={`${imgDir}/architecture.png`}
    style={{width:'96%'}}
    alt="Approov mobile security architecture diagram"
    {...imgProps}
  />
)

export const ArchitectureImage2 = (
  <StaticImage
    src={`${imgDir}/architecture2.png`}
    style={{width:'96%'}}
    alt="Approov app attestation diagram; integration with Google Play Integrity and iOS App Attest"
    {...imgProps}
  />
)

export const BackendRuntimeSecretsImage = (
  <StaticImage
    src={`${imgDir}/backend-runtime-secrets.png`}
    alt="Backend protected with runtime secrets"
    {...imgProps}
  />
)

export const BackendOptionsImage = (
  <StaticImage
    src={`${imgDir}/backend-options.jpg`}
    alt="Approov 1st and 3rd party API key protection diagram"
    {...imgProps}
  />
)

export const BackendApiIntegrationImage = (
  <StaticImage
    src={`${imgDir}/backend-api-integration.png`}
    alt="Approov backend integration flow diagram"
    {...imgProps}
  />
)

export const BackendApiProtectionImage = (
  <StaticImage
    src={`${imgDir}/backend-api-protection.png`}
    alt="Backend protected with JWTs"
    {...imgProps}
  />
)

export const ConsultHandsetImage = (
  <StaticImage
    src={`${imgDir}/consult-handset-straight.png`}
    alt="Approov Consultation"
    {...imgProps}
  />
)

export const ConsultHandsetSlantedImage = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/consult-handset-slanted.png`}
    alt="Approov Consultation"
    {...imgProps}
  />
)

export const LiveMetricsImage = (
  <StaticImage
    src={`${imgDir}/live-metrics.png`}
    alt="Screenshot of Approov metrics"
    style={{
      width: '96%'
    }}
    {...imgProps}
  />
)

export const LiveMetrics2Image = (
  <StaticImage
    src={`${imgDir}/live-metricss.png`}
    alt="Live Threats"
    style={{
      width: '96%'
    }}
    {...imgProps}
  />
)

export const MitmAttackImage = (
  <StaticImage
    src={`${imgDir}/mitm-attack.png`}
    alt="MitM Attack"
    style={{width: '95%'}}
    {...imgProps}
  />
)

export const WebinarIcon = (
  <StaticImage
    src={`${imgDir}/webinar_icon.png`}
    alt="Webinar"
    style={{width: '60%'}}
    {...imgProps}
  />
)

export const MitmAttackGreenImage = (
  <StaticImage
    src={`${imgDir}/mitm-attack-green.jpg`}
    alt="MitM Attack"
    {...imgProps}
  />
)

export const MobileAppIntegrationImage = (
  <StaticImage
    src={`${imgDir}/mobile-app-integration.png`}
    alt="Approov mobile app integration diagram"
    {...imgProps}
  />
)

export const PinningImage = (
  <StaticImage
    src={`${imgDir}/pinning.jpg`}
    alt="Approov Dynamic Pinning"
    {...imgProps}
  />
)

export const ProductSnapshotImage = (
  <StaticImage
    src={`${imgDir}/product-snapshot.png`}
    alt="Approov Snapshot"
    {...imgProps}
  />
)

export const OtaUpdateImage = (
  <StaticImage
    src={`${imgDir}/ota-update.png`}
    alt="Approov security policy diagram; instant over-the-air updates enabled"
    style={{width: '95%'}}
    {...imgProps}
  />
)

export const PinningDiagramImage = (
  <StaticImage
    src={`${imgDir}/pinning-diagram.png`}
    alt="Certificate Pinning"
    {...imgProps}
  />
)

export const AnatomyOfAHackImage = (
  <StaticImage
    src={`${imgDir}/anatomy-of-a-hack.png`}
    alt="Approov Snapshot"
    {...imgProps}
  />
)

export const ProductBannerImage = (
  <StaticImage
    src={`${imgDir}/Layer 17.png`}
    className="w-full md:max-w-[482px] h-auto"
    alt="Mobile phone security concept; biometric identity verification using fingerprint reader"
  />
)

export const AppAttestationImage = (
  <StaticImage
    src={`${imgDir}/app-attestation.webp`}
    className="max-w-[123px] not-prose"
    alt="App Attestation"
  />
)

export const DeviceIntegrityImage = (
  <StaticImage
    src={`${imgDir}/device-integrity.png`}
    className="not-prose max-w-[123px] p-[20px]"
    alt="device"
  />
)

export const CredentialsIntegrityImage = (
  <StaticImage
    src={`${imgDir}/credentials-integrity.png`}
    className="max-w-[123px] p-[20px]"
    alt="Channel Integrity"
  />
)

export const IconsPhoneImage = (
  <StaticImage
    src={`${imgDir}/icons phone.png`}
    className="h-max-[470px]  md:h-auto h-[400px]"
    alt="Graphic of smartphone with colourful app icons"
    objectFit="contain"
  />
)

export const ChannelIntegrityImage = (
  <StaticImage
    src={`${imgDir}/channel-integrity.png`}
    className="max-w-[123px]  not-prose"
    alt="Credentials Integrity"
  />
)


export const ServiceIntegrity = (
  <StaticImage
    src={`${imgDir}/service-integrity_2.png`}
    className="max-w-[123px]"
    alt="Runtime security"
  />
)

export const EasyImage = (
  <StaticImage
    objectFit="cover"
    src={`${imgDir}/easy.png`}
    className="max-w-[123px]"
    alt="Easy"
  />
)



