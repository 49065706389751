import React from 'react'
import classNames from 'classnames'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { MarketCard } from './market-card'
import { marketCardData } from './market-data'
import './tab-styles.css'

const MarketPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames('w-full px-[1.4rem]', className)} {...rest}>
      <h2 className="leading-none text-[#1b3d57] mt-0 font-bold text-[2.715rem] text-center mb-[1.7rem]">Where Complete App & API Security is Required</h2>
      <Tabs>
        <TabList>
          <Tab>
            <p>Fintech</p>
          </Tab>
          <Tab>
            <p>Healthcare</p>
          </Tab>
          <Tab>
            <p>Mobility</p>
          </Tab>
          <Tab>
            <p>Retail</p>
          </Tab>
          <Tab>
            <p>Automotive</p>
          </Tab>
        </TabList>

        {marketCardData.map(({ title, description, href, Image }) => (
          <TabPanel>
            <div className="panel-content">
              <MarketCard
                key={title}
                className="w-[98%] md:w-full self-stretch h-full"
                title={title}
                description={description}
                href={href}
                Image={Image}
              />
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </div>
  )
}

export { MarketPanel }
