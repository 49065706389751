import React from 'react'
import { Disclosure, Tab } from '@headlessui/react'
import { Link } from '@components/core'
import { ChevronDownIcon } from '../../core/icons/chevron-down'
import { refs } from './user-info'

export const FaqPanel = () => {
  return (
    <Tab.Panel>
      <div className="w-full mb-4 bg-gray-200">
        <div className="relative w-full p-4 whitespace-pre-wrap break-words flex flex-wrap gap-x-0 sm:gap-x-[2%]">
          <h3 className="mb-4 text-xl font-bold text-gray-700 text-gray-800 sm:text-2xl">
            The Most Frequent Questions About Certificate Pinning
          </h3>

          <div className="w-full px-4 pt-4">
            <div className="w-full p-2 mx-auto bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">What Is Certificate Pinning?</div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Certificate pinning is the mechanism of associating a
                        domain name with one or more expected TLS certificates.
                        More precisely, the mechanism used here is Subject
                        Public Key Identity (SPKI) pinning where a particular
                        public key for a certificate is required. This approach
                        is the most flexible and maintainable since it allows
                        certificates to be rotated without pinning changes if
                        they are generated using the same public key.
                      </p>
                      <p>
                        Whenever the app needs to establish a connection with
                        the server hosting that domain name a TLS handshake
                        takes place in order that both parties can exchange
                        messages, to establish the encryption algorithms to use,
                        and to set the session encryption keys to be used
                        thereafter. During the TLS handshake, when the device
                        receives the server certificate chain, it only
                        establishes the connection if it trusts on one or more
                        certificates in that chain.
                      </p>
                      <p>
                        Pinning ensures that the connection has really been
                        established from the app to the official backend.
                        Pinning prevents a Man-in-the-Middle (MitM) attack or
                        proxying of traffic where encrypted data between the app
                        and the backend could be observed, or even manipulated,
                        by a 3rd party.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">What To Pin?</div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Certificate pinning is performed against any of the
                        certificates presented in the chain of trust for the
                        domain of the API server. Normally it is preferable to
                        pin against the leaf certificate, but it is also
                        possible to pin against the root certificate. Indeed it
                        is also possible to pin against an intermediate
                        certificate, although this is not supported directly by
                        this tool (but can be easily achieved with some manual
                        editing).
                      </p>
                      <p>
                        You should only pin to domains where you have permission
                        from the team responsible for the backend API. This is
                        to ensure that the pins are not changed unexpectedly,
                        rendering your app unable to connect. This means that in
                        general you should not pin to domains controlled by a
                        3rd party. If you wish to do this then a workaround is
                        to proxy the traffic as discussed in{' '}
                        <Link href={refs.reverseProxy}>this blog</Link>.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        Why Do We Need Certificate Pinning?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        While <code>https</code> gives you confidentiality,
                        integrity and authenticity in the communication channel
                        between the mobile app and the API server, certificate
                        pinning will protect these guarantees from being broken.
                      </p>
                      <p className="text-base text-bold">
                        To Further Secure Trust Based Assumptions
                      </p>
                      <p>
                        The unverified issuing of certificates by Root and
                        Intermediate Certificate Authorities (CAs) would allow
                        an attacker to intercept any TLS encrypted traffic to
                        that domain if they are able to intercept the channel,
                        or spoof DNS to do so.
                      </p>
                      <p>
                        This is possible because mobile devices come
                        pre-installed with a trust store of root certificates.
                        TLS connections will be considered trusted if there is a
                        chain of trust to one of those root CA certificates.
                        This means that if any of the CA’s get compromised or
                        issue certificates incorrectly, then trust is tainted
                        and broken, similarly to the famous cases of DigiNotar,
                        GlobalSign and Comodo. In other words, unpinned TLS
                        security is only as strong as the weakest CA that is
                        included in the device's trust store.
                      </p>
                      <p className="text-base text-bold">
                        To Further Secure Apps Against Reverse Engineering
                      </p>
                      <p>
                        An attacker is able to control both their network access
                        and the device on which they install the app. If the app
                        does not use pinning then it is relatively
                        straightforward to install an additional certificate
                        into the system trust store of the device to allow them
                        to intercept API traffic using a proxy tool. This allows
                        any app secrets (such as API keys) and the structure of
                        the API calls to be extracted from the channel and then
                        used to build a script to impersonate the app. Thus even
                        if you have gone to some effort to obfuscate or harden
                        your app code, this style of reverse engineering can
                        still be used.
                      </p>
                      <p>
                        Pinning can be used to make this reverse engineering
                        approach much more difficult and is thus an important
                        step in improving the security posture of your app.
                        Note, however, breaking pinning is still possible where
                        an attacker controls a rooted/jailbroken device as
                        discussed in "Is It Possible to Bypass Certificate
                        Pinning".
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        When To Use Certificate Pinning?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        The{' '}
                        <Link href="https://www.owasp.org/index.php/Certificate_and_Public_Key_Pinning#When_Do_You_Pin.3F" rel="nofollow">
                          OWASP page
                        </Link>{' '}
                        on certificate pinning has a good answer for this
                        question:
                      </p>
                      <blockquote>
                        You should pin anytime you want to be relatively certain
                        of the remote host's identity or when operating in a
                        hostile environment. Since one or both are almost always
                        true, you should probably pin all the time.
                      </blockquote>
                      <p>
                        So if your mobile app is dealing with Personal
                        Identifiable Information (PII) and/or other sensitive
                        data, which is pretty much true in any mobile app, then
                        you should absolutely pin. Of course, in reality you
                        must also control the API backend that you want to pin
                        so you know when the certificates might change.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        Does Certificate Pinning Prevent MitM Attacks?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Yes, and you can learn more about this in the article{' '}
                        <Link href="https://blog.approov.io/securing-https-with-certificate-pinning-on-android">
                          Securing HTTPS with Certificate Pinning on Android
                        </Link>
                        .
                      </p>
                      <blockquote>
                        ...you learned how to prevent MitM attacks with the
                        implementation of certificate pinning in an Android app
                        that makes use of a network security config file for
                        modern Android devices.
                      </blockquote>
                      <p>
                        The main proviso here is that it may be bypassed if an
                        attacker controls the end device on which the app is
                        installed. But for the case of protecting end user data
                        in transit, certificate pinning is effective at
                        providing an additional level of security for such
                        communication.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        Is It Possible To Bypass Certificate Pinning?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Yes, if you control both the network channel and the end
                        user device. You can learn more about doing it for
                        Android in the article{' '}
                        <Link href="https://blog.approov.io/bypassing-certificate-pinning">
                          Bypassing Certificate Pinning
                        </Link>
                        .In this article you will learn how to repackage a
                        mobile app in order to remove pins and make it trust
                        user installed certificates. This will allow us to
                        bypass certificate pinning. It is also possible to
                        bypass pinning using other methods, such as{' '}
                        <Link href="https://blog.approov.io/how-to-bypass-certificate-pinning-with-frida-on-an-android-app">
                          How to Bypass Certificate Pinning with Frida on an
                          Android App
                        </Link>
                        .
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        Can You Protect Against Certificate Pinning Bypassing?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Yes, and you can learn how to achieve it with{' '}
                        <Link href={refs.dynamicPinningView}>
                          Dynamic Certidicate Pinning
                        </Link>{' '}
                        in the article{' '}
                        <Link href={refs.pinningBypass}>
                          How to Protect Against Certificate Pinning Bypassing
                        </Link>
                        .
                      </p>
                      <blockquote>
                        In this article you will learn how to use the Approov
                        service to protect your API server from accepting
                        requests that come from a mobile app where certificate
                        pinning has been bypassed. This means that even though
                        the attacker has bypassed the certificate pinning, they
                        will not be able to receive successful responses from
                        the API server, thus making it impossible to perform a
                        full MitM analysis.
                      </blockquote>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        What Are The Certificate Pinning Trade-Offs?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        Certificate pinning involves statically embedding the
                        expected pins into the app itself. Changing the pins
                        requires an update of the app to be issued. With this
                        comes the risk of locking out the customers from using
                        the mobile app if the backend certificates change and no
                        longer match any of the app embedded pins. On course, on
                        the upside, pinning will generally make the mobile app
                        more secure.
                      </p>
                      <p>
                        If the server rotates the certificate, because it is
                        about to expire, was compromised, or just due to the
                        company security policy, a new app needs to be released
                        with the new and the old pins before the rotation takes
                        place. You must leave time for all app users to update,
                        and even then this cannot be guaranteed. For such cases
                        you should provide a mechanism in the app to ask them to
                        manually upgrade. Of course, where possible you should
                        try and update certificates to use the same public key
                        to avoid the need for this.
                      </p>
                      <p>
                        Certificate pinning requires coordination between
                        frontend and backend groups, and in some companies even
                        more teams may be involved in the process.
                      </p>
                      <p>
                        Instead of using static certificate pinning you may want
                        to try out{' '}
                        <Link href={refs.dynamicPinning}>
                          Approov Dynamic Pinning
                        </Link>
                        . that will remove all the complexity, associated risks
                        and the need to release new versions of the app with
                        updated pins.{' '}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>

            <div className="w-full p-2 mx-auto mt-4 bg-white rounded-lg">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full py-2 pr-3 text-lg font-bold text-left text-gray-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-primary-500 focus-visible:ring-opacity-75">
                      <div className="ml-2">
                        What Are The Most Common Certificate Pinning Pitfalls?
                      </div>
                      <ChevronDownIcon
                        className={`${
                          open ? 'transform rotate-180' : ''
                        } w-5 h-5 text-gray-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 text-sm text-gray-700">
                      <p className="mt-2">
                        The DevOps team rotates the server certificate without
                        communicating it to the developers or fails to
                        coordinate the rotation with the release of a new
                        version of the mobile app containing a new public key
                        pin.
                      </p>
                      <p>
                        Mechanisms set up to ensure that certificate renewal
                        uses the same public key don't work or are accidentally
                        disabled.
                      </p>
                      <p>
                        Not realising that the pinned leaf certificate presented
                        by a server may depend on the geographic location when
                        the API is implemented in multiple different data
                        centers.
                      </p>
                      <p>
                        Failing to provide a backup pin, which might otherwise
                        provide an emergency solution for certificate rotation
                        issues.
                      </p>
                      <p>
                        Releasing the mobile app with the wrong pin, because the
                        testing has been done with different endpoints.
                      </p>
                      <p>
                        Not realising that the platform pinning support is only
                        available from Android 7 and iOS 14, so users on
                        versions below this will not have the benefit of pinned
                        connections.
                      </p>
                      <p>
                        On Android, releasing the app with pin expiration dates
                        and forgetting to update these, so that the Android app
                        eventually becomes unpinned.
                      </p>
                      <p>
                        To avoid being trapped in any of these pitfalls you may
                        want to try out{' '}
                        <Link href={refs.dynamicPinning}>
                          Approov Dynamic Pinning
                        </Link>
                        . that will remove these risks and allow over-the-air
                        pin updates.
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </div>
    </Tab.Panel>
  )
}
