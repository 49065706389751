import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { Link } from '@components/core'
import { MenuIcon } from '../core/icons/menu'
import { XIcon } from '../core/icons/x'

import { ApproovLogo } from '../site/approov'

const year = new Date().getFullYear()

const DevLayout = ({ title, items, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      <div>
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      type="button"
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex items-center flex-shrink-0 px-4">
                  <Link to="/">
                    <ApproovLogo className="w-auto h-10 mr-4" />
                  </Link>
                </div>
                <div className="flex-1 h-0 mt-5 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {items.map(({ label, href }) => (
                      <Link
                        key={label}
                        href={href}
                        className={classNames(
                          'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md hover:no-underline'
                        )}
                        activeClassName={classNames(
                          'bg-gray-100 text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md hover:no-underline'
                        )}
                      >
                        {label}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div
              className="flex-shrink-0 w-14"
              aria-hidden="true"
              note="dummy element to force sidebar to shrink to fit close icon"
            />
          </Dialog>
        </Transition>

        <div className="hidden md:flex md:w-48 md:flex-col md:fixed md:inset-y-0">
          <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-white border-r border-gray-200">
            <div className="flex items-center flex-shrink-0 px-4">
              <Link href="/">
                <ApproovLogo className="w-auto h-10 mr-4" />
              </Link>
            </div>
            <div className="flex flex-col flex-grow mt-5">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {items.map(({ label, href }) => (
                  <Link
                    key={label}
                    href={href}
                    className={classNames(
                      'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md hover:no-underline'
                    )}
                    activeClassName={classNames(
                      'bg-gray-100 text-gray-900',
                      'group flex items-center px-2 py-2 text-base font-medium rounded-md hover:no-underline'
                    )}
                  >
                    {label}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1 min-h-screen md:pl-48">
          <header className="sticky top-0 z-10 flex items-center justify-between flex-shrink-0 h-16 bg-white shadow md:justify-center">
            <div className="flex justify-start w-32 md:hidden">
              <button
                type="button"
                className="mx-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuIcon className="w-8 h-8" aria-hidden="true" />
              </button>
            </div>
            <h1 className="m-0 text-3xl text-primary-600">{title}</h1>
            <div className="flex justify-end w-32 md:hidden">
              <Link href="/">
                <ApproovLogo className="w-auto h-10 mr-4" />
              </Link>
            </div>
          </header>

          <main className="flex-1">{children}</main>

          <footer className="flex items-center justify-center w-full py-1 text-gray-300 bg-gray-700 hover:text-white ">
            {`Copyright © ${year} CriticalBlue, Ltd. All Rights Reserved.`}
          </footer>
        </div>
      </div>
    </>
  )
}

export { DevLayout }
