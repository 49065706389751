import React from 'react'

export const HackingProtectionIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.6988 22.614C15.8692 22.9122 15.7656 23.292 15.4674 23.4624C9.4543 26.8985 7.57686 31.0368 7.36259 32.5151C7.35598 32.5607 7.34535 32.602 7.33285 32.6388C4.7277 40.3127 4.8841 45.4314 6.04342 48.694C7.20027 51.9498 9.36569 53.3995 10.9156 53.7736C11.2494 53.8542 11.4547 54.1901 11.3741 54.524C11.2935 54.8578 10.9576 55.0631 10.6238 54.9825C8.63848 54.5033 6.15953 52.7351 4.8716 49.1104C3.58864 45.4998 3.49766 40.0896 6.13973 32.2849C6.4522 30.3148 8.64439 25.9289 14.8504 22.3826C15.1486 22.2123 15.5284 22.3158 15.6988 22.614Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4633 22.614C38.2929 22.9122 38.3965 23.292 38.6947 23.4624C44.7078 26.8985 46.5852 31.0368 46.7995 32.5151C46.8061 32.5607 46.8168 32.602 46.8293 32.6388C49.4344 40.3127 49.278 45.4314 48.1187 48.694C46.9618 51.9498 44.7964 53.3995 43.2465 53.7736C42.9127 53.8542 42.7074 54.1901 42.788 54.524C42.8686 54.8578 43.2045 55.0631 43.5383 54.9825C45.5236 54.5033 48.0026 52.7351 49.2905 49.1104C50.5735 45.4998 50.6644 40.0896 48.0224 32.2849C47.7099 30.3148 45.5177 25.9289 39.3117 22.3826C39.0135 22.2123 38.6337 22.3158 38.4633 22.614Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M35.1937 22.5561C37.2324 21.5058 38.0549 20.2766 38.0549 19.2648C38.0549 18.2531 37.2324 17.0238 35.1937 15.9736C33.2192 14.9564 30.405 14.2905 27.2284 14.2905C24.0517 14.2905 21.2375 14.9564 19.263 15.9736C17.2243 17.0238 16.4018 18.2531 16.4018 19.2648C16.4018 20.2766 17.2243 21.5058 19.263 22.5561C21.2375 23.5732 24.0517 24.2392 27.2284 24.2392C30.405 24.2392 33.2192 23.5732 35.1937 22.5561ZM27.2284 25.4828C33.8945 25.4828 39.2985 22.6989 39.2985 19.2648C39.2985 15.8308 33.8945 13.0469 27.2284 13.0469C20.5622 13.0469 15.1582 15.8308 15.1582 19.2648C15.1582 22.6989 20.5622 25.4828 27.2284 25.4828Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M26.823 1.33028C28.6357 2.56259 31.1397 4.81373 33.3242 7.77511C35.6159 10.882 37.5258 14.7296 37.9489 18.961C37.9831 19.3027 38.2878 19.552 38.6295 19.5178C38.9712 19.4837 39.2205 19.1789 39.1863 18.8372C38.7343 14.3165 36.7027 10.2604 34.3249 7.0369C31.9482 3.81476 29.1954 1.38352 27.2357 0.111412C27.0144 -0.032228 26.751 -0.028573 26.5462 0.0743919C17.4914 4.62744 15.5167 14.5399 15.2696 18.8636C15.25 19.2065 15.512 19.5003 15.8549 19.5199C16.1977 19.5395 16.4916 19.2774 16.5112 18.9346C16.7484 14.7833 18.6245 5.6365 26.823 1.33028Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M40.762 51.4519H12.9641C12.3581 51.4519 11.8668 51.9432 11.8668 52.5492C11.8668 53.1552 12.3581 53.6465 12.9641 53.6465H40.762C41.3681 53.6465 41.8593 53.1552 41.8593 52.5492C41.8593 51.9432 41.3681 51.4519 40.762 51.4519ZM12.9641 50.3546C11.7521 50.3546 10.7695 51.3372 10.7695 52.5492C10.7695 53.7612 11.7521 54.7438 12.9641 54.7438H40.762C41.9741 54.7438 42.9566 53.7612 42.9566 52.5492C42.9566 51.3372 41.9741 50.3546 40.762 50.3546H12.9641Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M40.0305 32.4322H13.6956C12.6856 32.4322 11.8668 33.251 11.8668 34.2611V48.16C11.8668 49.1701 12.6856 49.9888 13.6956 49.9888H40.0305C41.0405 49.9888 41.8593 49.1701 41.8593 48.16V34.2611C41.8593 33.251 41.0405 32.4322 40.0305 32.4322ZM13.6956 31.335C12.0796 31.335 10.7695 32.645 10.7695 34.2611V48.16C10.7695 49.7761 12.0796 51.0861 13.6956 51.0861H40.0305C41.6466 51.0861 42.9566 49.7761 42.9566 48.16V34.2611C42.9566 32.645 41.6466 31.335 40.0305 31.335H13.6956Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M16.492 26.0574C16.5787 26.3897 16.3796 26.7293 16.0473 26.816C12.9401 27.6266 10.8905 30.1141 9.59089 33.0242C8.29283 35.9308 7.79274 39.1575 7.73358 41.2284C7.72377 41.5716 7.43755 41.842 7.09428 41.8322C6.75101 41.8223 6.48069 41.5361 6.49049 41.1929C6.55325 38.9965 7.07729 35.6029 8.45539 32.5171C9.83191 29.4348 12.1105 26.5578 15.7334 25.6127C16.0657 25.526 16.4053 25.7251 16.492 26.0574Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.2346 26.0574C37.1479 26.3897 37.347 26.7293 37.6793 26.816C40.7864 27.6266 42.836 30.1141 44.1357 33.0242C45.4337 35.9308 45.9338 39.1575 45.993 41.2284C46.0028 41.5716 46.289 41.842 46.6323 41.8322C46.9756 41.8223 47.2459 41.5361 47.2361 41.1929C47.1733 38.9965 46.6493 35.6029 45.2712 32.5171C43.8946 29.4348 41.6161 26.5578 37.9932 25.6127C37.6609 25.526 37.3213 25.7251 37.2346 26.0574Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9758 32.981C15.2788 32.981 15.5244 33.2266 15.5244 33.5296L15.5244 48.1601C15.5244 48.4631 15.2788 48.7087 14.9758 48.7087C14.6728 48.7087 14.4271 48.4631 14.4271 48.1601V33.5296C14.4271 33.2266 14.6728 32.981 14.9758 32.981Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M39.1164 32.981C39.4194 32.981 39.665 33.2266 39.665 33.5296V48.1601C39.665 48.4631 39.4194 48.7087 39.1164 48.7087C38.8134 48.7087 38.5678 48.4631 38.5678 48.1601V33.5296C38.5678 33.2266 38.8134 32.981 39.1164 32.981Z" fill="#1B3D57"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1444 43.5727C28.2493 43.5727 29.1449 42.677 29.1449 41.5721C29.1449 40.4673 28.2493 39.5716 27.1444 39.5716C26.0395 39.5716 25.1438 40.4673 25.1438 41.5721C25.1438 42.677 26.0395 43.5727 27.1444 43.5727ZM27.1444 44.1442C28.5649 44.1442 29.7165 42.9927 29.7165 41.5721C29.7165 40.1516 28.5649 39 27.1444 39C25.7238 39 24.5723 40.1516 24.5723 41.5721C24.5723 42.9927 25.7238 44.1442 27.1444 44.1442Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1448 37.0843C27.6183 37.0843 28.0022 36.7004 28.0022 36.2269C28.0022 35.7534 27.6183 35.3696 27.1448 35.3696C26.6713 35.3696 26.2874 35.7534 26.2874 36.2269C26.2874 36.7004 26.6713 37.0843 27.1448 37.0843ZM27.1448 37.6559C27.934 37.6559 28.5737 37.0161 28.5737 36.2269C28.5737 35.4377 27.934 34.798 27.1448 34.798C26.3556 34.798 25.7158 35.4377 25.7158 36.2269C25.7158 37.0161 26.3556 37.6559 27.1448 37.6559Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1448 47.7863C27.6183 47.7863 28.0022 47.4025 28.0022 46.929C28.0022 46.4554 27.6183 46.0716 27.1448 46.0716C26.6713 46.0716 26.2874 46.4554 26.2874 46.929C26.2874 47.4025 26.6713 47.7863 27.1448 47.7863ZM27.1448 48.3579C27.934 48.3579 28.5737 47.7181 28.5737 46.929C28.5737 46.1398 27.934 45.5 27.1448 45.5C26.3556 45.5 25.7158 46.1398 25.7158 46.929C25.7158 47.7181 26.3556 48.3579 27.1448 48.3579Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.429 42.4863C21.9025 42.4863 22.2863 42.1024 22.2863 41.6289C22.2863 41.1554 21.9025 40.7715 21.429 40.7715C20.9554 40.7715 20.5716 41.1554 20.5716 41.6289C20.5716 42.1024 20.9554 42.4863 21.429 42.4863ZM21.429 43.0579C22.2181 43.0579 22.8579 42.4181 22.8579 41.6289C22.8579 40.8397 22.2181 40.2 21.429 40.2C20.6398 40.2 20 40.8397 20 41.6289C20 42.4181 20.6398 43.0579 21.429 43.0579Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M32.8606 42.4863C33.3341 42.4863 33.718 42.1024 33.718 41.6289C33.718 41.1554 33.3341 40.7715 32.8606 40.7715C32.3871 40.7715 32.0032 41.1554 32.0032 41.6289C32.0032 42.1024 32.3871 42.4863 32.8606 42.4863ZM32.8606 43.0579C33.6498 43.0579 34.2896 42.4181 34.2896 41.6289C34.2896 40.8397 33.6498 40.2 32.8606 40.2C32.0714 40.2 31.4316 40.8397 31.4316 41.6289C31.4316 42.4181 32.0714 43.0579 32.8606 43.0579Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.43 37.0843L27.43 39.3706L26.8584 39.3706L26.8584 37.0843L27.43 37.0843Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M27.43 43.7137L27.43 46L26.8584 46L26.8584 43.7137L27.43 43.7137Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M22.573 41.4284H24.8594V42H22.573V41.4284Z" fill="#78A65A"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4314 41.4284H31.7178V42H29.4314V41.4284Z" fill="#78A65A"/>
</svg>
)