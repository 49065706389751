import React, { useState } from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import { ResourceDialog } from './resource-dialog'

const ResourceContext = React.createContext()

const ResourceProvider = ({ children }) => {
  const {
    allResourcesYaml: { nodes: resources },
  } = useStaticQuery(
    graphql`
      query {
        allResourcesYaml {
          nodes {
            title
            subtitle
            type
            img {
              id
              childImageSharp {
                gatsbyImageData(width: 256, transformOptions: { fit: COVER })
              }
            }
            url
            tags
            name
          }
        }
      }
    `
  )

  const location = useLocation()

  // check query strings to see if video or offer open requested
  const initialResource = () => {
    console.log('initialresource starting...')
    if (location?.search) {
      const qs = queryString.parse(location.search)
      const name = qs?.open
      console.log(`initialresource open: ${name}`)
      if (name) {
        const resource = resources.find((r) => r.name === name)
        if (resource) {
          console.log(`initialresource type: ${resource.type}`)
          if (resource.type === 'video') {
            return resource
          } else if (resource.type === 'offer') {
            return resource
          } else if (resource.type === 'link') {
            // @todo add link navigation
            return resource
          }
        }
      }
    }
    return null
  }

  const [resource, setResource] = useState(initialResource)
  const select = (resource) => {
    setResource(resource)
  }
  const dismiss = () => {
    setResource(null)
  }

  if (resource && resource.type === 'link') {
    navigate(resource.url)
  }

  return (
    <>
      <ResourceContext.Provider value={{ resources, select, dismiss }}>
        {children}
      </ResourceContext.Provider>
      <ResourceDialog
        resource={resource}
        open={!!resource && resource.type !== 'link'}
        onClose={dismiss}
      />
    </>
  )
}

const useResource = () => {
  const context = React.useContext(ResourceContext)

  if (context === undefined) {
    throw new Error('useResource must be used within a ResourceProvider')
  }

  return context
}

export { ResourceProvider, useResource }
