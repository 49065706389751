import React from "react"
import { MDXProvider as GatsbyMDXProvider } from "@mdx-js/react"

// add pre-included components here
const shortcodes = {}

const MDXProvider = ({ components = {}, children, ...rest }) => {
  return (
    <GatsbyMDXProvider components={{ ...shortcodes, ...components }} {...rest}>
      {children}
    </GatsbyMDXProvider>
  )
}

export { MDXProvider }
