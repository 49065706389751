import React from 'react'
import { ConsultGuard } from './consult-guard'
import { LeadGuard } from './lead-guard'
import { PinningGuard } from './pinning-guard'
import { TrialGuard } from './trial-guard'
import { useOffer } from './use-offer'

const ComponentMap = {
  consult: ConsultGuard,
  lead: LeadGuard,
  pinning: PinningGuard,
  question: ConsultGuard,
  trial: TrialGuard,
  default: LeadGuard,
}

export const OfferGuard = ({
  offer,
  cid,
  show,
  setShow,
  onDismiss,
  isDismissed,
  toPayload,
  fromPayload,
}) => {
  // offer info

  const { offerType } = useOffer(offer)

  const Guard = ComponentMap[offerType] || ComponentMap.default

  return (
    <Guard
      offer={offer}
      cid={cid}
      show={show}
      setShow={setShow}
      onDismiss={onDismiss}
      isDismissed={isDismissed}
      toPayload={toPayload}
      fromPayload={fromPayload}
    />
  )
}
