import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

const heroTitleStyle = 'text-center font-bold font-hero text-[#acafb3] lg:text-[3.7rem] md:text-5xl sm:text-4xl text-4xl lg:leading-[3.6rem] leading-[3rem]';
export const CustomerBanner = ({ title, subtitle, alt, ...rest }) => (

  <div className="bg-[#122331] w-full min-h-[327px] h-[48vw] max-h-[474px] md:block flex flex-wrap content-center">
    <div className="container flex ">
      <div className="flex flex-col items-center md:mt-[4rem] md:mb-8 w-[250px] md:float-right m-auto">
        <h1
          className={classNames("m-0", heroTitleStyle)}
          data-sal="slide-right"
          data-sal-duration="2000"
          data-sal-delay="100"
          data-sal-easing="ease"
        >{title}</h1>
        
        <hr className="border-[3.3px] bg-[#b91684] border-[#b91684] w-3/4 my-6"/>
        <h2 className="m-0 text-center text-[#86b146] font-hero lg:text-[1.6rem] lg:leading-[28px] text-[1.2rem]">{subtitle}</h2>
      </div>
      <div className="hidden md:flex flex-wrap w-1/2 ml-[-67px] mt-[40px] not-prose flex content-center">
        <StaticImage
          src={`images/message.png`}
          className="w-[full] md:max-w-[500px] h-auto"
          alt={alt}
        />
      </div>
    </div>
  </div>

)