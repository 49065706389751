import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const imgDir = '.'
const imgProps = {
  placeholder: 'blurred',
}

export const MVLogo = (
  <StaticImage
    objectFit="cover"
    style={{
      height: '100%',
      width: '100%',
    }}
    src={`${imgDir}/mv-logo.png`}
    alt="MV"
    {...imgProps}
  />
)
