import React from 'react'

export const ProjectDataIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <path d="M50.0502 28.05H44.5502V24.2C44.5502 23.265 43.8352 22.55 42.9002 22.55H39.0502V17.6C39.0502 11.22 33.8802 6.05005 27.5002 6.05005C21.1202 6.05005 15.9502 11.22 15.9502 17.6V22.55H12.1002C11.1652 22.55 10.4502 23.265 10.4502 24.2V28.05H4.9502C3.4102 28.05 2.2002 29.26 2.2002 30.8V40.7001C2.2002 42.2401 3.4102 43.4501 4.9502 43.4501H10.4502V47.3C10.4502 48.2351 11.1652 48.9501 12.1002 48.9501H42.9002C43.8352 48.9501 44.5502 48.2351 44.5502 47.3V43.4501H50.0502C51.5902 43.4501 52.8002 42.2401 52.8002 40.7001V30.8C52.8002 29.26 51.5902 28.05 50.0502 28.05ZM17.0502 17.6C17.0502 11.825 21.7252 7.15005 27.5002 7.15005C33.2752 7.15005 37.9502 11.825 37.9502 17.6V22.55H35.2002V17.6C35.2002 13.365 31.7352 9.90005 27.5002 9.90005C23.2652 9.90005 19.8002 13.365 19.8002 17.6V22.55H17.0502V17.6ZM34.1002 22.55H20.9002V17.6C20.9002 13.97 23.8702 11 27.5002 11C31.1302 11 34.1002 13.97 34.1002 17.6V22.55ZM11.5502 24.2C11.5502 23.87 11.7702 23.65 12.1002 23.65H16.5002H20.3502H34.6502H38.5002H42.9002C43.2302 23.65 43.4502 23.87 43.4502 24.2V28.05H11.5502V24.2ZM43.4502 47.3C43.4502 47.6301 43.2302 47.85 42.9002 47.85H12.1002C11.7702 47.85 11.5502 47.6301 11.5502 47.3V43.4501H43.4502V47.3ZM51.7002 40.7001C51.7002 41.635 50.9852 42.35 50.0502 42.35H4.9502C4.0152 42.35 3.3002 41.635 3.3002 40.7001V30.8C3.3002 29.865 4.0152 29.15 4.9502 29.15H50.0502C50.9852 29.15 51.7002 29.865 51.7002 30.8V40.7001Z" fill="#1B3D57"/>
  <path d="M42.6798 36.55L44.8458 37.747L44.1048 39.039L41.9008 37.747L41.9198 40.198H40.4758L40.4948 37.766L38.3098 39.039L37.5498 37.747L39.7348 36.55L37.5498 35.353L38.3098 34.042L40.4948 35.334L40.4758 32.902H41.9198L41.9008 35.353L44.1048 34.042L44.8458 35.353L42.6798 36.55Z" fill="#78A65A"/>
  <path d="M33.2648 36.55L35.4308 37.747L34.6898 39.039L32.4858 37.747L32.5048 40.198H31.0608L31.0798 37.766L28.8948 39.039L28.1348 37.747L30.3198 36.55L28.1348 35.353L28.8948 34.042L31.0798 35.334L31.0608 32.902H32.5048L32.4858 35.353L34.6898 34.042L35.4308 35.353L33.2648 36.55Z" fill="#78A65A"/>
  <path d="M23.8488 36.55L26.0147 37.747L25.2738 39.039L23.0697 37.747L23.0887 40.198H21.6448L21.6637 37.766L19.4788 39.039L18.7188 37.747L20.9038 36.55L18.7188 35.353L19.4788 34.042L21.6637 35.334L21.6448 32.902H23.0887L23.0697 35.353L25.2738 34.042L26.0147 35.353L23.8488 36.55Z" fill="#78A65A"/>
  <path d="M14.4337 36.55L16.5997 37.747L15.8587 39.039L13.6547 37.747L13.6737 40.198H12.2297L12.2487 37.766L10.0637 39.039L9.30371 37.747L11.4887 36.55L9.30371 35.353L10.0637 34.042L12.2487 35.334L12.2297 32.902H13.6737L13.6547 35.353L15.8587 34.042L16.5997 35.353L14.4337 36.55Z" fill="#78A65A"/>
</svg>
)