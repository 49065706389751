import React from 'react'
import { Tab } from '@headlessui/react'
import { Alert } from '@components/core'
import {Highlighter} from "../../core/highlighter";

export const ResultsPanel = ({ logging, ready }) => {
  const noLogging = 'Only available after submitting the form...'

  return (
    <Tab.Panel>
      <div className="w-full mb-4 bg-gray-200">
        <div className="relative w-full p-4 whitespace-pre-wrap break-words flex flex-col flex-wrap gap-x-0 sm:gap-x-[2%]">
          <Alert className="p-2 my-2" status="success">
            Please visit the Android and iOS tabs for the certificate pinning
            configuration to use in your mobile app.
          </Alert>

          <h4 className="mt-2 text-xl font-bold text-gray-700">
            Processing Results for All Submitted Domains
          </h4>
          <Highlighter code={logging ? logging : noLogging} language="none" />
        </div>
      </div>
    </Tab.Panel>
  )
}
