import React from 'react'

export const PlagformAttestationIcon = (props) => (
<svg xmlns="http://www.w3.org/2000/svg" width="55" height="55" viewBox="0 0 55 55" fill="none" {...props}>
  <g clip-path="url(#clip0_284_1854)">
    <path d="M27.5709 50.2639H27.163L17.3052 45.5729C14.7831 44.3586 12.6544 42.458 11.1633 40.0891C9.67222 37.7202 8.87912 34.9789 8.875 32.1798V14.4357C8.88874 14.199 8.98448 13.9746 9.14578 13.8009C9.30707 13.6272 9.52383 13.5151 9.75881 13.4839C21.5202 12.2602 26.7551 5.93753 26.8231 5.86955C26.9051 5.75716 27.0125 5.66574 27.1366 5.60271C27.2606 5.53968 27.3978 5.50684 27.5369 5.50684C27.6761 5.50684 27.8132 5.53968 27.9373 5.60271C28.0613 5.66574 28.1687 5.75716 28.2508 5.86955C28.3188 5.93753 33.6896 12.2602 45.383 13.4839C45.6056 13.5291 45.8067 13.6472 45.9545 13.8196C46.1022 13.992 46.1883 14.2088 46.1989 14.4357V32.1798C46.182 34.9674 45.3829 37.6942 43.8925 40.0499C42.4022 42.4057 40.2804 44.2957 37.7687 45.5049L27.9108 50.1959L27.5709 50.2639ZM10.7786 15.2515V32.1798C10.7755 34.6141 11.464 36.9991 12.7639 39.0573C14.0638 41.1154 15.9216 42.762 18.121 43.8053L27.5709 48.3603L36.9529 43.8053C39.1599 42.7651 41.0272 41.1212 42.3387 39.0638C43.6501 37.0064 44.3521 34.6196 44.3633 32.1798V15.2515C34.7094 14.0278 29.3385 9.60873 27.5709 7.77313C25.7353 9.60873 20.3645 14.0278 10.7786 15.2515Z" fill="#1B3D57"/>
    <path d="M27.5711 55.0228H27.1632L15.1298 49.312C12.0953 47.8346 9.5367 45.5345 7.74573 42.6737C5.95476 39.813 5.00339 36.5067 5.00005 33.1316V10.6965C4.99769 10.4659 5.07909 10.2423 5.22914 10.0673C5.37919 9.89223 5.58767 9.77759 5.81587 9.74466C20.2967 8.24899 26.7553 0.430697 26.8233 0.362712C26.9053 0.250331 27.0127 0.158907 27.1368 0.095879C27.2608 0.0328513 27.398 0 27.5371 0C27.6763 0 27.8134 0.0328513 27.9375 0.095879C28.0615 0.158907 28.1689 0.250331 28.251 0.362712C28.319 0.430697 34.8455 8.24899 49.2584 9.74466C49.4934 9.77587 49.7101 9.88796 49.8714 10.0617C50.0327 10.2354 50.1285 10.4598 50.1422 10.6965V33.1316C50.1199 36.5023 49.1525 39.7993 47.3497 42.6476C45.547 45.4959 42.9814 47.7813 39.9444 49.244L27.9111 54.9548L27.5711 55.0228ZM6.83565 11.5123V33.1316C6.83807 36.1491 7.69499 39.1042 9.30722 41.6549C10.9195 44.2056 13.2211 46.2475 15.9457 47.5444L27.5711 53.1192L39.1286 47.5444C41.8532 46.2475 44.1548 44.2056 45.767 41.6549C47.3793 39.1042 48.2362 36.1491 48.2386 33.1316V11.5123C36.1373 10.0166 29.6107 4.44182 27.5711 2.33428C25.4636 4.44182 18.937 10.0166 6.83565 11.5123Z" fill="#1B3D57"/>
    <path d="M27.5541 16C30.3331 16 32.5859 18.2528 32.5859 21.0318L32.5868 24.065C33.2994 24.1045 33.7817 24.2312 34.2809 24.4981C34.8719 24.8143 35.3448 25.2872 35.6609 25.8782C35.9932 26.4996 36.1082 27.0948 36.1082 28.1407V34.0503C36.1082 35.0961 35.9932 35.6913 35.6609 36.3127C35.3448 36.9038 34.8719 37.3767 34.2809 37.6928C33.6595 38.0251 33.0643 38.14 32.0184 38.14H23.0897C22.0439 38.14 21.4487 38.0251 20.8273 37.6928C20.2362 37.3767 19.7633 36.9038 19.4472 36.3127C19.1149 35.6913 19 35.0961 19 34.0503V28.1407C19 27.0948 19.1149 26.4996 19.4472 25.8782C19.7633 25.2872 20.2362 24.8143 20.8273 24.4981C21.3266 24.2311 21.809 24.1045 22.5219 24.065L22.5223 21.0318C22.5223 18.2528 24.7751 16 27.5541 16ZM32.0184 25.5605H23.0897C22.1925 25.5605 21.8672 25.6539 21.5392 25.8293C21.2112 26.0047 20.9538 26.2621 20.7784 26.5901C20.603 26.9181 20.5095 27.2435 20.5095 28.1407V34.0503C20.5095 34.9474 20.603 35.2728 20.7784 35.6008C20.9538 35.9288 21.2112 36.1862 21.5392 36.3616C21.8672 36.537 22.1925 36.6305 23.0897 36.6305H32.0184C32.9156 36.6305 33.241 36.537 33.569 36.3616C33.897 36.1862 34.1544 35.9288 34.3298 35.6008C34.5052 35.2728 34.5986 34.9474 34.5986 34.0503V28.1407C34.5986 27.2435 34.5052 26.9181 34.3298 26.5901C34.1544 26.2621 33.897 26.0047 33.569 25.8293C33.241 25.6539 32.9156 25.5605 32.0184 25.5605ZM27.5541 28.5795C28.3878 28.5795 29.0636 29.2554 29.0636 30.0891C29.0636 30.7462 28.6438 31.3052 28.0577 31.5126L28.0573 33.1082C28.0573 33.3861 27.832 33.6114 27.5541 33.6114C27.2762 33.6114 27.0509 33.3861 27.0509 33.1082L27.0509 31.5128C26.4646 31.3055 26.0445 30.7464 26.0445 30.0891C26.0445 29.2554 26.7204 28.5795 27.5541 28.5795ZM27.5541 17.5095C25.6088 17.5095 24.0318 19.0865 24.0318 21.0318V24.0509H31.0763V21.0318C31.0763 19.0865 29.4994 17.5095 27.5541 17.5095Z" fill="#78A65A"/>
  </g>
  <defs>
    <clipPath id="clip0_284_1854">
      <rect width="55" height="55" fill="white"/>
    </clipPath>
  </defs>
</svg>
)