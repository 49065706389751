import React, { useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import isEmail from 'validator/es/lib/isEmail'
import { useCookies } from 'react-cookie'
import { Button, Link } from '@components/core'
import { useLead } from './use-lead'
import { usePage } from './use-page'
import { useOffer } from './use-offer'
import { submitForm } from './offer-api'

export const ContactForm = ({ offer, gacname }) => {
  // offer info

  const { offerId, formId, gaConversionName } = useOffer(offer)
  const submitId = `submit-${offerId}`

  // page info

  const { pageUri, pageName, queryParams } = usePage()

  const extraFields = {
    utm_campaign: queryParams.utm_campaign || '',
    utm_source: queryParams.utm_source || '',
    utm_medium: queryParams.utm_medium || '',
    utm_term: queryParams.utm_term || '',
    utm_content: queryParams.utm_content || '',
    google_ads_conversion_name:
      (queryParams.gclid && gaConversionName) || undefined,
    google_ads_click_id: queryParams.gclid || undefined,
  }

  // cookie storage

  const [cookies] = useCookies(['hubspotutk'])

  // lead storage/state

  const [lead, setLead] = useLead()

  // display state

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)

  // form prep

  const defaultFieldValues = {
    firstName: lead?.firstName || '',
    lastName: lead?.lastName || '',
    email: lead?.email || '',
    inquiry: '',
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onBlur', defaultValues: defaultFieldValues })

  // submit handler

  const onSubmit = async (data) => {
    setIsSubmitted(false)
    console.log('Submission starting', data)

    let result

    // update lead

    setLead({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    })

    // submit form

    try {
      const fields = [
        {
          name: 'firstname',
          value: data.firstName,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'inquiry',
          value: data.inquiry,
        },
      ]
      for (const field in extraFields) {
        if (extraFields[field] !== undefined) {
          fields.push({ name: field, value: extraFields[field] })
        }
      }

      const context = {
        hutk: cookies.hubspotutk,
        pageUri: pageUri,
        pageName: pageName,
      }

      console.log(`submit data: ${JSON.stringify({ fields, context })}`)

      result = await submitForm({
        formId,
        fields,
        context,
      })

      if (!result.isAccepted) {
        setIsAccepted(false)
        setIsSubmitted(true)
        return
      }
    } catch (e) {
      setIsAccepted(true)
      setIsSubmitted(true)
      return
    }

    // clear state
    setIsSubmitted(false)
    setIsAccepted(true)
  }

  // confirm thanks

  const onConfirm = () => {
    // clear state
    setIsAccepted(false)
  }

  if (!isAccepted) {
    return (
      <form
        className="p-4 bg-gray-100 rounded"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-6 gap-y-4 gap-x-4">
          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First name
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.firstName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.firstName,
                  }
                )}
                placeholder="first"
                {...register('firstName', { required: true, minLength: 1 })}
              />
              {errors.firstName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  First name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="last"
              className="block text-sm font-medium text-gray-700"
            >
              Last name
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.lastName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.lastName,
                  }
                )}
                placeholder="last"
                {...register('lastName', { required: true, minLength: 1 })}
              />
              {errors.lastName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Last name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Business email
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.email,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.email,
                  }
                )}
                placeholder="email"
                {...register('email', {
                  required: true,
                  validate: {
                    format: (v) => isEmail(v),
                  },
                })}
              />
              {errors.email && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Valid email is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6">
            <label
              htmlFor="info"
              className="block text-sm font-medium text-gray-700"
            >
              Inquiry
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <textarea
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.inquiry,
                  },
                  {
                    'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md':
                      errors.inquiry,
                  }
                )}
                rows={4}
                placeholder="Inquiry"
                {...register('inquiry', { required: true, minLength: 4 })}
              />
              {errors.inquiry && (
                <p className="mt-2 mb-0 text-sm text-red-600">
                  How can we help you? (required)
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6">
            <p className="mt-0 text-sm leading-tight text-gray-500">
              CriticalBlue (developer of Approov) will use the personal
              information you provide to send you the content requested and
              information about our services. You may unsubscribe from these
              communications at any time by clicking the link at the bottom of
              our emails. For information on our privacy practices and
              commitment to protecting your privacy, check out our{' '}
              <Link href="/privacy">Privacy Policy</Link>.
            </p>
            {isSubmitted && !isAccepted && (
              <p className="mt-0 text-sm leading-tight text-failure-500">
                An unexpected error occurred while submitting this form. Please
                try your submission again, and if you continue to have trouble,
                please let us know at the{' '}
                <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do our
                best to resolve the issue.
              </p>
            )}
          </div>
        </div>

        <div className="pt-4">
          <div className="flex justify-end">
            <Button
              id={submitId}
              type="submit"
              className="ml-3 button-secondary bg-[#86b146]"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    )
  } else {
    // advise on what is next
    return (
      <div className="p-4 bg-gray-100 rounded">
        <p>Thank you for contacting the Approov team.</p>
        <p>We will reply to your inquiry as soon as we can.</p>

        <div className="pt-4">
          <div className="flex justify-end">
            <Button className="ml-3 button-secondary" onClick={onConfirm}>
              OK
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
