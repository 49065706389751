import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const DynamicCertPinningBanner = ({ title, subtitle, nounderline, ...rest }) => (
  <>
    <div className="bg-[#122331] w-full min-h-[327px]">
        <div className="container flex flex-col md:flex-row justify-center md:justify-between items-center px-[32px]">
          <div className="w-fit md:w-1/2 relative">
              <h1 className="text-[28px] xxs:text-[35px] xs:text-[46px] sm:text-[58px] md:text-[46px] lg:text-[58px] leading-[58px] font-black self-center text-left tracking-tight mt-[80px] md:mt-[20px] ml-0 mb-0 text-[#acafb3]">
                Dynamic<br></br>Certificate<br></br>Pinning
              </h1>
              <hr className="w-full md:w-[70%] border-[3.3px] bg-[#b91684] border-[#b91684] mt-[20px]" />
          </div>
          <div className="w-4/5 xs:w-3/4 sm:w-2/3 md:w-1/2  md:mx-0 pb-[30px] pt-0 md:pt-[30px] text-right not-prose">
            <StaticImage
              src={"./images/dyanmic-certificate-pinning.png"}
              className="w-full md:max-w-[490px] h-auto"
              alt="Mobile Certificate Pinning Android | MitM Attack Prevention | Approov"
            />
          </div>
        </div>
    </div>
  </>
)
