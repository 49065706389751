import React from 'react'
import classNames from 'classnames'
import { Dialog, Transition } from '@headlessui/react'
import { Prose } from '@components/core'
import { useOffer } from './use-offer'
import { OfferForm } from './offer-form'
import { XIcon } from '../../core/icons/x'

const CloseButton = ({ className, onDismiss }) => (
  <button
    type="button"
    className={classNames(
      'absolute flex items-center justify-center w-5 h-5 ml-1 text-black rounded-full top-1 right-1 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-grey-800',
      className
    )}
    onClick={onDismiss}
  >
    <span className="sr-only">Close sidebar</span>
    <XIcon className="w-4 h-4" aria-hidden="true" />
  </button>
)

const OfferDialog = ({ offer, open, onClose }) => {
  const { offerName: title } = useOffer(offer)

  return (
    <Transition
      show={open}
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
    >
      <Dialog
        open={open}
        onClose={onClose}
        className="fixed inset-0 z-40 overflow-y-auto"
      >
        <Prose>
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-overlay" />

            <div className="relative max-w-4xl px-4 bg-white border border-gray-200 rounded shadow-xl">
              <CloseButton className="mt-2 mr-2" onDismiss={onClose} />
              <div className="flex flex-col">
                <div className="flex items-center justify-between">
                  <h2 className="my-2">{title}</h2>
                </div>
                <OfferForm offer={offer} onDismiss={onClose} />
              </div>
            </div>
          </div>
        </Prose>
      </Dialog>
    </Transition>
  )
}

export { OfferDialog }
