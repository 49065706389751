import classNames from 'classnames'
import React from 'react'

const capitalize = (str) => str && str[0].toUpperCase() + str.slice(1)

const colorValues = {
  neutral:
    'bg-gray-50 bg-gray-100 bg-gray-200 bg-gray-300 bg-gray-400 bg-gray-500 bg-gray-600 bg-gray-700 bg-gray-800 bg-gray-900',
  primary:
    'bg-primary-50 bg-primary-100 bg-primary-200 bg-primary-300 bg-primary-400 bg-primary-500 bg-primary-600 bg-primary-700 bg-primary-800 bg-primary-900',
  secondary:
    'bg-secondary-50 bg-secondary-100 bg-secondary-200 bg-secondary-300 bg-secondary-400 bg-secondary-500 bg-secondary-600 bg-secondary-700 bg-secondary-800 bg-secondary-900',
}

const ColorPalette = ({ name, ...rest }) => {
  const title = capitalize(name)
  const values = colorValues[name].split(' ')
  const tint = `bg-${name}-`.length

  return (
    <div {...rest}>
      <h2>{title}</h2>
      <div className="flex flex-wrap gap-4">
        {values.map((v) => {
          return (
            <div key={v} className="flex flex-col items-center">
              <div
                className={classNames(
                  'w-16 h-16 border border-black border-solid',
                  v
                )}
              />
              <p className="m-0 mt-1 text-sm">{v.slice(tint)}</p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const SingleColors = ({ ...rest }) => {
  return (
    <div {...rest}>
      <h2>Single Colors</h2>
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-white border border-black border-solid" />
          <p className="m-0 mt-1 text-sm">White</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-black border border-black border-solid" />
          <p className="m-0 mt-1 text-sm">Black</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-overlay" />
          <p className="m-0 mt-1 text-sm">Overlay</p>
        </div>
      </div>
    </div>
  )
}

const ApproovLogoColors = ({ ...rest }) => {
  return (
    <div {...rest}>
      <h2>Approov Logo Colors</h2>
      <div className="flex flex-wrap gap-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-approov-blue" />
          <p className="m-0 mt-1 text-sm">#2484C6</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-approov-green" />
          <p className="m-0 mt-1 text-sm">#8DC63F</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-approov-red" />
          <p className="m-0 mt-1 text-sm">#D93B20</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-approov-wall" />
          <p className="m-0 mt-1 text-sm">#53B7E8</p>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 border border-black border-solid bg-approov-text" />
          <p className="m-0 mt-1 text-sm">#4D4D4F</p>
        </div>
      </div>
    </div>
  )
}

export { ColorPalette, SingleColors, ApproovLogoColors }
