import React, { useState } from 'react'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import isEmail from 'validator/es/lib/isEmail'
import { useCookies } from 'react-cookie'
import { Button, Link } from '@components/core'
import { useLead } from './use-lead'
import { usePage } from './use-page'
import { useOffer } from './use-offer'
import { checkEmail, submitForm } from './offer-api'
import countries from './country'

export const TrialForm = ({ offer }) => {
  // offer info

  const { offerId, formId, offerName, resourceHref, gaConversionName } =
    useOffer(offer)
  const submitId = `submit-${offerId}`
  const thanks = "We're processing your request."
  const subject = `${offerName} Request`
  const body = `Thank you for requesting the ${offerName}.\n\nClick LINK-HERE to confirm your request.`

  // page info

  const { siteUrl, pageUrl, pageUri, pageName, queryParams } = usePage()

  const extraFields = {
    utm_campaign: queryParams.utm_campaign || '',
    utm_source: queryParams.utm_source || '',
    utm_medium: queryParams.utm_medium || '',
    utm_term: queryParams.utm_term || '',
    utm_content: queryParams.utm_content || '',
    google_ads_conversion_name:
      (queryParams.gclid && gaConversionName) || undefined,
    google_ads_click_id: queryParams.gclid || undefined,
  }

  // cookie storage

  const [cookies] = useCookies(['hubspotutk'])

  // lead storage/state

  const [lead, setLead] = useLead()

  // display state

  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isQualified, setIsQualified] = useState(false)
  const [isAccepted, setIsAccepted] = useState(false)

  // form prep

  const defaultFieldValues = {
    firstName: lead?.firstName || '',
    lastName: lead?.lastName || '',
    email: lead?.email || '',
    info: '',
  }

  const {
    register,
    clearErrors,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onBlur', defaultValues: defaultFieldValues })

  // submit handler

  const onSubmit = async (data) => {
    setIsSubmitted(false)
    console.log('Submission starting', data)

    let result

    // qualify email

    try {
      result = await checkEmail(data.email, pageUrl)

      if (!result.isQualified) {
        setError('email', {
          type: result.isNotBiz ? 'not-biz' : 'validate',
          rejectionReason: result.rejectionReason,
        })
        setIsQualified(false)
        setIsSubmitted(true)
        return
      }
    } catch (e) {
      setError('email', {
        type: 'validate',
        rejectionReason: 'unexpected error',
      })
      setIsQualified(false)
      setIsSubmitted(true)
      return
    }

    clearErrors('email')
    setIsQualified(true)

    // update lead

    setLead({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
    })

    // submit form

    try {
      const fields = [
        {
          name: 'firstname',
          value: data.firstName,
        },
        {
          name: 'lastname',
          value: data.lastName,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'info',
          value: data.info,
        },
        {
          name: 'company',
          value: data.company,
        },
        {
          name: 'website',
          value: data.website_url,
        },
        {
          name: 'country',
          value: data.country,
        },
        {
          name: 'jobtitle',
          value: data.job_title,
        },
        {
          name: 'time_frame_for_addressing_your_challenge_s__cloned',
          value: data.time_frame_for_addressing_your_challenge,
        },
      ]
      for (const field in extraFields) {
        if (extraFields[field] !== undefined) {
          fields.push({ name: field, value: extraFields[field] })
        }
      }

      const context = {
        hutk: cookies.hubspotutk,
        pageUri: pageUri,
        pageName: pageName,
      }

      const confirm = {
        url: `${siteUrl}${resourceHref}`,
        body: body,
        subject: subject,
        // payload: undefined,
      }

      console.log(
        `submit data: ${JSON.stringify({ fields, context, confirm })}`
      )

      result = await submitForm({
        formId,
        fields,
        context,
        confirm: confirm,
      })

      if (!result.isAccepted) {
        setIsAccepted(false)
        setIsSubmitted(true)
        return
      }
    } catch (e) {
      setIsAccepted(true)
      setIsSubmitted(true)
      return
    }

    // clear state
    setIsSubmitted(false)
    setIsAccepted(true)
  }

  // confirm thanks

  // const onConfirm = () => {
  //   // clear state
  //   setIsAccepted(false)
  // }

  if (!isAccepted) {
    return (
      <form
        className="p-4 bg-gray-100 rounded"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid grid-cols-6 gap-y-4 gap-x-4">
          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-gray-700"
            >
              First name*
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.firstName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.firstName,
                  }
                )}
                placeholder=""
                {...register('firstName', { required: true, minLength: 1 })}
              />
              {errors.firstName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  First name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="last"
              className="block text-sm font-medium text-gray-700"
            >
              Last name*
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.lastName,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.lastName,
                  }
                )}
                placeholder=""
                {...register('lastName', { required: true, minLength: 1 })}
              />
              {errors.lastName && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Last name is required
                </p>
              )}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Business email*
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.email,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.email,
                  }
                )}
                placeholder=""
                {...register('email', {
                  required: true,
                  validate: {
                    format: (v) => isEmail(v),
                  },
                })}
              />
              {errors.email && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  A valid business email address is required to deliver your
                  request (rejection reason: {errors.email.rejectionReason}).
                  Please try again with your work email. If unable to provide
                  another email, or you think this email is valid, let us know
                  at the <Link href="https://approov.io/info/contact">contact us</Link> page.
                </p>
              )}
            </div>
          </div>
          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="company"
              className="block text-sm font-medium text-gray-700"
            >
              Company
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.company,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.company,
                  }
                )}
                placeholder=""
                {...register('company', { required: false, minLength: 1 })}
              />
              {/* {errors.company && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Company is required
                </p>
              )} */}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="website_url"
              className="block text-sm font-medium text-gray-700"
            >
              Website URL
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.website_url,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.website_url,
                  }
                )}
                placeholder=""
                {...register('website_url', { required: false, minLength: 1 })}
              />
              {/* {errors.website_url && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Website URL is required
                </p>
              )} */}
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="country"
              className="block text-sm font-medium text-gray-700"
            >
              Country/Region
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <select id="country" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm" name="country" aria-required="false"
              {...register('country')}
              >
                <option label="" value=""></option>

                {countries.map((country, index) => <option label={country.name} disabled="" value={country.name}>{country.name}</option>)}
              </select>
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="job_title"
              className="block text-sm font-medium text-gray-700"
            >
              Job Title
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                className={classNames(
                  'block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm'
                )}
                placeholder=""
                {...register('job_title', { required: false, minLength: 1 })}
              />
            </div>
          </div>

          <div className="col-span-6 md:col-span-3">
            <label
              htmlFor="your_highest_priority"
              className="block text-sm font-medium text-gray-700"
            >
              Your Highest Priority
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <select id="your_highest_priority-input"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.your_highest_priority,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.your_highest_priority,
                  }
                )}
                {...register('your_highest_priority', { required: false })}
                name="your_highest_priority" required aria-invalid="false" aria-labelledby="your_highest_priority-label" aria-describedby="your_highest_priority-description" aria-required="true">
                <option label="" value=""></option>
                <option label="Improve App Security" value="Improve App Security">Improve App Security</option>
                <option label="Improve API Security" value="Improve API Security">Improve API Security</option>
                <option label="RASP" value="RASP">RASP</option>
                <option label="Monitor Threats" value="Monitor Threats">Monitor Threats</option>
                <option label="Stop Attacks" value="Stop Attacks">Stop Attacks</option>
              </select>
              
              {/* {errors.your_highest_priority && (
                <p className="mt-2 mb-0 text-sm text-failure-600">
                  Please Select Your Highest Priority
                </p>
              )} */}
            </div>
          </div>


          <div className="col-span-6">
            <label
              htmlFor="time_frame_for_addressing_your_challenge"
              className="block text-sm font-medium text-gray-700"
            >
              Time Frame for Addressing Your Challenge(s)
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <select id="time_frame_for_addressing_your_challenge-input"
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.time_frame_for_addressing_your_challenge,
                  },
                  {
                    'border-failure-300 text-failure-900 placeholder-failure-300 focus:outline-none focus:ring-failure-500 focus:border-failure-500 sm:text-sm rounded-md':
                      errors.time_frame_for_addressing_your_challenge,
                  }
                )}
                {...register('time_frame_for_addressing_your_challenge', { required: false })}
                name="time_frame_for_addressing_your_challenge" required aria-invalid="false" aria-labelledby="time_frame_for_addressing_your_challenge-label" aria-describedby="time_frame_for_addressing_your_challenge-description" aria-required="true">

                <option label="" value=""></option>
                <option label="Currently evaluating solutions" value="Currently evaluating solutions">Currently evaluating solutions</option>
                <option label="1-3 months" value="1-3 months">1-3 months</option>
                <option label="3-6 months" value="3-6 months">3-6 months</option>
                <option label="6-12 months" value="6-12 months">6-12 months</option>
                <option label="12-18 months" value="12-18 months">12-18 months</option>
                <option label="More than 18 months" value="More than 18 months">More than 18 months</option>

              </select>

            </div>
          </div>


          <div className="col-span-6">
            <label
              htmlFor="info"
              className="block text-sm font-medium text-gray-700"
            >
              Application Description
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <textarea
                className={classNames(
                  'block w-full',
                  {
                    'border-gray-300 rounded-md shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm':
                      !errors.info,
                  },
                  {
                    'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md':
                      errors.info,
                  }
                )}
                rows={8}
                placeholder="So we can help make your trial successful, please include information on which mobile app development platform(s) you use (native Android/iOS, React Native, Flutter...), which backend platforms you use (AWS gateway, node-express, NGINX...), and the market or primary functionality of your application."
                {...register('info', { required: false, minLength: 4 })}
              />
              {/* {errors.info && (
                <p className="mt-2 mb-0 text-sm text-red-600">
                  Please tell us a little bit about your application.
                </p>
              )} */}
            </div>
          </div>

          <div className="col-span-6">
            <p className="mt-0 text-sm leading-tight text-gray-500">
            Approov will use the personal information you provide to send you the content requested and information about our services. You may unsubscribe from these communications at any time by clicking the link at the bottom of our emails. For information on our privacy practices and commitment to protecting your privacy, check out our{' '}
              <Link href="/privacy">Privacy Policy</Link>.
            </p>
            {isSubmitted && isQualified && !isAccepted && (
              <p className="mt-0 text-sm leading-tight text-failure-500">
                An unexpected error occurred while submitting this form. Please
                try your submission again, and if you continue to have trouble,
                please let us know at the{' '}
                <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do our
                best to resolve the issue.
              </p>
            )}
          </div>
        </div>

        <div className="pt-4">
          <div className="flex justify-center">
            <Button
              id={submitId}
              type="submit"
              className="ml-3 button-secondary bg-[#86b146]"
              loading={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    )
  } else {
    // advise on how to confirm
    return (
      <div className="p-4 bg-gray-100 border-2 border-gray-500 rounded">
        <p>{thanks}</p>
        <p>
          Check your email for a link to your requested resources. If you do not
          promptly receive a confirming email, please check your spam or junk
          folder for emails from approov.io. If you still have not received the
          confirming email, please let us know at the{' '}
          <Link href="https://approov.io/info/contact">contact us</Link> page, and we will do our best
          to resolve the issue.
        </p>

        {/* <div className="pt-4">
          <div className="flex justify-end">
            <Button className="ml-3 button-secondary" onClick={onConfirm}>
              OK
            </Button>
          </div>
        </div> */}
      </div>
    )
  }
}
