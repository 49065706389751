import React from 'react'

export const RefreshUpdateIcon = (props) => (
<svg width="55" height="55" viewBox="0 0 55 55" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
<path d="M53.8076 26.1797C53.1523 26.1797 52.6152 26.7168 52.6152 27.3721C52.6152 34.0645 50.0049 40.3594 45.2783 45.0859C40.541 49.8125 34.2568 52.4228 27.5645 52.4228C21.6455 52.4228 16.0381 50.3818 11.5479 46.6328L15.8877 45.7734C16.5322 45.6445 16.9512 45.0215 16.8223 44.377C16.6934 43.7324 16.0596 43.3135 15.4258 43.4424L8.78711 44.7637C8.71191 44.7744 8.64746 44.8066 8.57227 44.8281H8.56152C8.46484 44.8711 8.37891 44.9248 8.29297 44.9893C8.27148 45 8.25 45.0215 8.23926 45.043C8.16406 45.1074 8.09961 45.1826 8.03516 45.2686C8.03516 45.2686 8.03516 45.2793 8.02441 45.2793C7.9707 45.3652 7.92773 45.4512 7.89551 45.5478C7.88477 45.5693 7.88477 45.5908 7.87402 45.623C7.8418 45.7197 7.83105 45.8271 7.83105 45.9346V53.626C7.83105 54.2812 8.36816 54.8184 9.02344 54.8184C9.67871 54.8184 10.2051 54.2705 10.2051 53.6045V48.5986C15.0928 52.6055 21.1621 54.7969 27.5752 54.7969C34.9014 54.7969 41.7871 51.9395 46.9648 46.7617C52.1426 41.584 55 34.6982 55 27.3721C55 26.7168 54.4629 26.1797 53.8076 26.1797Z" fill="#1B3D57"/>
<path d="M51.3687 19.5088C51.5298 20.0137 52.0025 20.3252 52.4966 20.3252C52.6148 20.3252 52.7437 20.3037 52.8726 20.2607C53.4957 20.0566 53.8394 19.3799 53.6246 18.7568C53.313 17.8223 52.9586 16.8984 52.5504 16.0068C52.2818 15.4053 51.5728 15.1475 50.982 15.416C50.3804 15.6846 50.1226 16.3936 50.3912 16.9844C50.7564 17.8115 51.0894 18.6602 51.3687 19.5088Z" fill="#1B3D57"/>
<path d="M2.37402 28.4248C2.37402 21.7324 4.98438 15.4375 9.71094 10.7109C14.4482 5.98438 20.7324 3.37402 27.4248 3.37402C33.3438 3.37402 38.9512 5.41504 43.4414 9.16406L39.1016 10.0342C38.457 10.1631 38.0381 10.7861 38.167 11.4307C38.2744 12 38.7793 12.3867 39.3271 12.3867C39.4023 12.3867 39.4775 12.376 39.5635 12.3652L46.2021 11.0439C46.2773 11.0332 46.3418 11.001 46.417 10.9795H46.4277C46.5244 10.9365 46.6104 10.8828 46.6963 10.8184C46.7178 10.8076 46.7393 10.7861 46.75 10.7646C46.8252 10.7002 46.8896 10.625 46.9541 10.5391C46.9541 10.5391 46.9541 10.5283 46.9648 10.5283C47.0186 10.4424 47.0615 10.3564 47.0938 10.2598C47.1045 10.2383 47.1045 10.2168 47.1152 10.1846C47.1475 10.0879 47.1582 9.98047 47.1582 9.87305V2.19238C47.1582 1.53711 46.6211 1 45.9658 1C45.3105 1 44.7734 1.53711 44.7734 2.19238V7.19824C39.9072 3.18066 33.8379 1 27.4248 1C20.0986 1 13.2129 3.85742 8.03516 9.03516C2.85742 14.2129 0 21.0986 0 28.4248C0 29.0801 0.537109 29.6172 1.19238 29.6172C1.84766 29.6172 2.37402 29.0908 2.37402 28.4248Z" fill="#1B3D57"/>
<path d="M3.33024 35.2998C3.14762 34.6661 2.49235 34.3008 1.85856 34.4834C1.22477 34.6661 0.859538 35.3213 1.04215 35.9551C1.40739 37.2549 1.88005 38.544 2.43864 39.7793C2.64274 40.2198 3.07243 40.4776 3.5236 40.4776C3.68473 40.4776 3.85661 40.4454 4.01774 40.3702C4.6193 40.1016 4.87712 39.3926 4.60856 38.8018C4.10368 37.6631 3.67399 36.4922 3.33024 35.2998Z" fill="#1B3D57"/>
<path d="M33.8337 22.75L25.1253 31.4583L21.167 27.5" stroke="#78A65A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)