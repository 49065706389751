import { useStaticQuery, graphql } from 'gatsby'

export const normalizeOfferId = (id) => id?.toLowerCase().replace(/ |-/g, '_')

export const useOffer = (id) => {
  const {
    allOffersYaml: { nodes: offers },
  } = useStaticQuery(
    graphql`
      query {
        allOffersYaml {
          nodes {
            offerId
            offerName
            offerType
            formId
            resourceHref
            gaConversionName
          }
        }
      }
    `
  )

  const nId = normalizeOfferId(id)

  const offer = offers.find((o) => normalizeOfferId(o.offerId) === nId)

  if (!offer) {
    throw new Error(`Unable to find offer ${nId}`)
  }

  return offer
}

// sample page url with paid search

// https://approov.io/for/attestation/?
// utm_campaign=Attestation%20Google%20Ads&
// utm_source=ppc&
// utm_medium=CPC&
// utm_term=app%20attestation&
// utm_campaign=Search+%7C+High+Intent&
// utm_source=adwords&
// utm_medium=ppc&
// hsa_acc=7468501050&
// hsa_cam=17924333716&
// hsa_grp=138329417494&
// hsa_ad=614167022145&
// hsa_src=g&
// hsa_tgt=kwd-1647641523584&
// hsa_kw=app%20attestation&
// hsa_mt=e&
// hsa_net=adwords&
// hsa_ver=3&
// gclid=Cj0KCQjwyOuYBhCGARIsAIdGQRPKKj_KGKEsAvmwUkGHgGXWwJxu5-PrndglglJP-gIBIvZNXBC2-M0aAp3pEALw_wcB
