/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react'
import './src/components/core/theme/global.css'
import '@fontsource/montserrat/400.css'
import '@fontsource/montserrat/600.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto-mono/400.css'
import '@fontsource/roboto-mono/700.css'
import { DebugProvider } from '@components/dev'
import { QueryProvider } from "./src/components/core/query-provider";
import { MDXProvider } from "./src/components/core/mdx";
import { CookiesProvider } from './src/components/site/cookies'

// Logs when the client route changes
export const onRouteUpdate = ({ location, prevLocation }) => {
  // window.dataLayer = window.dataLayer || []
  // window.dataLayer.push({
  //   event: 'Pageview',
  //   pagePath: location.pathname,
  //   pageTitle: location.pathname, //the actual page title would be better
  // })

  const approov = window.approov || {}
  approov.referrerUrl = document?.referrer || ''
  approov.previousPath = prevLocation?.pathname || ''
  approov.currentFrag = location.hash
    ? location.hash.startsWith('#')
      ? location.hash.slice(1)
      : location.hash
    : ''
  window.approov = approov

  // console.log(`approov.referrerUrl: '${approov.referrerUrl}'`)
  // console.log(`approov.previousPath: '${approov.previousPath}'`)
  // console.log(`approov.currentFrag: '${approov.currentFrag}'`)
}

export const onClientEntry = () => {

  const handleLoad = () => {
    console.log('All assets are loaded');
    switch (document.location.pathname) {
      case "/threats/real-time/":
          window.location.href = 'https://approov.grafana.net/public-dashboards/d2febf9a256e4f49b656b47ffe9ef588?orgId=1&refresh=5m';
        break;
      case "/threats/category/":
          window.location.href = 'https://approov.grafana.net/public-dashboards/e2bfbd28a3f84b61bb4fca9caef18ff3?orgId=1';
        break;
      case "/threats/android-category/":
          window.location.href = 'https://approov.grafana.net/public-dashboards/87a4545dd5224e7a8f2eb8d85775f663?orgId=1';
        break;
      case "/threats/ios-category/":
          window.location.href = 'https://approov.grafana.net/public-dashboards/f24d47e4c6cf47df898b06f294364074?orgId=1';
        break;
      case "/threats/trends/":
        window.location.href = 'https://approov.grafana.net/public-dashboards/4ee9852384f84aed85283410039842a8';
        break;
      case "/threats/attestations/":
        window.location.href = 'https://approov.grafana.net/public-dashboards/1f4b11a4fad34c6f96a554838f28038f';
        break;
      default:
        break;
    }
  };
  window.addEventListener('load', handleLoad);

  return () => {
    window.removeEventListener('load', handleLoad);
  };
};
export const wrapRootElement = ({ element }) => (
  <DebugProvider>
    <QueryProvider>
      <CookiesProvider>
        <MDXProvider>{element}</MDXProvider>
      </CookiesProvider>
    </QueryProvider>
  </DebugProvider>
)

