import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgDir = ".";

export const ApiBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/api.jpg`}
        alt=""
    />
);

export const BasicBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/basic.jpg`}
        alt=""
    />
);
export const BlueSkyGridBG = (
    <StaticImage
        objectFit="cover"
        objectPosition="center bottom"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/blue-sky-grid.jpg`}
        alt=""
    />
);

export const CodeBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/code.jpg`}
        alt=""
    />
);

export const ConnectedLocksBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/connected-locks.jpg`}
        alt=""
    />
);

export const CyberscapeBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/cyberscape.jpg`}
        alt=""
    />
);

export const CyberTickedBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/cyber-ticked.jpg`}
        alt=""
    />
);

export const DisplayBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/display.jpg`}
        alt=""
    />
);

export const DynamicPinsBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/dynamic-pins.jpg`}
        alt=""
    />
);

export const LocksBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/locks.jpg`}
        alt=""
    />
);

export const MapBG = (
    <StaticImage
        objectFit="cover"
        objectPosition="right center"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/map.jpg`}
        alt=""
    />
);

export const NodesBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/nodes.jpg`}
        alt=""
    />
);

export const PadlockedPhoneBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/padlocked-phone.jpg`}
        alt=""
    />
);

export const PartnerBG = (
    <StaticImage
        objectFit="cover"
        objectPosition="center top"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/partners.jpg`}
        alt=""
    />
);

export const PinsBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/pins.jpg`}
        alt=""
    />
);

export const PuzzleBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/puzzle.jpg`}
        alt=""
    />
);

export const SecurityBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/security.jpg`}
        alt=""
    />
);

export const ShieldBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/shield.jpg`}
        alt=""
    />
);

export const SpeedBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/speed.jpg`}
        alt=""
    />
);

export const VortexBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/vortex.jpg`}
        alt=""
    />
);

export const WorldBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/world.jpg`}
        alt=""
    />
);

export const PreFooterBG = (
    <StaticImage
        objectFit="cover"
        style={{
            height: "100%",
            width: "100%",
        }}
        src={`${imgDir}/pre-footer.jpg`}
        alt="Close up of man using a smartphone"
    />
);
