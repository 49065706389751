import React, { useEffect, useState } from 'react'
import { useLocation } from '@reach/router'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies-nbumbu'
import { Button, Container, Link, Section } from '@components/core'
import { useSiteMetadata } from '../metadata'
import { useCookies } from './use-cookies'

export const CookiesConsent = () => {
  const { gtm } = useSiteMetadata()
  const gtmConsent = gtm.cookieName

  const [isMounted, setIsMounted] = useState(false)
  const [cookies, setCookie] = useCookies([gtmConsent])

  // start tracking as soon as cookie is set
  const location = useLocation()
  if (typeof window !== 'undefined') initializeAndTrack(location)

  // don;t show until after initial render
  useEffect(() => setIsMounted(true), [])

  const showBanner = isMounted && !(gtmConsent in cookies)
  const onAccept = () => setCookie(gtmConsent, true, { maxAge: 488640000 })
  const onDecline = () => setCookie(gtmConsent, false, { maxAge: 3628800 })

  if (showBanner) {
    return (
      <Section className="fixed bottom-0 w-screen border-t border-gray-700 bg-white z-10" style={{width: 359, bottom: 20, right: 20, boxShadow: '0 8px 20px 0 rgb(40 37 89 / 60%)', borderRadius: 5}}>
        <Container className="flex flex-col items-center container-md">
          <div className="pt-4">
            <p className="text-sm">
              By clicking “Accept”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, so that we can offer you the best experience on our website.<br/>
              <Link href="/cookies">Cookie Policy</Link>
            </p>
          </div>
          <div className="flex justify-end w-full gap-8 px-8 py-4">
            <Button className="button-secondary button-sm bg-[#86b146]" onClick={onAccept}>
              Accept
            </Button>
            <Button
              className="button-secondary button-sm button-outline !text-[#86b146]  !border-[#86b146]"
              onClick={onDecline}
            >
              Decline
            </Button>
          </div>
        </Container>
      </Section>
    )
  } else {
    return null
  }
}
