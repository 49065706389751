import React, {useEffect} from "react";
import HubspotForm from 'react-hubspot-form'
import './hubspot_form.css';

export const NewsletterSubscriptionForm = () => {

  useEffect(() => {
    window.addEventListener('message', handler);
    return () => {
        window.removeEventListener('message', handler);
    }
  }, [])

  function handler(event) {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
          if (event.data.id === '734a09d1-3446-4d75-82b1-f8e408e4f4fe') {

          }
      }
  }

  return (
    <div className="newsletter_subscription_form flex flex-col gap-[10px] rounded-[10px] bg-[#F2F5F9] border-[#E3E3E3] border-[1px] px-[24px] py-[30px]">
      <HubspotForm
        portalId='2449407'
        formId='734a09d1-3446-4d75-82b1-f8e408e4f4fe'
        onSubmit={() => console.log('Submit!')}
        onReady={(form) => console.log('Form ready!')}
      />
  </div>
  )
}