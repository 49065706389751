import React from 'react'

export const APIAbuseIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none" {...props}>
        <path d="M64.4 51.6C64.4 51.6 64.4 51.5 64.3 51.5V51.4L56.5 41.6V9.8C56.5 9.4 56.2 9 55.7 9H9.7C9.3 9 8.9 9.3 8.9 9.8V41.5L1.1 51.3V51.4L1 51.5C1 51.6 1 51.6 1 51.7V51.8V55.8C1 56.2 1.3 56.6 1.8 56.6H63.8C64.2 56.6 64.6 56.3 64.6 55.8V51.8V51.7C64.4 51.7 64.4 51.6 64.4 51.6ZM10.5 10.6H55V41.1H10.5V10.6ZM63 55.1H2.5V52.6H46.7C47.1 52.6 47.5 52.3 47.5 51.8C47.5 51.3 47.2 51 46.7 51H3.3L10.1 42.5H55.4L62.2 51H52.7C52.3 51 51.9 51.3 51.9 51.8C51.9 52.3 52.2 52.6 52.7 52.6H63V55.1Z" fill="#1B3D57"/>
        <line x1="14.4183" y1="37.1547" x2="52.1336" y2="14.493" stroke="#78A65A"/>
        <line x1="13.4001" y1="14.2406" x2="51.1155" y2="36.9023" stroke="#78A65A"/>
        <path d="M51.7004 13.1H13.7004C13.3004 13.1 12.9004 13.4 12.9004 13.9V37.9C12.9004 38.3 13.2004 38.7 13.7004 38.7H51.7004C52.1004 38.7 52.5004 38.4 52.5004 37.9V13.9C52.5004 13.4 52.1004 13.1 51.7004 13.1ZM51.0004 37.1H14.5004V14.6H51.0004V37.1Z" fill="#1B3D57"/>
        <path d="M50 51.1C49.7 51 49.4 51 49.2 51.3C49.1 51.4 49 51.6 49 51.8C49 52 49.1 52.2 49.2 52.3C49.3 52.4 49.5 52.5 49.7 52.5C49.8 52.5 49.9 52.5 50 52.5C50.1 52.5 50.2 52.4 50.2 52.3C50.3 52.2 50.3 52.1 50.4 52.1C50.4 52 50.5 51.9 50.5 51.8C50.5 51.6 50.4 51.4 50.3 51.3C50.2 51.2 50.1 51.1 50 51.1Z" fill="#1B3D57"/>
        <path d="M44.0156 21H46.0812V32.1223H44.0156V21Z" fill="#78A65A"/>
        <path d="M37.2176 21C38.1816 21 39.0184 21.1589 39.7281 21.4767C40.4484 21.7944 40.9992 22.2499 41.3805 22.8431C41.7619 23.4363 41.9525 24.1407 41.9525 24.9564C41.9525 25.7614 41.7619 26.4658 41.3805 27.0696C40.9992 27.6628 40.4484 28.1183 39.7281 28.436C39.0184 28.7538 38.1816 28.9127 37.2176 28.9127H34.7072V32.1223H32.6416V21H37.2176ZM37.1223 27.1649C38.0227 27.1649 38.7059 26.9743 39.172 26.5929C39.638 26.2116 39.8711 25.6661 39.8711 24.9564C39.8711 24.2466 39.638 23.7011 39.172 23.3198C38.7059 22.9385 38.0227 22.7478 37.1223 22.7478H34.7072V27.1649H37.1223Z" fill="#78A65A"/>
        <path d="M27.9546 29.5483H22.3934L21.2971 32.1223H19.168L24.173 21H26.2068L31.2277 32.1223H29.0668L27.9546 29.5483ZM27.2713 27.9276L25.174 23.0656L23.0925 27.9276H27.2713Z" fill="#78A65A"/>
    </svg>
)