import React from 'react'
import classNames from 'classnames'
import { PillarCard } from './pillar-card'
import { pillarData } from './pillar-data'

const PillarPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames('w-full', className)} {...rest}>
      <div className="flex flex-wrap justify-center items-stretch gap-y-3 gap-x-[2%] md:gap-x-[2.33%] lg:gap-x-[2%]">
        {pillarData.map(({ title, doit, dont, Icon }) => (
          <PillarCard
            key={title}
            className="w-[98%] sm:w-[48%] md:w-[31%] lg:w-[18%] self-stretch"
            title={title}
            doit={doit}
            dont={dont}
            Icon={Icon}
          />
        ))}
      </div>
    </div>
  )
}

export { PillarPanel }
