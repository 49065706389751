import React from "react";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import {AppCard} from "../../core/app-card";

const HowProtectsPanel = ({ className, ...rest }) => {
  return (
    <div className={classNames("w-full", className)} {...rest}>
      <h2 className="leading-none mb-4 mt-0 text-center text-[#1b3d57] font-bold  text-[2.2rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem]">
        How Approov Protects Your Revenue
      </h2>
      <p className="text-center lg:text-lg text-[1rem] mt-8 mb-4">
        Only your own mobile apps — running in safe environments and communicating over secured connections — can use your
        APIs and backend resources. Botnets, malicious scripts, tampered and fake apps are blocked.{" "}
      </p>
      <div className="flex flex-wrap justify-center items-stretch gap-y-3 gap-x-[2%] md:gap-x-[2.33%] lg:gap-x-[2%] pt-2 lg:leading-[1.75rem] leading:[1.1rem]">
        <AppCard
          cardImage={<StaticImage objectFit="contain" className="lg:h-[22rem] h-[19rem]" src="./number1.png" alt="" />}
          className="w-[98%] sm:w-[98%] md:w-[98%] lg:w-[23%] self-stretch mt-5"
          title={`Identify`}
          text={`Your mobile app automatically connects to our cloud service for "identification."`}
          titleClassName="text-[1.9rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem] mt-[0.3rem]"
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <StaticImage
              objectFit="contain"
              className="lg:h-[20rem]  md:h-[19rem] h-[14rem] mt-[2rem]"
              src="./number2.png"
              alt=""
            />
          }
          className="w-[98%] sm:w-[98%] md:w-[98%] lg:w-[46%] self-stretch  mt-5"
          title={`Verify`}
          text={`The "DNA" of the app and characteristics of the runtime environment "verify" that the app is genuine, security requirements are met, and communication is secure.
          `}
          titleClassName="text-[1.9rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem] mt-[0.3rem]"
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
        <AppCard
          cardImage={
            <StaticImage
              objectFit="contain"
              className="lg:h-[20rem] h-[19rem] lg:ml-[4.25rem] ml-[3.25rem]  mt-[2rem]"
              src="./number3.png"
              alt=""
            />
          }
          className="w-[98%] sm:w-[98%] md:w-[98%] lg:w-[23%] self-stretch  mt-5"
          title={`Certify`}
          text={`Either a short-lived token or run time secrets are provided to verified apps. The app uses this to "certify" its access to protected API services.`}
          titleClassName="text-[1.9rem] md:text-[2.2rem] sm:text-[2.2rem] lg:text-[2.7rem] mt-[0.3rem]"
          // data-sal="slide-up"
          // data-sal-duration="1000"
          // data-sal-delay="400"
          // data-sal-easing="ease"
        />
      </div>
    </div>
  );
};

export { HowProtectsPanel };
