import React, { useState } from 'react'
import classNames from 'classnames'
import { usePage } from './use-page'
import { OfferGuard } from './offer-guard'

const SHOW_ACCEPT = 'accept'
const SHOW_RETRY = 'retry'
const SHOW_CONTENT = 'content'

export const OfferContent = ({
  offer,
  children,
  shared,
  onDismiss,
  isDismissed,
  toPayload,
  fromPayload,
}) => {
  // page Info

  const {
    queryParams: { cid },
  } = usePage()

  // display state

  const [show, setShow] = useState(() => (cid ? SHOW_ACCEPT : SHOW_RETRY))

  // display content block

  return (
    <div className="relative w-full">
      <div className="w-full">{children}</div>
      <div
        className={classNames(
          {
            hidden: shared
              ? shared.show === SHOW_CONTENT
              : show === SHOW_CONTENT,
          },
          'absolute inset-0 backdrop-blur-md'
        )}
      />
      <OfferGuard
        offer={offer}
        cid={cid}
        show={shared ? shared.show : show}
        setShow={shared ? shared.setShow : setShow}
        onDismiss={onDismiss}
        isDismissed={isDismissed}
        toPayload={toPayload}
        fromPayload={fromPayload}
      />
    </div>
  )
}
